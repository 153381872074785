import { createSlice } from '@reduxjs/toolkit'
import { ActionConfig } from '../sdk/model'
import { enableMapSet } from 'immer';

enableMapSet();

const initialState = {};

const actionConfigDataLoadSlice = createSlice({
  name: 'actionConfigDataLoad',
  initialState,
  reducers: {
    setActionConfigDataLoad (state, action) {
      return state = action.payload
    }
  }
})

export const { setActionConfigDataLoad } = actionConfigDataLoadSlice.actions
export default actionConfigDataLoadSlice.reducer