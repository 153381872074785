import { Box, Button, Paper, Typography } from '@mui/material';
import * as React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { LeagueEventApi } from '../../../../sdk/api/league-event-api';
import { Draft, EventActivity, Player, Team } from '../../../../sdk/model';
import { getCurrentSalary, toSalary } from '../../../util/SalaryDisplay';
import FastForwardDraftButton from '../dialogBoxes/fastforward';
import PauseDraftButton from '../dialogBoxes/pause';
import ResetDraftButton from '../dialogBoxes/reset';
import ResumeDraftButton from '../dialogBoxes/resume';
import RewindDraftButton from '../dialogBoxes/rewind';
import { getClockText } from '../service';


// Valid states for this button to be shown
const isPlaying = ['STARTED', 'RESUMED', 'PENDING']


export interface DraftTimerProps {
  draft: Draft;
  pickId: string;
  secondsPerPick: number
}

function DraftTimer(props: DraftTimerProps) {
  const draftStatus = props.draft.status;
  const countdownDuration = draftStatus === 'PENDING' ? 3600 : props.secondsPerPick;
  const countdownDeadline = draftStatus === 'PENDING' ? props.draft.eventDateTime : props.draft.nextPickDeadline;
  const secondsRemaining = draftStatus === 'PAUSED' ? props.draft.countdownSecondsRemaining : (new Date(countdownDeadline).getTime() - new Date().getTime()) / 1000;
  
  console.log("Timer");
  console.log(props.draft);
  console.log("Countdown Duration: " + countdownDuration);
  console.log("Countdown Deadline: " + countdownDeadline);
  console.log("Seconds Remaining: " + secondsRemaining);
  // Render Timer
  const renderTimer = ({ remainingTime }) => {
    const hours = Math.floor(remainingTime / 3600)
      const minutes = Math.floor((remainingTime % 3600) / 60)
      const seconds = remainingTime % 60
  
      return <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 16, textAlign: 'center' }}>
        {getClockText(hours, minutes, seconds)}
      </Typography>
    }

  return (
    <CountdownCircleTimer
        isPlaying={isPlaying.includes(draftStatus)}
        initialRemainingTime={secondsRemaining}
        duration={countdownDuration}
        key={props.pickId}
        colors={['#004777', '#F7B801', '#A30000', '#A30000']}
        colorsTime={[30, 10, 5, 0]}
        size="100">
      {renderTimer}
    </CountdownCircleTimer>
  );
}

export default DraftTimer;