import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { getTotalContractValue, getPerSeasonSalaries } from '../../../util/ContractUtils';
import AuctionBidButton from './AuctionBidButton';

interface minimumBidButtonProps {
  years: number,
  minimumOneYearOfferValue: number,
  currentSeason: number | undefined,
  bidsRemaining: number | undefined,
  maxBid: number,
  disabled: boolean,
  auctionId: string,
  teamId: string | undefined
}

function MinimumBidButton(props: minimumBidButtonProps) {
  const totalAmount = getTotalContractValue(props.minimumOneYearOfferValue, props.years);
  function getBidDisabled() {
    if (props.bidsRemaining === 0) {
      return true;
    }
    const perSeasonSalaries = getPerSeasonSalaries(props.years, totalAmount);
    return props.maxBid < perSeasonSalaries[0];
  }
  const bidDisabled = props.disabled || getBidDisabled();
  return <Box sx={{ display: 'inline-flex'}}>
    <Box>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        {props.years} year{props.years === 1 ? " (unlimited)" : "s (" + props.bidsRemaining + ")"}
      </Typography>
      <AuctionBidButton years={props.years} totalAmount={totalAmount} currentSeason={props.currentSeason} bidDisabled={bidDisabled} auctionId={props.auctionId} teamId={props.teamId} />
    </Box>
  </Box>
}

export default MinimumBidButton;