import * as React from 'react'
import { Team, TradeProposalPick } from '../../../sdk/model';
import { Box, Typography, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { displayDraftPick } from '../../util/DraftPickDisplay';
import TradeOptionsMenu from '../trade/TradeOptionsMenu';
import { toSalary } from '../../util/SalaryDisplay';

interface teamDraftPickDisplayProps {
  team: Team | undefined,
  teamMap: Map<string | undefined, Team | undefined>,
  proposalPickMap: Map<string | undefined, TradeProposalPick | undefined> | undefined,
  handleTradeProposalChange: Function | undefined,
  myTeam: boolean,
  otherTeamIds: Array<string | undefined> | undefined,
  currentSeason: number,
}

function TeamDraftPickDisplay(props: teamDraftPickDisplayProps) {
  return <Box>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Draft Picks
    </Typography>
    <TableContainer className="centered" component={Paper}><Table>
      <TableHead><TableRow>
        <TableCell>Pick</TableCell>
        <TableCell>Current Season Salary</TableCell>
        {props.proposalPickMap && <TableCell>Trade To</TableCell>}
      </TableRow></TableHead>
      <TableBody>
        {props.team!.draftPicks?.map(pick => {
          const team = props.teamMap?.get(pick.originalTeamId)
          const proposalPick = props.proposalPickMap ? props.proposalPickMap.get(pick.id) : undefined;
          const currentSeasonSalary = pick.contract ? pick.contract.seasons!.find(season => season.season_year === props.currentSeason)?.annual_salary : 0;
          return <TableRow key={pick.id}>
            <TableCell>{displayDraftPick(pick, team)}</TableCell>
            <TableCell>{currentSeasonSalary ? toSalary(currentSeasonSalary) : '-'}</TableCell>
            {props.handleTradeProposalChange && <TableCell><TradeOptionsMenu handleTradeProposalChange={(playerId: string, fromTeamId: string, action: string) => props.handleTradeProposalChange!(playerId, fromTeamId, action, 'pick')} asset={pick} teamId={props.team?.id!} myTeam={props.myTeam} droppable={false} otherTeamIds={props.otherTeamIds!} teamMap={props.teamMap} value={proposalPick ? proposalPick.toTeamId! : 'default'} /></TableCell>}
          </TableRow>
        })}
      </TableBody>
    </Table></TableContainer>
  </Box>
}
  
export default TeamDraftPickDisplay;