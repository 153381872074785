import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import { Team, User } from '../../../sdk/model';
import { Box, Modal, Button, Typography, IconButton, Avatar } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FlagIcon from '@mui/icons-material/Flag';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import {getNumberOrdinal} from '../../util/DraftPickDisplay';
import UserInfoCard from '../../cards/userCard'

interface teamHistoryProps {
  leagueId: string,
  team: Team,
}

function TeamHistory(props: teamHistoryProps) {
  const [viewingUser, setViewingUser] = React.useState<User | undefined>(undefined);
  const navigate = useNavigate();
  
  function displayRelaventSeasons(relaventSeasons : number[], icon) {
    return relaventSeasons.length ? 
      relaventSeasons.map((seasonYear, seasonIndex) =>
        <Button onClick={() => navigate(`/league/${props.leagueId}/history?seasonYear=${seasonYear}`)}>
          {icon}{seasonYear + (seasonIndex < relaventSeasons.length - 1 ? ',' : '')}
        </Button>
      ) : 'None';
  }
  const completedSeasons = Object.keys(props.team.finishesBySeason!);
  return <Box sx={{maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}>
    <Modal open={!!viewingUser} onClose={() => setViewingUser(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <UserInfoCard userId={viewingUser?.id!} callback={() => setViewingUser(undefined)}/>
    </Modal>
    <Box sx={{border: 1, width: 600}}>
      <Typography variant="h5">
        Owner(s):
      </Typography>
      {props.team.owners?.length ? 
        props.team.owners!.map((owner, ownerIndex) => {
        return <Button key={ownerIndex} onClick={() => setViewingUser(owner)}>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt={owner?.username} src={owner?.picture} />
            </IconButton>
            {' ' + owner.username}
            <br/>
          </Button>
      }) : 'No Owner'}
    </Box>
    <Box sx={{border: 1, width: 600}}>
      <Typography variant="h5">
        Accomplishments:
      </Typography>
      <p>
        League Championships: {displayRelaventSeasons(props.team.leagueChampionshipSeasons!, <EmojiEventsIcon />)}
      </p>
      <p>
        Division Championships: {displayRelaventSeasons(props.team.divisionChampionshipSeasons!, <FlagIcon />)}
      </p>
      <p>
        Playoff Appearances: {displayRelaventSeasons(props.team.playoffAppearanceSeasons!, <EmojiFlagsIcon />)}
      </p>
    </Box>
    <Box sx={{border: 1, width: 600}}>
      <Typography variant="h5">
        Final Finishes by Season:
      </Typography>
      {completedSeasons.length ? completedSeasons.map(seasonYear => <Typography>
        <Button onClick={() => navigate(`/league/${props.leagueId}/history?seasonYear=${seasonYear}`)}>{seasonYear}</Button> - {getNumberOrdinal(props.team.finishesBySeason![seasonYear])}
      </Typography>) : 'No completed seasons'}
    </Box>
  </Box>
}

export default TeamHistory;