/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { NflPlayerGameStatistics } from './nfl-player-game-statistics';
// May contain unused imports in some cases
// @ts-ignore
import { NflTeam } from './nfl-team';
// May contain unused imports in some cases
// @ts-ignore
import { PlayerSeasonStatistic } from './player-season-statistic';
// May contain unused imports in some cases
// @ts-ignore
import { QuickStats } from './quick-stats';

/**
 * Nfl Player Information
 * @export
 * @interface Player
 */
export interface Player {
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    'last_name'?: string;
    /**
     * 
     * @type {NflTeam}
     * @memberof Player
     */
    'nfl_team'?: NflTeam;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    'DOB'?: string;
    /**
     * Abbreviated Name
     * @type {string}
     * @memberof Player
     */
    'abbr_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Player
     */
    'jersey'?: number;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    'position'?: PlayerPositionEnum;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    'college'?: string;
    /**
     * 
     * @type {QuickStats}
     * @memberof Player
     */
    'quick_stats'?: QuickStats;
    /**
     * 
     * @type {number}
     * @memberof Player
     */
    'rookie_year'?: number;
    /**
     * Player\'s Statistics
     * @type {Array<PlayerSeasonStatistic>}
     * @memberof Player
     */
    'statistics'?: Array<PlayerSeasonStatistic>;
    /**
     * List of game statistics
     * @type {Array<NflPlayerGameStatistics>}
     * @memberof Player
     */
    'games'?: Array<NflPlayerGameStatistics>;
}

export const PlayerPositionEnum = {
    Qb: 'QB',
    Rb: 'RB',
    Wr: 'WR',
    Te: 'TE',
    Invalid: 'INVALID'
} as const;

export type PlayerPositionEnum = typeof PlayerPositionEnum[keyof typeof PlayerPositionEnum];


