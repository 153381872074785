import * as React from 'react';
import {Box} from '@mui/material';
import { Auction } from '../../../sdk/model';

interface auctionQuickDisplayProps {
  auction: Auction
}

function AuctionQuickDisplay(props: auctionQuickDisplayProps) {
  return <Box>
    <p>{props.auction.seasonYear} Free Agent Auction</p>
    <p>{props.auction.currentPick - 1} new contracts signed</p>
  </Box>
}

export default AuctionQuickDisplay;