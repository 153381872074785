import * as React from 'react'
import { League } from '../../../sdk/model';
import AllTeamsForSeason from './AllTeamsForSeason';

interface allTeamsProps {
  league : League, 
  joinEnabled: boolean
}

function AllTeams(props: allTeamsProps) {
  const leagueSeason = {...props.league.currentSeason!};
  leagueSeason.divisions = props.league.divisions;
  const teamSeasons = props.league.teams.map(team => {
    const teamSeason = {...team.currentSeason!};
    teamSeason.teamName = team.name;
    teamSeason.teamPicture = team.picture;
    teamSeason.owners = team.owners;
    return teamSeason;
  });
  return <AllTeamsForSeason league={props.league} leagueSeason={leagueSeason} 
      teamSeasons={teamSeasons} joinEnabled={props.joinEnabled} showWaiverPriority={true} />
}

export default AllTeams;
