import * as React from 'react'
import { useAppSelector } from '../../../app/hooks'
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box } from '@mui/material';
import { getCurrentSalary, getFinalSeasonOfContract, toSalary } from '../../util/SalaryDisplay';
import { Player, TeamPlayerInfo, PlayerPositionEnum } from '../../../sdk/model';
import { List } from 'reselect/es/types';
import { table } from 'console';


interface teamRosterProps {
  roster: Map<String, List<TeamPlayerInfo>> | undefined,
  playerMap: Map<string | undefined, Player | undefined>,
  currentSeasonYear: number,
  rosterSettings: Map<string | undefined, number | undefined>
}

const positionOrder: Array<string> = ["QB", "RB", "WR", "TE", "SFLEX", "FLEX", "DEF", "K", "P", "DL", "LB", "DB", "DFLEX", "BN"];
const playerPositionOrder: Array<string> = ["QB", "RB", "WR", "TE"];

function TeamRoster(props: teamRosterProps) {
  const allPlayers = Object.values(props.roster!).flat().filter(player => player).map(playerInfo => props.playerMap.get(playerInfo.playerId)!);
  const numbersAtPosition = playerPositionOrder.filter(position => props.rosterSettings[position]).map(position => {
    return {
      position: position,
      players: allPlayers.filter(player => player.position === position).length
    }
  })
  return <Box>
    <Typography variant="p" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Positions signed: {numbersAtPosition.map((position, positionIndex) => (positionIndex === 0 ? "" : ", ") + position.position + ": " + position.players)}
    </Typography>
    <TableContainer className="centered" component={Paper}><Table>
      <TableHead><TableRow>
        <TableCell>Position</TableCell>
        <TableCell>Player Name</TableCell>
        <TableCell>Current Year Salary</TableCell>
        <TableCell>Final Year of Contract</TableCell>
      </TableRow></TableHead>
      {getTableBody(props)}
    </Table></TableContainer>
  </Box>;
}

/**
 * 
 * @param props Returns the table body for the team roster
 * @returns 
 */
function getTableBody(props: teamRosterProps) {
  const tableBody = <TableBody>
    {typeof props.roster !== 'undefined' && typeof props.rosterSettings !== 'undefined' ?
      positionOrder.map((rosterPosition) => {
        const playerList = props?.roster[rosterPosition];
        if(typeof playerList === 'undefined') {
          return;
        }
        var tableRows = playerList.map((player, index) => {
          if (player) {
            return getTableRow(rosterPosition, player, props.playerMap ,props.currentSeasonYear);
          }
          return getEmptyTableRow(rosterPosition);
        })

        const emptySpotCount = props!.rosterSettings![rosterPosition] - playerList.length;
        for(var i = 0; i < emptySpotCount; i++) {
          tableRows = [...tableRows, getEmptyTableRow(rosterPosition)];
        }
        return tableRows;
      })

      :
      <TableRow><TableCell>Empty</TableCell></TableRow>
    }
  </TableBody>
  return tableBody;
}

/**
 * Returns a table row for a given player
**/
function getTableRow(rosterPosition: String, playerInfo: TeamPlayerInfo, playerMap: Map<string | undefined, Player | undefined>, currentSeasonYear: number) {
  const player = playerMap.get(playerInfo.playerId);
  const tableRow = <TableRow key={rosterPosition + playerInfo.playerId}>
    <TableCell>{rosterPosition}</TableCell>
    <TableCell>{player ? player.abbr_name : 'Empty'}</TableCell>
    <TableCell>{getCurrentSalary(playerInfo, currentSeasonYear)}</TableCell>
    <TableCell>{getFinalSeasonOfContract(playerInfo)}</TableCell>
  </TableRow>;
  return tableRow;
}


/**
 * Returns a blank table row for a given roster position
**/
function getEmptyTableRow(rosterPosition: String) {
  const tableRow = <TableRow>
    <TableCell>{rosterPosition}</TableCell>
    <TableCell>Empty</TableCell>
    <TableCell>-</TableCell>
    <TableCell>-</TableCell>
  </TableRow>;
  return tableRow;
}

export default TeamRoster;