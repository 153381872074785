import { Box, MenuItem, Select, Typography, Button } from '@mui/material';
import * as React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { Player, Team } from '../../../../sdk/model';
import { getTeamsRemainingCapSpace } from '../../../util/SalaryDisplay';
import TeamRoster from '../../team/TeamRosterSmall';
import { findDefaultTeam } from '../service';


interface teamViewerProps {
  leagueId: string,
  teams: Array<Team> | undefined,
  currentSeasonYear: number | undefined,
  salaryCap: number | undefined,
  userId: string | undefined,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team>,
  rosterSettings: Map<string | undefined, number | undefined>,
  tradeDisabled: boolean
}

function DraftTeamViewer(props: teamViewerProps) {

  const [visibleTeamId, setVisibleTeamId] = React.useState<string | undefined>(findDefaultTeam(props.teams!, props.userId!));
  
  const user = useAppSelector((state) => state.user);

  if(props.teams === undefined) {
    return <div>Team Data Loading...</div>
  }


  const visibleTeam: Team | undefined = visibleTeamId ? props.teamMap.get(visibleTeamId) : undefined;
  console.log("Visible Team (" , visibleTeamId , "): ", visibleTeam);

  const myTeam = visibleTeam?.owners?.some(owner => owner.id === user.id);
  console.log("Team Map: ", props.teamMap);

  return <Box sx={{ height: '50%' }}>
    <Select name="viewingTeam" label="Team" labelId="viewingTeamLabel" value={visibleTeamId} onChange={(event) => setVisibleTeamId(event.target.value)}
      sx={{ flexGrow: 1 }}>
      {Object.entries(props.teams!).map(([teamIndex, team]) => 
        <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
      )}
    </Select>
    {!myTeam && !props.tradeDisabled && <Button color="primary" variant="contained" onClick={() => window.open('/league/' + props.leagueId + '/trade?teamId=' + visibleTeam?.id, '_blank', 'noreferrer')}>Propose Trade</Button>}
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      {visibleTeam?.name}
    </Typography>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      ({getTeamsRemainingCapSpace(visibleTeam, props.currentSeasonYear, props.salaryCap)} cap space remaining in {props.currentSeasonYear})
    </Typography>
    <TeamRoster leagueId={props.leagueId} roster={visibleTeam?.playerRoster} currentSeasonYear={props.currentSeasonYear!}
      playerMap={props.playerMap} rosterSettings={props.rosterSettings} />
  </Box>
}

export default DraftTeamViewer;