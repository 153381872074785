import { SxProps } from '@mui/material';

export const getColorByPosition = function(position: string) {
  var sx: SxProps = { cursor: 'pointer'};
  switch(position) {
    case 'QB':
      sx['backgroundColor'] = '#ffc09f';
      break;
    case 'RB':
      sx['backgroundColor'] = '#adf7b6';
      break;
    case 'WR':
      sx['backgroundColor'] = '#79addc';
      break;
    case 'TE':
      sx['backgroundColor'] = '#fcf5c7';
      break;
    default:
      sx['backgroundColor'] = '#ffffff';
      break;
  }
  return sx;
}