import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid  from '@mui/material/Unstable_Grid2';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/hooks'
import { styled } from '@mui/material/styles';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League } from '../../../sdk/model';

import LeagueMenu from './LeagueMenu';
import LeagueName from './LeagueName';


const drawerWidth = 250;

type LeagueViewProps = {
  children: React.ReactNode;
  includeRosters?: boolean;
};

function LeagueView({ children, includeRosters = false }: LeagueViewProps) {

  
  const {leagueId} = useParams();

  // Navigator/Dispatch
  const navigate = useNavigate();

  // State  
  const user = useAppSelector((state) => state.user);

  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [result, setResult] = React.useState("");  
  const [sideNavOpen, setSideNavOpen] = React.useState(false);

  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                              loadFullDraftData: false, loadContractData: false,
                                                              loadBasicAuctionData: true, loadFullAuctionData: false,
                                                              loadRosterData: includeRosters, 
                                                              isException: isException, setIsException: setIsException,
                                                              isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});


const handleSideNavOpenChange = (newSideNavOpen) => {
  setSideNavOpen(newSideNavOpen);
};

   // Load League Data into the children nodes
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {league});
    }
    return child;
  });
  function getResult() {

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'sideNavOpen' })<{
      sideNavOpen?: boolean;
    }>(({ theme, sideNavOpen }) => ({

      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: 'calc(100vh - 117px)',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      customMarginLeft: `${sideNavOpen}`,
      marginLeft: `0`,
      ...(sideNavOpen && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${drawerWidth}px`,
      }),
    }));
    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      },
      typography: {
        button: {
          textTransform: 'none'
        }
      }
    });
    return <ThemeProvider theme={darkTheme}>
      <Grid className='component'>
        <Box sx={{position: 'relative'}}>
          <LeagueMenu onSideNavOpenChange={handleSideNavOpenChange} />
          <Main sideNavOpen={sideNavOpen}>
            <LeagueName />
            {childrenWithProps}
          </Main>
        </Box>
      </Grid>
    </ThemeProvider>
  }
  
  React.useEffect(() => {
    // Doing this because otherwise, changes to the league re-renders child components, clearing out the state
    if (isLoadComplete) {
      setResult(getResult());
    }
  }, [isLoadComplete, children, sideNavOpen])
  
  React.useEffect(() => {
    setLoadComplete(false);
  }, [leagueId])
  
  if(isException) {
    return <Typography color="red">There was an error loading the league</Typography>
  } else if(!isLoadComplete) {
    return <Typography color="white">Loading league data..</Typography>
  }
  
  return result;
}

export default LeagueView;