import * as React from 'react';
import { League, Team } from '../../../../sdk/model';
import { Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, 
    TextField, IconButton, Avatar, Button } from '@mui/material';
import { teamApi } from '../../../../adapters/APIExporter'

interface allTeamsSettingsProps {
  league: League
}

function AllTeamsSettings(props: allTeamsSettingsProps) {
  const [mutableTeams, setMutableTeams] = React.useState<Team[]>(props.league.teams.map(team => { return {...team}}));
  
  function handleChange(event, teamIndex) {
    const {name, value} = event.target;
    const newMutableTeams = mutableTeams.map(team => {return {...team}});
    const changingTeam = newMutableTeams[teamIndex];
    changingTeam[name] = value;
    setMutableTeams(newMutableTeams);
  }
  
  function save() {
    teamApi.updateLeagueTeams(props.league.id, mutableTeams);
  }
  
  return <Box>
    <TableContainer className="centered" component={Paper} sx={{width: '75%'}}><Table>
      <TableHead><TableRow>
        <TableCell>Team Name</TableCell>
        <TableCell>Owner(s)</TableCell>
      </TableRow></TableHead>
      <TableBody>
        {mutableTeams.map((team, teamIndex) => <TableRow key={team.id}>
          <TableCell>
            <TextField name='name' label="Team Name" value={team.name}
                onChange={event => handleChange(event, teamIndex)} />
          </TableCell>
          <TableCell>
            {team?.owners?.length ? 
              team!.owners!.map((owner, ownerIndex) => {
              return <Box>
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt={owner?.username} src={owner?.picture} />
                </IconButton>
                {' ' + owner.username}
                <br/>
              </Box>
            }) : 
            'No Owner'}
          </TableCell>
        </TableRow>)}
      </TableBody>
    </Table></TableContainer>
    <br/>
    <Button className='capsized-button' onClick={save}>Save</Button>
  </Box>
}

export default AllTeamsSettings;
