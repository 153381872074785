import { createTheme } from "@mui/material";

export const defaultTableTheme = createTheme({
  palette: {
    primary: {
      main:"#1f2f4e",
      light:"#243453",
      dark:"#1A2A49"
    },
    secondary: {
      main: "#2e5573",
      light: "#335A78",
      dark: "#29506E"
    }
  },
});