import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { League } from '../../../../sdk/model';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Close from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { Typography, IconButton, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { leagueApi } from '../../../../adapters/APIExporter'

interface leagueSettingsProps {
  league: League
}

function LeagueSettings(props: leagueSettingsProps) {
  const [editMode, setEditMode] = React.useState(false);
  const [mutableLeague, setMutableLeague] = React.useState(props.league);
  const [deleting, setDeleting] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const navigate = useNavigate();
  
  function handleLeagueChange(event) {
    const {name, value} = event.target;
    const newLeague = {...mutableLeague};
    newLeague[name] = value;
    setMutableLeague(newLeague);
  }
  const validateLeagueName = (value, errors) => {
    if (value.length < 3 || value.length > 50) {
      errors.push('You must provide a League Name with a length between 3 and 50 characters');
    }
  }
  function cancelEdit() {
    setMutableLeague({...props.league});
    setEditMode(false);
  }
  function saveLeagueSettings() {
    let newErrors = [];
    validateLeagueName(mutableLeague.leagueName, newErrors);
    setErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }
    setEditMode(false);
    leagueApi.updateLeague(mutableLeague.id, mutableLeague)
      .then()
      .catch((error) => {
        newErrors.push(error.response.data.message);
        setErrors(newErrors);
        setEditMode(true);
      });
  }
  function deleteLeague() {
    leagueApi.deleteLeague(props.league.id).then(() => navigate('/'));
  }
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return <Box>
    <TextField name='leagueName' label="League Name" value={mutableLeague.leagueName}
        disabled={!editMode} onChange= {handleLeagueChange}/>
    <br/>
    {editMode && <TextField name='picture' label="Image URL" value={mutableLeague.picture} onChange={handleLeagueChange}/>}
    <IconButton sx={{ p: 0 }}>
      <Avatar alt={mutableLeague.leagueName} src={mutableLeague.picture}>{!mutableLeague.picture && mutableLeague.leagueName?.substring(0,1)}</Avatar>
    </IconButton>
    <br/>
    {editMode ?
      <Box>
        <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
        <Button className='capsized-button' onClick={saveLeagueSettings}>Save League Settings</Button>
      </Box>
      : <Button className='capsized-button' onClick={() => setEditMode(true)}>Edit League Settings</Button>
    }
    {errorInfo}
    <br />
    <Button className='capsized-button' onClick={() => setDeleting(true)}>Delete League</Button>
    <Dialog open={deleting} maxWidth="sm" fullWidth>
      <DialogTitle>Delete League?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setDeleting(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to delete {props.league.leagueName}?  This action is irreversable, and will delete all league history!</Typography>
      </DialogContent>  
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setDeleting(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={deleteLeague}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
}

export default LeagueSettings;
