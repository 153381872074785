import { createSlice } from '@reduxjs/toolkit'
import { User } from '../sdk/model'

const initialState: User = {}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser (state, action) {
      console.log(action)
      return state = {...state, ...action.payload}
      // state.user = action.payload
    }
  }
})

export const { setUser } = userSlice.actions
export default userSlice.reducer