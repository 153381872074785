import * as React from 'react'
import Box from '@mui/material/Box';
import Close from "@mui/icons-material/Close"
import { Typography, IconButton, DialogTitle, Dialog,
    DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { leagueEventApi } from '../../../../adapters/APIExporter';
import { useAppSelector } from '../../../../app/hooks'
import { useValidateAction } from '../../../util/ActionValidationUtil';
import LeagueEventDatePicker from './LeagueEventDatePicker';
import LeagueEventOrderSelector from './LeagueEventOrderSelector';

function AuctionSettings({league}) {
  function copyAuction(auction) {
    let newAuction = {...auction};
    newAuction.nominationOrder = newAuction.nominationOrder ? newAuction.nominationOrder.map(id => id) : null;
    newAuction.eventDateTime = newAuction.eventDateTime ? newAuction.eventDateTime : '';
    console.log('New Auction:', newAuction)
    return newAuction;
  }
  const user = useAppSelector((state) => state.user);
  const [auction, setAuction] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [resetting, setResetting] = React.useState(false);
  React.useEffect(() => {
    setAuction(copyAuction(league.currentSeason.auction));
    console.log('Current Auction Info:', league.currentSeason.auction)
  }, [league]);
  const canSchedule = useValidateAction("scheduleAuction", league);
  const canReset = useValidateAction("resetAuction", league);
  if(!auction) {
    return <Typography color="white">Loading auction data..</Typography>
  }
  function handleAuctionChange(event) {
    const {name, value} = event.target;
    const newAuction = {...auction};
    newAuction[name] = value;
    if (name === 'orderBase') { 
      if (value === 'CUSTOM') {
        newAuction.draftOrder = [league.teams.map(team => team.id)];
      }
    }
    setAuction(newAuction);
  }
  
  function handleContractLengthLimitsChange(length, event) {
    const {value} = event.target;
    const newAuction = {...auction};
    newAuction.contractLengthLimits.lengthNumbers[length] = value;
    setAuction(newAuction);
  }

  function reorderTeam(destination, source) {
    // dropped outside the list
    if (!destination) return;
    const newAuction = {...auction};
    const [removed] = newAuction.nominationOrder.splice(source.index, 1);
    newAuction.nominationOrder.splice(destination.index, 0, removed);
    setAuction(newAuction);
  }
  function cancelEdit() {
    setAuction(copyAuction(league.currentSeason.auction));
    setEditMode(false);
  }
  function saveOrUpdateAuction() {
    setEditMode(false);
    return leagueEventApi.updateLeagueEvent(auction.id, auction);
  }
  function saveAuctionSettings() {
  setErrors([])
  // TODO: Do we need to update league info based on this? Not sure it is required
  saveOrUpdateAuction()
    .then()
      .catch((error) => {
        let errors = [error.response.data.message];
        setErrors(errors);
      });
  }
  function resetAuction() {
    leagueEventApi.resetEvent(auction.id).then((response) => {
      setResetting(false);
    });
  }
  const teamSet = league.teams.reduce(function(map, team) {
    map[team.id] = team;
    return map;
  }, {});
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  let fullAuctionSettings = (
    <Box>
      <Box>
        <LeagueEventDatePicker eventDateTime={auction.eventDateTime} handleEventChange={handleAuctionChange} editMode={editMode} label="Auction Date/Time" />
        <TextField name="secondsPerNomination" label="Time per player nomination (seconds)" value={auction.secondsPerNomination} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 10, max: 86400}}} onChange={handleAuctionChange}/>
        <TextField name="secondsPerBid" label="Time per bid to win player (seconds)" value={auction.secondsPerBid} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 5, max: 86400}}} onChange={handleAuctionChange}/>
        <br/><br/>
        <TextField name="twoYearContracts" label="Two year contracts per team" value={auction.contractLengthLimits.lengthNumbers["2"]} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 0, max: 99}}} onChange={event => handleContractLengthLimitsChange("2", event.target.value)}/>
        <TextField name="threeYearContracts" label="Three year contracts per team" value={auction.contractLengthLimits.lengthNumbers["3"]} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 0, max: 99}}} onChange={event => handleContractLengthLimitsChange("3", event.target.value)}/>
        <TextField name="fourYearContracts" label="Four year contracts per team" value={auction.contractLengthLimits.lengthNumbers["4"]} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 0, max: 99}}} onChange={event => handleContractLengthLimitsChange("4", event.target.value)}/>
        <LeagueEventOrderSelector event={auction} handleEventChange={handleAuctionChange} editMode={editMode} firstSeason={league.totalSeasons === 1} teamSet={teamSet} setEvent={setAuction} roundBased={false} />
      </Box>
      {editMode ?
        <Box>
          <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
          <Button className='capsized-button' onClick={saveAuctionSettings} disabled={!auction.eventDateTime}>Save Auction Settings</Button>
        </Box>
        : <Box title={canSchedule ? "Edit Auction Settings" : "The Rookie Draft must be completed or bypassed in order to schedule the Auction"}>
          <Button className='capsized-button' onClick={() => setEditMode(true)} disabled={!canSchedule}>Edit Auction Settings</Button>
        </Box>
      }
    </Box>
  );
  const completedAuctionSettings = <Box>
    <Dialog open={resetting} maxWidth="sm" fullWidth>
      <DialogTitle>Reset Auction?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setResetting(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to reset the auction and all picks made?  You will have to reschedule a new auction after this.  This action is irreversable!</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setResetting(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={resetAuction}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Typography variant="p" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Free Agent Auction has been completed
    </Typography>
    <Button className='capsized-button' disabled={!canReset} onClick={() => setResetting(true)}>Reset Free Agent Auction</Button>
  </Box>;
  return (
  <Box className="centered" sx={{ border: 1, borderColor: 'primary.main', width: '50%' }}>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        {league.currentSeason.seasonYear} Free Agent Auction
      </Typography>
      {auction.status==='COMPLETE' ? completedAuctionSettings : fullAuctionSettings}
      {errorInfo}
    </Box>
  )
}

export default AuctionSettings;
