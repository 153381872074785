import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, TableContainer, Table, TableHead, TableRow, TableBody, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useLoadLeagueData, useUpdateLeagueData } from '../../../app/dataLoaderHooks';
import { styled } from '@mui/material/styles';
import { leagueEventApi, leagueSeasonApi } from '../../../adapters/APIExporter';
import AllTeams from '../team/AllTeams';
import EventCountdown from '../../util/EventCountdown';
import LeagueEventDisplay from '../leagueEvent/LeagueEventDisplay';
import { League, Team, LeaguePhase, GlobalConfig } from '../../../sdk/model';

const PAGE_SIZE_FOR_RECENT_EVENTS = 10;

function LeagueHome() {
  const {leagueId} = useParams();
  const [newRecentEvents, setNewRecentEvents] = React.useState([]);
  
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, 
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
             
  const [allHistoryLoaded, setAllHistoryLoaded] = React.useState(league?.recentEvents && league.recentEvents.length < PAGE_SIZE_FOR_RECENT_EVENTS);                                               
  
  let newLeagueData;
  if (newRecentEvents.length) {
    newLeagueData = {...league};
    if (newLeagueData.recentEvents) {
      newRecentEvents.forEach(event => {
        if (!newLeagueData.recentEvents.some(existing => existing.id === event.id)) {
          newLeagueData.recentEvents.push(event);
        }
      });
    } else {
      newLeagueData.recentEvents = newRecentEvents;
      newLeagueData.eventsLoaded = true;
    }
  }
  
  useUpdateLeagueData(newLeagueData);
  
  const globalConfig: GlobalConfig = useAppSelector((state) => state.globalConfigDataLoad)
  
  if (newRecentEvents.length) {
    setNewRecentEvents([]);
  }
  
  function loadHistory() {
    leagueEventApi.getLeagueEvents(league.id, league.recentEvents ? league.recentEvents.length : 0).then(response => {
      setNewRecentEvents(response.data);
      if(response.data.length < PAGE_SIZE_FOR_RECENT_EVENTS) {
        setAllHistoryLoaded(true);
      }
    });
  }
  
  function rolloverSeason() {
    leagueSeasonApi.rolloverSeason(league?.id);
  }
  
  React.useEffect(() => {
    if (league && !league.eventsLoaded && (!league.recentEvents || league.recentEvents.length < PAGE_SIZE_FOR_RECENT_EVENTS)) {
      loadHistory();
    }
  }, []);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  if (!league) {
    return <p>Loading...</p>
  }
  
  function getInviteLink() {
	return window.location.host + '/league/' + league?.id
  }

  function copyInviteLink() {
	navigator.clipboard.writeText(getInviteLink());
  }

  function getEventSection(league, leagueEvent, preparingPhase, eventType, deadlineEvents, activePhases, roomUrl) {
	var eventDate = leagueEvent && leagueEvent.eventDateTime ? 
	    new Date(leagueEvent.eventDateTime) : null;
	if (league?.phase === preparingPhase && eventDate) {
	  return leagueEvent.eventDateTime ?
        <Box>
          <Typography variant="body" component="div" color="text.secondary" sx={{ flexGrow: 1, fontSize: 16 }}>
            The {league?.currentSeason.seasonYear} {eventType} is on {eventDate.toLocaleDateString()} at {eventDate.toLocaleTimeString()}.  
            The deadline for {deadlineEvents} is 72 hours prior.  This is also when rosters will lock in 
            preparation for the {eventType}.
          </Typography>
          <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            Roster Deadline <EventCountdown dateTime={league.phaseTransitionDateTime} />
          </Typography>
          <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            {eventType} Starts <EventCountdown dateTime={leagueEvent.eventDateTime} />
          </Typography>
        </Box>
        : 
        <Typography variant="body" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            The {eventType} date/time has not yet been set
          <br/>
        </Typography>;
    }
    if (activePhases.includes(league?.phase) && eventDate) {
	  const ACTIVE_EVENT_STATUSES = new Set();
      ACTIVE_EVENT_STATUSES.add('PENDING');
      ACTIVE_EVENT_STATUSES.add('STARTED');
      ACTIVE_EVENT_STATUSES.add('PAUSED');
	  return <Box>  
        <Typography variant="body" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          The {league?.currentSeason?.seasonYear} {eventType} is on {eventDate.toLocaleDateString()} at {eventDate.toLocaleTimeString()}.
          The room will open one hour before the start of the {eventType}.
        </Typography>
        <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          {eventType} Starts <EventCountdown dateTime={leagueEvent.eventDateTime} />
        </Typography>
        {ACTIVE_EVENT_STATUSES.has(leagueEvent.status) ?
          <Button className='capsized-button' onClick={() => window.open(roomUrl + league?.id, '_blank', 'noreferrer')}>Enter {eventType} Room</Button>
          : null
        }
      </Box>;
    }
  }
  let draftSection = getEventSection(league, league?.currentSeason?.draft, 'OFFSEASON', 'Rookie Draft', 'franchise tags and fifth-year options', ['DRAFT_PREP','DRAFT'], '/draft/');
  let auctionSection = getEventSection(league, league?.currentSeason?.auction, 'TRAINING_CAMP', 'Free Agent Auction', 'franchise-tagged player long-term extensions', ['AUCTION_PREP','AUCTION'], '/auction/');
  const commish = league!.commissioners!.some(commish => commish.id === user.id);
  let inviteSection = league?.teams?.some(team => !team?.owners?.length) && commish ?
      <Box>
        <Typography variant="body" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            There are teams without owners in your league! Copy the following link to share with your friends:
          <br/>
          <Button onClick={copyInviteLink}>{getInviteLink()} (Click to copy)</Button>
        </Typography>
      </Box> : null;
      
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  let weekTracker = league.currentSeason?.finalWeekOfRegularSeason!;
  let playoffRound = 0;
  for (let playoffConfigIndex = 0; weekTracker < league.currentSeason?.currentWeek!; playoffConfigIndex++) {
    const playoffConfig = league.currentSeason?.playoffRoundConfig![playoffConfigIndex];
    playoffRound = playoffConfig?.roundNumber!;
    weekTracker += playoffConfig?.weeks!;
  }
  
  return <Box>
    {draftSection && <Box className="centered" sx={{ border: 1, borderColor: 'primary.main', width: '75%' }}>{draftSection}</Box>}
    {auctionSection && <Box className="centered" sx={{ border: 1, borderColor: 'primary.main', width: '75%' }}>{auctionSection}</Box>}
    {LeaguePhase.RegularSeason === league.phase && <Typography variant="h4" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Week {league.currentSeason?.currentWeek}
    </Typography>}
    {LeaguePhase.Playoffs === league.phase && <Typography variant="h4" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Playoffs Round {playoffRound}
    </Typography>}
    {LeaguePhase.SeasonComplete === league.phase && <Box>
      <Typography variant="h4">
        Congratulations, {league!.teams!.find(team => team.currentSeason!.overallRank! === 1)!.name} - {league.currentSeasonYear} League Champion!
      </Typography>
      <Typography variant="h4">
        {league!.teams!.find(team => team.currentSeason!.overallRank! === league.teams.length)!.name}, YOU SUCK.
      </Typography>
      {globalConfig.currentSeason === league.currentSeasonYear || (commish && <Button variant="contained" onClick={rolloverSeason}>Rollover League to the {globalConfig.currentSeason} Season</Button>)}
    </Box>}
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Teams:
    </Typography>
    {inviteSection}
    <AllTeams league={league} joinEnabled={true} />
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Recent Activities:
    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{fontSize : 20}}>
          <TableRow>
            <StyledTableCell>Date/Time</StyledTableCell>
            <StyledTableCell>Event Type</StyledTableCell>
            <StyledTableCell>Details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {league.recentEvents && league.recentEvents.map((event, eventIndex) => 
            <LeagueEventDisplay key={eventIndex} event={event} playerMap={playerMap} teamMap={teamMap} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {allHistoryLoaded ? <p>Beginning of league history</p> : <Button onClick={loadHistory}>Load More League History</Button>}
  </Box>;
}

export default LeagueHome;