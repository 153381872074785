/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RosterSwapInfo } from '../model';
// @ts-ignore
import { Team } from '../model';
// @ts-ignore
import { TeamPlayerInfo } from '../model';
/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds Player to Team
         * @summary Add\'s Player to a Team
         * @param {string} playerId Unique identifier for player
         * @param {string} teamId A unique identifier for a team.
         * @param {number} [bid] Bid amount (may want to use this in the future for handeling adding players through bids)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlayerToTeam: async (playerId: string, teamId: string, bid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerId' is not null or undefined
            assertParamExists('addPlayerToTeam', 'playerId', playerId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addPlayerToTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}/player/{playerId}`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bid !== undefined) {
                localVarQueryParameter['bid'] = bid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the details of a single instance of a `team`.
         * @summary Get a team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam: async (teamId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `team` entities.
         * @summary List All teams
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams: async (leagueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getTeams', 'leagueId', leagueId)
            const localVarPath = `/teams/league/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Assigns the logged-in user as an owner of the `team` with the given id
         * @summary Join the team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinTeam: async (teamId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('joinTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}/owner`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove\'s Player from Team
         * @param {string} playerId Unique identifier for player
         * @param {string} teamId A unique identifier for a team.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePlayerFromTeam: async (playerId: string, teamId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerId' is not null or undefined
            assertParamExists('removePlayerFromTeam', 'playerId', playerId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('removePlayerFromTeam', 'teamId', teamId)
            const localVarPath = `/teams/{teamId}/player/{playerId}`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId A unique identifier for a team
         * @param {RosterSwapInfo} rosterSwapInfo Information about the roster spots to be swapped on the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapRosterPositions: async (teamId: string, rosterSwapInfo: RosterSwapInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('swapRosterPositions', 'teamId', teamId)
            // verify required parameter 'rosterSwapInfo' is not null or undefined
            assertParamExists('swapRosterPositions', 'rosterSwapInfo', rosterSwapInfo)
            const localVarPath = `/teams/{teamId}/players/swap`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rosterSwapInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {Array<Team>} team Updated &#x60;team&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeagueTeams: async (leagueId: string, team: Array<Team>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('updateLeagueTeams', 'leagueId', leagueId)
            // verify required parameter 'team' is not null or undefined
            assertParamExists('updateLeagueTeams', 'team', team)
            const localVarPath = `/teams/league/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(team, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing `team`.
         * @summary Update a team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {Team} team Updated &#x60;team&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (teamId: string, team: Team, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateTeam', 'teamId', teamId)
            // verify required parameter 'team' is not null or undefined
            assertParamExists('updateTeam', 'team', team)
            const localVarPath = `/teams/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(team, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds Player to Team
         * @summary Add\'s Player to a Team
         * @param {string} playerId Unique identifier for player
         * @param {string} teamId A unique identifier for a team.
         * @param {number} [bid] Bid amount (may want to use this in the future for handeling adding players through bids)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlayerToTeam(playerId: string, teamId: string, bid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPlayerToTeam(playerId, teamId, bid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the details of a single instance of a `team`.
         * @summary Get a team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeam(teamId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `team` entities.
         * @summary List All teams
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeams(leagueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeams(leagueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Assigns the logged-in user as an owner of the `team` with the given id
         * @summary Join the team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinTeam(teamId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove\'s Player from Team
         * @param {string} playerId Unique identifier for player
         * @param {string} teamId A unique identifier for a team.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePlayerFromTeam(playerId: string, teamId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<TeamPlayerInfo>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removePlayerFromTeam(playerId, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId A unique identifier for a team
         * @param {RosterSwapInfo} rosterSwapInfo Information about the roster spots to be swapped on the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swapRosterPositions(teamId: string, rosterSwapInfo: RosterSwapInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<TeamPlayerInfo>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swapRosterPositions(teamId, rosterSwapInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {Array<Team>} team Updated &#x60;team&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLeagueTeams(leagueId: string, team: Array<Team>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLeagueTeams(leagueId, team, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing `team`.
         * @summary Update a team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {Team} team Updated &#x60;team&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(teamId: string, team: Team, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(teamId, team, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * Adds Player to Team
         * @summary Add\'s Player to a Team
         * @param {string} playerId Unique identifier for player
         * @param {string} teamId A unique identifier for a team.
         * @param {number} [bid] Bid amount (may want to use this in the future for handeling adding players through bids)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlayerToTeam(playerId: string, teamId: string, bid?: number, options?: any): AxiosPromise<void> {
            return localVarFp.addPlayerToTeam(playerId, teamId, bid, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the details of a single instance of a `team`.
         * @summary Get a team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam(teamId: string, options?: any): AxiosPromise<Team> {
            return localVarFp.getTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `team` entities.
         * @summary List All teams
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams(leagueId: string, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.getTeams(leagueId, options).then((request) => request(axios, basePath));
        },
        /**
         * Assigns the logged-in user as an owner of the `team` with the given id
         * @summary Join the team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinTeam(teamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.joinTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove\'s Player from Team
         * @param {string} playerId Unique identifier for player
         * @param {string} teamId A unique identifier for a team.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePlayerFromTeam(playerId: string, teamId: string, options?: any): AxiosPromise<{ [key: string]: Array<TeamPlayerInfo>; }> {
            return localVarFp.removePlayerFromTeam(playerId, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId A unique identifier for a team
         * @param {RosterSwapInfo} rosterSwapInfo Information about the roster spots to be swapped on the team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapRosterPositions(teamId: string, rosterSwapInfo: RosterSwapInfo, options?: any): AxiosPromise<{ [key: string]: Array<TeamPlayerInfo>; }> {
            return localVarFp.swapRosterPositions(teamId, rosterSwapInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {Array<Team>} team Updated &#x60;team&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeagueTeams(leagueId: string, team: Array<Team>, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.updateLeagueTeams(leagueId, team, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing `team`.
         * @summary Update a team
         * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
         * @param {Team} team Updated &#x60;team&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(teamId: string, team: Team, options?: any): AxiosPromise<void> {
            return localVarFp.updateTeam(teamId, team, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * Adds Player to Team
     * @summary Add\'s Player to a Team
     * @param {string} playerId Unique identifier for player
     * @param {string} teamId A unique identifier for a team.
     * @param {number} [bid] Bid amount (may want to use this in the future for handeling adding players through bids)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public addPlayerToTeam(playerId: string, teamId: string, bid?: number, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).addPlayerToTeam(playerId, teamId, bid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the details of a single instance of a `team`.
     * @summary Get a team
     * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeam(teamId: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `team` entities.
     * @summary List All teams
     * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeams(leagueId: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeams(leagueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Assigns the logged-in user as an owner of the `team` with the given id
     * @summary Join the team
     * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public joinTeam(teamId: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).joinTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove\'s Player from Team
     * @param {string} playerId Unique identifier for player
     * @param {string} teamId A unique identifier for a team.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public removePlayerFromTeam(playerId: string, teamId: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).removePlayerFromTeam(playerId, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId A unique identifier for a team
     * @param {RosterSwapInfo} rosterSwapInfo Information about the roster spots to be swapped on the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public swapRosterPositions(teamId: string, rosterSwapInfo: RosterSwapInfo, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).swapRosterPositions(teamId, rosterSwapInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
     * @param {Array<Team>} team Updated &#x60;team&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public updateLeagueTeams(leagueId: string, team: Array<Team>, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).updateLeagueTeams(leagueId, team, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing `team`.
     * @summary Update a team
     * @param {string} teamId A unique identifier for a &#x60;team&#x60;.
     * @param {Team} team Updated &#x60;team&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public updateTeam(teamId: string, team: Team, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).updateTeam(teamId, team, options).then((request) => request(this.axios, this.basePath));
    }
}
