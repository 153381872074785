import { createSlice } from '@reduxjs/toolkit'
import { TeamPlayerClaims } from '../sdk/model'
import { enableMapSet } from 'immer';

enableMapSet();

const initialState : Map<string, TeamPlayerClaims[]> = new Map();

const playerClaimDataLoadSlice = createSlice({
  name: 'playerClaimDataLoad',
  initialState,
  reducers: {
    setPlayerClaimDataLoad (state, action) {
      return state = action.payload
    }
  }
})

export const { setPlayerClaimDataLoad } = playerClaimDataLoadSlice.actions
export default playerClaimDataLoadSlice.reducer