import React from 'react';
import "../component.css";
import "./style.css";
import { Container, Typography, Grid,} from '@mui/material';
import Paper from '@mui/material/Paper';

const AboutUs = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{ minHeight: '100vh' }}
    >
      <Paper maxWidth="md" style={{ marginTop: '50px', marginBottom: '50px' }} sx={{ width: '75%', alignSelf: 'center' }}  elevation={3}  >
        <Typography variant="h4" align="center" gutterBottom fontWeight={'bold'}>About Us</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center" >
              Welcome to our fantasy football application!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              We are a group of passionate football fans who have developed this app to help fellow enthusiasts like you to manage and enjoy your fantasy football season. Our team consists of experienced developers and avid football fans who understand the thrill of fantasy football and are dedicated to making your experience as enjoyable as possible.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Our mission is to provide you with the tools and information you need to manage your fantasy football team with ease. We know that you take your fantasy football seriously, and we are committed to providing you with accurate and up-to-date information on players, teams, and leagues. Our app includes a variety of features, including player rankings, injury reports, game schedules, and news updates, to help you make informed decisions and stay ahead of the competition.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              At our core, we believe that fantasy football is more than just a game - it's a community. That's why we have built this app to be social and interactive, allowing you to connect with other players, join or create leagues, and share your thoughts and insights with the community. We believe that the camaraderie and friendly competition that comes with fantasy football are just as important as the game itself.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Thank you for choosing our app as your fantasy football companion. We hope that our app helps you to enjoy the season to the fullest and that you have a great time managing your team with us.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AboutUs;
