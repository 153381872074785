import * as React from 'react'
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Close from "@mui/icons-material/Close";
import { Typography, IconButton, DialogTitle, Dialog,
    DialogContent, DialogActions, InputLabel, Checkbox, TextField, Select, MenuItem, Button } from '@mui/material';
import { leagueEventApi } from '../../../../adapters/APIExporter';
import { useAppSelector } from '../../../../app/hooks'
import { useValidateAction } from '../../../util/ActionValidationUtil';
import LeagueEventDatePicker from './LeagueEventDatePicker';
import LeagueEventOrderSelector from './LeagueEventOrderSelector';

function DraftSettings({league}) {
  function copyDraft(draft) {
  	let newDraft = {...draft};
  	newDraft.draftOrder = draft.draftOrder ? draft.draftOrder.map(round => round.map(id => id)) : null;
  	newDraft.eventDateTime = draft.eventDateTime ? draft.eventDateTime : '';
    console.log('New Draft:', newDraft)
  	return newDraft;
  }
  const user = useAppSelector((state) => state.user);
  const [draft, setDraft] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [resetting, setResetting] = React.useState(false);
  const canSchedule = useValidateAction("scheduleRookieDraft", league);
  const canReset = useValidateAction("resetRookieDraft", league);
  React.useEffect(() => {
	  setDraft(league.currentSeason.draft && league.currentSeason.draft.status !== 'CANCELLED' ? copyDraft(league.currentSeason.draft) : null);
    console.log('Current Draft Info:', league.currentSeason.draft)
  }, [league]);
  if(draft && !draft?.draftOrder) {
    console.log('Draft:', draft)
    console.log('Draft Order:', draft.draftOrder)
    return <Typography color="white">Loading draft data..</Typography>
  }
  function toggleDraft() {
	if (draft) {
	  setDraft(null);
	} else {
	  setDraft(league.currentSeason.draft ? copyDraft(league.currentSeason.draft) : {
		leagueId: league.id,
		seasonYear: league.currentSeason.seasonYear,
		type: 'draft',
		status: 'UNSCHEDULED',
		eventDateTime: '',
		rounds: 3,
		secondsPerPick: 10,
		orderBase: league.totalSeasons === 1 ? 'RANDOM' : 'STANDINGS',
		draftType: league.totalSeasons === 1 ? 'SNAKE' : 'LINEAR',
		draftOrder: []
	  });
	}
  }
  function handleDraftChange(event) {
	const {name, value} = event.target;
    const newDraft = {...draft};
    if (name === 'orderBase') { 
	  if (value === 'CUSTOM') {
        newDraft.draftOrder = [league.teams.map(team => team.id)];
      } else if (newDraft.draftType === 'MANUAL') {
	    newDraft.draftType = league.totalSeasons === 1 ? 'SNAKE' : 'LINEAR';
      }
    } else if (name === 'draftType' && newDraft.orderBase === 'CUSTOM') {
	  if (value === 'MANUAL') {
		newDraft.draftOrder = Array.from({ length : newDraft.rounds }, () => [...newDraft.draftOrder[0]]);
	  } else if (newDraft.draftType === 'MANUAL') {
		newDraft.draftOrder = [newDraft.draftOrder[0]];
	  }
    } else if (name === 'rounds' && newDraft.draftType === 'MANUAL') {
	  if (value < 1) {
		return;
	  }
      if (value > newDraft.rounds) {
		for (var i=newDraft.rounds; i < value; i++) {
			newDraft.draftOrder.push([...newDraft.draftOrder[newDraft.rounds - 1]]);
		}
	  } else {
		for (var i=newDraft.rounds; i > value; i--) {
			newDraft.draftOrder.pop();
		}
	  }
    }
    newDraft[name] = value;
    setDraft(newDraft);
  }
  
  function cancelEdit() {
	setDraft(league.currentSeason.draft && league.currentSeason.draft.status !== 'CANCELLED' ? copyDraft(league.currentSeason.draft) : null);
	setEditMode(false);
  }
  function saveOrUpdateDraft() {
	setEditMode(false);
	if (!draft) { 
	  if (league.currentSeason.draft) {
	  	return leagueEventApi.deleteLeagueEvent(league.currentSeason.draft.id);
      }
      return new Promise( (resolve) => { resolve(); });
	}
    if (draft.id) {
	  return leagueEventApi.updateLeagueEvent(draft.id, draft);
    }
    return leagueEventApi.createLeagueEvent(draft);
  }
  function saveDraftSettings() {
	setErrors([])
  // TODO: Do we need to update league info based on this? Not sure it is required
	saveOrUpdateDraft()
	  .then()
      .catch((error) => {
        let errors = [error.response.data.message];
        setErrors(errors);
  	  });
  }
  function resetDraft() {
    leagueEventApi.resetEvent(draft.id).then((response) => {
      setResetting(false);
    });
  }
  
  const teamSet = league.teams.reduce(function(map, team) {
    map[team.id] = team;
    return map;
  }, {});
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  let fullDraftSettings = (
    <Box>
      <InputLabel id="bypassDraftLabel">Bypass Rookie Draft
        <Tooltip title="We recommend bypassing the Rookie Draft in Year 1 to start everyone on an equal playing field">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Checkbox checked={!draft} labelId="bypassDraftLabel" onChange={toggleDraft} disabled={!editMode}/>
      </InputLabel>
      <br/>
      {draft && (<Box>
        <LeagueEventDatePicker eventDateTime={draft.eventDateTime} handleEventChange={handleDraftChange} editMode={editMode} label="Draft Date/Time" />
        <TextField name="rounds" label="Number of rounds" value={draft.rounds} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 1, max: 5}}} onChange={handleDraftChange}/>
        <TextField name="secondsPerPick" label="Time per Pick (seconds)" value={draft.secondsPerPick} type="number" disabled={!editMode}
                InputProps={{inputProps: {min: 30, max: 86400}}} onChange={handleDraftChange}/>
        <LeagueEventOrderSelector event={draft} handleEventChange={handleDraftChange} editMode={editMode} firstSeason={league.totalSeasons === 1} teamSet={teamSet} setEvent={setDraft} roundBased={true} />
      </Box>)}
      {editMode ?
        <Box>
          <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
          <Button className='capsized-button' onClick={saveDraftSettings} disabled={draft && !draft.eventDateTime}>Save Draft Settings</Button>
        </Box>
        : <Box title={canSchedule ? "Edit Draft Settings" : "You must first reset the results of the auction before you can schedule a draft"}>
          <Button className='capsized-button' onClick={() => setEditMode(true)} disabled={!canSchedule}>Edit Draft Settings</Button>
        </Box>
      }
    </Box>
  );
  const completedDraftSettings = <Box>
    <Dialog open={resetting} maxWidth="sm" fullWidth>
      <DialogTitle>Reset Draft?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setResetting(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to reset the draft and all picks made?  You will have to reschedule a new draft after this.  This action is irreversable!</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setResetting(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={resetDraft}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Typography variant="p" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Rookie draft has been completed
    </Typography>
    <Box title={canReset ? "Reset Draft" : "You must first reset the results of the auction before you can reset the draft"}>
      <Button className='capsized-button' onClick={() => setResetting(true)} disabled={!canReset}>Reset Draft</Button>
    </Box>
  </Box>;
  return (
    <Box className="centered" sx={{ border: 1, borderColor: 'primary.main', width: '50%' }}>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        {league.currentSeason.seasonYear} Rookie Draft
      </Typography>
      {draft && draft.status==='COMPLETE' ? completedDraftSettings : fullDraftSettings}
      {errorInfo}
    </Box>
  )
}

export default DraftSettings;
