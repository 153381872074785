import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { AcquisitionDrop, Team } from '../../../sdk/model';
import {Button} from '@mui/material';

interface acquisitionDropDisplayProps {
  acquisitionDrop: AcquisitionDrop,
  teamMap: Map<string | undefined, Team>
}

function AcquisitionDropDisplay(props: acquisitionDropDisplayProps) {
  const navigate = useNavigate();
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  
  const team = props.teamMap.get(props.acquisitionDrop.teamId)!;
  return <p>Waived by {team.name} while signing <Button onClick={() => navigate('/league/' + props.acquisitionDrop.leagueId + '/playerAcquisitionEvent/' + props.acquisitionDrop.leagueEventId)}>{playerMap.get(props.acquisitionDrop.acquiredPlayerId)!.abbr_name}</Button></p>
}

export default AcquisitionDropDisplay;