import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Image } from 'mui-image';
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import ProfileButton from './accountButton';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const pages = [
  {title: 'Search', link: '/search'}, 
  {title: 'About Us', link: '/about_us'}, 
  {title: 'Blog', link: 'https://blog.capsized-fantasy.com'}];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const location = useLocation();
  const isRoot = location.pathname === '/';

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {

    setAnchorElNav(null);
  };

  const handleNavMenuSelection = (page: string) => {
    // access to e.target here
    console.log(page);
    if(page.startsWith('/'))
      navigate(page);
    else
      window.open(page, '_blank');
  };
  

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Grid sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
          <Image height="50px" width="50px"
            src="/assets/image/Capsized_Logo_1_2.jpg" />
        </Grid>
          <Typography
            variant="h6"
            noWrap
            component={isRoot ? 'p' : 'a'} // 'p' for paragraph if on root, otherwise 'a' for link
            onClick={isRoot ? undefined : (() => handleNavMenuSelection('/'))}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CAPSized
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.link}
                href="#"
                onClick={(event => handleNavMenuSelection(page.link))}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          <ProfileButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;