import * as React from 'react';
import {Pressable} from 'react-native';
import { PlayoffRoundConfig, LeagueGame, Team } from '../../../sdk/model';
import {Box, Typography, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {Bracket, IRoundProps, ISeedProps, Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';

interface playoffBracketProps {
  leagueId: string,
  gamesByWeek: LeagueGame,
  displayTeam: Function,
  teams: Team[],
  finalWeekOfRegularSeason: number,
  playoffRoundConfig: Array<PlayoffRoundConfig>
}

function getGameThisWeek(gamesThisWeek : LeagueGame[], gameIndex : number) : LeagueGame | null {
  if (gamesThisWeek.length > gameIndex) {
    return gamesThisWeek[gameIndex];
  }
  return null;
}

function getRoundTitle(numberOfRounds : number, roundNumber : number, playoffs : boolean) : string {
  if (roundNumber === numberOfRounds) {
    if (playoffs) {
      return 'Championship';
    }
    return 'Toilet Bowl';
  }
  if (roundNumber === numberOfRounds - 1) {
    return 'Semi-Finals';
  }
  return 'Round ' + roundNumber;
}

function getTeamStatus(thisTeamId : string | undefined, winningTeamId : string | undefined) {
  if (!thisTeamId || !winningTeamId) {
    return null;
  }
  return thisTeamId === winningTeamId ? 'WIN' : 'LOSS';
}

function PlayoffBracket(props: playoffBracketProps) {
  const navigate = useNavigate();
  const numberOfRounds = Math.max(...props.playoffRoundConfig.map(round => round.roundNumber!));
  
  function getBracketRounds(gameFilter : Function, playoffs : boolean) : IRoundProps[] {
    return props.playoffRoundConfig.map(round => {
      const weekNumber = props.finalWeekOfRegularSeason + round.roundNumber!;
      let gamesThisWeek = props.gamesByWeek[weekNumber.toString()] || [];
      gamesThisWeek = gamesThisWeek.filter(gameFilter)
        .sort((game1, game2) => game1.bracketGameNumber - game2.bracketGameNumber);
      const numberOfGamesThisWeek = 2 ** (numberOfRounds - round.roundNumber!);
      const roundTitle = getRoundTitle(numberOfRounds, round.roundNumber!, playoffs);
      const seeds : ISeedProps[] = [...Array(numberOfGamesThisWeek).keys()].map(gameIndex => {
        const game = getGameThisWeek(gamesThisWeek, gameIndex);
        const awayTeam = props.teams.find(team => team.id === game?.awayTeamId);
        const homeTeam = props.teams.find(team => team.id === game?.homeTeamId);
        return {
          id: game?.id!,
          date: "Week " + weekNumber,
          teams: [
            {
              name: awayTeam ? "#" + awayTeam?.currentSeason?.playoffSeedRank! + " " + awayTeam?.name! : homeTeam ? 'BYE' : "TBD", 
              score: awayTeam ? game?.awayTeamScore : '-',
              status: getTeamStatus(game?.awayTeamId, game?.winningTeamId)
            },
            {
              name: homeTeam ? "#" + homeTeam?.currentSeason?.playoffSeedRank! + " " + homeTeam?.name! : awayTeam ? 'BYE' : "TBD", 
              score: homeTeam ? game?.homeTeamScore : '-',
              status: getTeamStatus(game?.homeTeamId, game?.winningTeamId)
            }
          ]
        }
      });
      return {
        title: roundTitle,
        seeds : seeds
      }
    });
  }

  const playoffRounds = getBracketRounds(game => game.playoff, true);
  const consolationGames : IRoundProps[] = Object.values(props.gamesByWeek)
    .map(games => games.filter(game => game.postseasonRoundNumber && !game.playoff && !game.loserBracketGame))
    .filter(games => games.length > 0)
    .map(weekOfGames => {
      return {
        title: '',
        seeds: weekOfGames.map(game => {
          const awayTeam = props.teams.find(team => team.id === game?.awayTeamId);
          const homeTeam = props.teams.find(team => team.id === game?.homeTeamId);
          return {
            id: game?.id!,
            date: "Week " + game?.weekNumber,
            teams: [
              {
                name: "#" + awayTeam?.currentSeason?.playoffSeedRank! + " " + awayTeam?.name!, 
                score: game?.awayTeamScore,
                status: getTeamStatus(game?.awayTeamId, game?.winningTeamId)
              },
              {
                name: "#" + homeTeam?.currentSeason?.playoffSeedRank! + " " + homeTeam?.name!, 
                score: game?.homeTeamScore,
                status: getTeamStatus(game?.homeTeamId, game?.winningTeamId)
              }
            ]
          }
        })
      }
    })
  const loserBracketRounds = getBracketRounds(game => game.loserBracketGame, false);
  
  const CustomSeed = ({seed, breakpoint, roundIndex, seedIndex}: IRenderSeedProps) => {
    return (
      <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
          <Pressable onPress={() => navigate(`/league/${props.leagueId}/matchup/id/${seed.id}`)} disabled={!seed.id} >
            <SeedTeam className={seed.teams[0]?.status === 'WIN' ? 'winning-team' : seed.teams[0]?.status === 'LOSS' ? 'losing-team' : null}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={8} textAlign="left">
                  {seed.teams[0]?.name}
                </Grid>
                <Grid item xs={4}  textAlign="right">
                  {seed.teams[0]?.score}
                </Grid>
              </Grid>
            </SeedTeam>
            <SeedTeam className={seed.teams[1]?.status === 'WIN' ? 'winning-team' : seed.teams[1]?.status === 'LOSS' ? 'losing-team' : null}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={8} textAlign="left">
                  {seed.teams[1]?.name}
                </Grid>
                <Grid item xs={4}  textAlign="right">
                  {seed.teams[1]?.score}
                </Grid>
              </Grid>
            </SeedTeam>
          </Pressable>
        </SeedItem>
        <Typography variant="body2" color="text.secondary" sx={{fontStyle: 'italic'}}>{seed.date}</Typography>
      </Seed>
    );
  };

  
  return <Box>
    <br/>
    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
      Playoffs
    </Typography>
    <br/>
    <Bracket rounds={playoffRounds} renderSeedComponent={CustomSeed} />
    <br/>
    {consolationGames.length > 0 && <Box>
      <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
        Consolation Games
      </Typography>
      <br/>
      <Bracket rounds={consolationGames} renderSeedComponent={CustomSeed} />
      <br/>
    </Box>}
    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
      Loser Bracket
    </Typography>
    <br/>
    <Bracket rounds={loserBracketRounds} renderSeedComponent={CustomSeed} />
  </Box>
}

export default PlayoffBracket;