import { createSlice } from '@reduxjs/toolkit'
import { NflTeam } from '../sdk/model'

const initialState: NflTeam[] = []

const playerDataLoadSlice = createSlice({
  name: 'playerDataLoad',
  initialState,
  reducers: {
    setPlayerDataLoad (state, action) {
      console.log(action)
      return state = action.payload
      // return state = {...state, ...action.payload}
    }
  }
})

export const { setPlayerDataLoad } = playerDataLoadSlice.actions
export default playerDataLoadSlice.reducer