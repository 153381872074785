import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { leagueEventApi } from '../../../adapters/APIExporter';
import DraftBoard from './component/DraftBoard';
import { Team } from '../../../sdk/model';
import Box from '@mui/material/Box';
import { Select, MenuItem, Typography } from '@mui/material';
import TradeAtAGlance from '../trade/TradeAtAGlance';

function DraftHistory({league}) {
  const [searchParams] = useSearchParams();
  const defaultSeasonYear = searchParams.get("seasonYear");
  const [seasonYear, setSeasonYear] = React.useState(defaultSeasonYear ? defaultSeasonYear : league.currentSeasonYear);
  const [draft, setDraft] = React.useState(undefined);
  const [loadingDraft, setLoadingDraft] = React.useState(true);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  
  React.useEffect(() => {
    leagueEventApi.getLeagueEvents(league.id, 0, seasonYear, 'draft', true).then(response => {
      if (response.data.length) {
        setDraft(response.data[0])
      }
      setLoadingDraft(false);
    });
  }, [seasonYear])
  
  if (loadingDraft) {
    return <p>Loading...</p>
  }
  
  const midDraftTrades = draft ? draft.activityHistory.filter(activity => activity.type === 'mid_draft_trade') : [];
  
  return <Box>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Draft History
    </Typography>
    <Select name="seasonYear" label="Season" labelId="seasonYearLabel" value={seasonYear}
        onChange={event => setSeasonYear(event.target.value)}>
      {[...Array(league.totalSeasons).keys()]
        .map(seasonIndex => league.currentSeasonYear - league.totalSeasons + seasonIndex + 1)
        .map(seasonYearOption => <MenuItem key={seasonYearOption} value={seasonYearOption}>{seasonYearOption}</MenuItem>)}
    </Select>
    <br/>
    {draft ? <Box>
      <DraftBoard draft={draft} teamMap={teamMap} playerMap={playerMap} />
      {midDraftTrades.length === 0 ? <p>No trades executed during this draft</p> : <Box>
        <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          Trades Executed During this Draft:
        </Typography>
        {midDraftTrades.map(trade => 
          <TradeAtAGlance leagueId={league.id!} tradeStatus="COMPLETE" teams={trade.tradeProposal.teams} picks={trade.tradeProposal.picks} players={trade.tradeProposal.players} playerMap={playerMap} teamMap={teamMap} pickMap={undefined} />
        )}
      </Box>}
      </Box> : <p>Draft data not found</p>
    }
  </Box>
}

export default DraftHistory;
