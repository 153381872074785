import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/user'
import playerLoadReducer from './slices/playerDataLoad'
import actionConfigDataReducer from './slices/actionConfigDataLoad'
import leagueDataReducer from './slices/leagueDataLoad'
import tradeDataReducer from './slices/tradeDataLoad'
import playerClaimDataReducer from './slices/playerClaimDataLoad'
import globalConfigDataReducer from './slices/globalConfigDataLoad'

// NOTE: When creating a slicer you need to add it to this config store in order to access it in the app
const store = configureStore({
  reducer: {
    user: userReducer,
    playerDataLoad: playerLoadReducer,
    actionConfigDataLoad: actionConfigDataReducer,
    leagueDataLoad: leagueDataReducer,
    tradeDataLoad: tradeDataReducer,
    playerClaimDataLoad: playerClaimDataReducer,
    globalConfigDataLoad: globalConfigDataReducer,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store