/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LeagueSeason } from '../model';
/**
 * LeagueSeasonApi - axios parameter creator
 * @export
 */
export const LeagueSeasonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {number} seasonYear Year Associated with a specific season
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTagValues: async (leagueId: string, seasonYear: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('calculateTagValues', 'leagueId', leagueId)
            // verify required parameter 'seasonYear' is not null or undefined
            assertParamExists('calculateTagValues', 'seasonYear', seasonYear)
            const localVarPath = `/league_seasons/{leagueId}/{seasonYear}/calculateTags`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"seasonYear"}}`, encodeURIComponent(String(seasonYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of completed seasons
         * @param {string} leagueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompletedLeagueSeasons: async (leagueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getAllCompletedLeagueSeasons', 'leagueId', leagueId)
            const localVarPath = `/league_seasons/completed/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current season
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {boolean} [includeDraft] Include Draft Information
         * @param {boolean} [includeAuction] Include Auction Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSeason: async (leagueId: string, includeDraft?: boolean, includeAuction?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getCurrentSeason', 'leagueId', leagueId)
            const localVarPath = `/league_seasons/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeDraft !== undefined) {
                localVarQueryParameter['includeDraft'] = includeDraft;
            }

            if (includeAuction !== undefined) {
                localVarQueryParameter['includeAuction'] = includeAuction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of seasons
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {number} seasonYear Year Associated with a specific season
         * @param {boolean} [includeDraft] Include Draft Information
         * @param {boolean} [includeAuction] Include Auction Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueSeason: async (leagueId: string, seasonYear: number, includeDraft?: boolean, includeAuction?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getLeagueSeason', 'leagueId', leagueId)
            // verify required parameter 'seasonYear' is not null or undefined
            assertParamExists('getLeagueSeason', 'seasonYear', seasonYear)
            const localVarPath = `/league_seasons/{leagueId}/{seasonYear}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"seasonYear"}}`, encodeURIComponent(String(seasonYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeDraft !== undefined) {
                localVarQueryParameter['includeDraft'] = includeDraft;
            }

            if (includeAuction !== undefined) {
                localVarQueryParameter['includeAuction'] = includeAuction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueId Unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolloverSeason: async (leagueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('rolloverSeason', 'leagueId', leagueId)
            const localVarPath = `/league_seasons/{leagueId}/rollover`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Does setup for a specific week (i.e. finalizing game rosters and scoring)
         * @param {number} week Week Number
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreAndLockWeek: async (week: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'week' is not null or undefined
            assertParamExists('scoreAndLockWeek', 'week', week)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('scoreAndLockWeek', 'year', year)
            const localVarPath = `/league_seasons/week/{week}/year/{year}/scoreAndComplete`
                .replace(`{${"week"}}`, encodeURIComponent(String(week)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueId A unique identifier for a league
         * @param {LeagueSeason} leagueSeason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentLeagueSeason: async (leagueId: string, leagueSeason: LeagueSeason, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('updateCurrentLeagueSeason', 'leagueId', leagueId)
            // verify required parameter 'leagueSeason' is not null or undefined
            assertParamExists('updateCurrentLeagueSeason', 'leagueSeason', leagueSeason)
            const localVarPath = `/league_seasons/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueSeason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueSeasonApi - functional programming interface
 * @export
 */
export const LeagueSeasonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueSeasonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {number} seasonYear Year Associated with a specific season
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateTagValues(leagueId: string, seasonYear: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateTagValues(leagueId, seasonYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of completed seasons
         * @param {string} leagueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompletedLeagueSeasons(leagueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeagueSeason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompletedLeagueSeasons(leagueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current season
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {boolean} [includeDraft] Include Draft Information
         * @param {boolean} [includeAuction] Include Auction Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSeason(leagueId: string, includeDraft?: boolean, includeAuction?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueSeason>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSeason(leagueId, includeDraft, includeAuction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of seasons
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {number} seasonYear Year Associated with a specific season
         * @param {boolean} [includeDraft] Include Draft Information
         * @param {boolean} [includeAuction] Include Auction Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagueSeason(leagueId: string, seasonYear: number, includeDraft?: boolean, includeAuction?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueSeason>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagueSeason(leagueId, seasonYear, includeDraft, includeAuction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueId Unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolloverSeason(leagueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolloverSeason(leagueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Does setup for a specific week (i.e. finalizing game rosters and scoring)
         * @param {number} week Week Number
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreAndLockWeek(week: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreAndLockWeek(week, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueId A unique identifier for a league
         * @param {LeagueSeason} leagueSeason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentLeagueSeason(leagueId: string, leagueSeason: LeagueSeason, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentLeagueSeason(leagueId, leagueSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeagueSeasonApi - factory interface
 * @export
 */
export const LeagueSeasonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueSeasonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {number} seasonYear Year Associated with a specific season
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTagValues(leagueId: string, seasonYear: number, options?: any): AxiosPromise<void> {
            return localVarFp.calculateTagValues(leagueId, seasonYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of completed seasons
         * @param {string} leagueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompletedLeagueSeasons(leagueId: string, options?: any): AxiosPromise<Array<LeagueSeason>> {
            return localVarFp.getAllCompletedLeagueSeasons(leagueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current season
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {boolean} [includeDraft] Include Draft Information
         * @param {boolean} [includeAuction] Include Auction Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSeason(leagueId: string, includeDraft?: boolean, includeAuction?: boolean, options?: any): AxiosPromise<LeagueSeason> {
            return localVarFp.getCurrentSeason(leagueId, includeDraft, includeAuction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of seasons
         * @param {string} leagueId League Id Associated with a specific season.
         * @param {number} seasonYear Year Associated with a specific season
         * @param {boolean} [includeDraft] Include Draft Information
         * @param {boolean} [includeAuction] Include Auction Information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueSeason(leagueId: string, seasonYear: number, includeDraft?: boolean, includeAuction?: boolean, options?: any): AxiosPromise<LeagueSeason> {
            return localVarFp.getLeagueSeason(leagueId, seasonYear, includeDraft, includeAuction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueId Unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolloverSeason(leagueId: string, options?: any): AxiosPromise<void> {
            return localVarFp.rolloverSeason(leagueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Does setup for a specific week (i.e. finalizing game rosters and scoring)
         * @param {number} week Week Number
         * @param {number} year Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreAndLockWeek(week: number, year: number, options?: any): AxiosPromise<void> {
            return localVarFp.scoreAndLockWeek(week, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueId A unique identifier for a league
         * @param {LeagueSeason} leagueSeason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentLeagueSeason(leagueId: string, leagueSeason: LeagueSeason, options?: any): AxiosPromise<void> {
            return localVarFp.updateCurrentLeagueSeason(leagueId, leagueSeason, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueSeasonApi - object-oriented interface
 * @export
 * @class LeagueSeasonApi
 * @extends {BaseAPI}
 */
export class LeagueSeasonApi extends BaseAPI {
    /**
     * 
     * @param {string} leagueId League Id Associated with a specific season.
     * @param {number} seasonYear Year Associated with a specific season
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public calculateTagValues(leagueId: string, seasonYear: number, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).calculateTagValues(leagueId, seasonYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of completed seasons
     * @param {string} leagueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public getAllCompletedLeagueSeasons(leagueId: string, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).getAllCompletedLeagueSeasons(leagueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current season
     * @param {string} leagueId League Id Associated with a specific season.
     * @param {boolean} [includeDraft] Include Draft Information
     * @param {boolean} [includeAuction] Include Auction Information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public getCurrentSeason(leagueId: string, includeDraft?: boolean, includeAuction?: boolean, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).getCurrentSeason(leagueId, includeDraft, includeAuction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of seasons
     * @param {string} leagueId League Id Associated with a specific season.
     * @param {number} seasonYear Year Associated with a specific season
     * @param {boolean} [includeDraft] Include Draft Information
     * @param {boolean} [includeAuction] Include Auction Information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public getLeagueSeason(leagueId: string, seasonYear: number, includeDraft?: boolean, includeAuction?: boolean, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).getLeagueSeason(leagueId, seasonYear, includeDraft, includeAuction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueId Unique identifier for a league
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public rolloverSeason(leagueId: string, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).rolloverSeason(leagueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Does setup for a specific week (i.e. finalizing game rosters and scoring)
     * @param {number} week Week Number
     * @param {number} year Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public scoreAndLockWeek(week: number, year: number, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).scoreAndLockWeek(week, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueId A unique identifier for a league
     * @param {LeagueSeason} leagueSeason 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueSeasonApi
     */
    public updateCurrentLeagueSeason(leagueId: string, leagueSeason: LeagueSeason, options?: AxiosRequestConfig) {
        return LeagueSeasonApiFp(this.configuration).updateCurrentLeagueSeason(leagueId, leagueSeason, options).then((request) => request(this.axios, this.basePath));
    }
}
