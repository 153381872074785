import * as React from 'react';
import { DraftPick } from '../../sdk/model';
import { draftApi } from '../../adapters/APIExporter';
import { Modal, Button } from '@mui/material';
import DraftPickCard from './draftPickCard';

interface draftPickCardLinkProps {
  pick: DraftPick | undefined,
  pickId: string | undefined,
  leagueId: string,
  children
}

// TODO: Deal with an undefined player
function DraftPickCardLink(props: draftPickCardLinkProps) {
  const [viewingPick, setViewingPick] = React.useState<DraftPick | undefined>(undefined);
  function setupViewingPick() {
    if (props.pick) {
      setViewingPick(props.pick);
    } else {
      draftApi.getDraftPick(props.pickId).then(response => setViewingPick(response.data));
    }
  }
  return <React.Fragment>
    <Modal open={viewingPick !== undefined} onClose={() => setViewingPick(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <DraftPickCard pick={viewingPick!} leagueId={props.leagueId} />
    </Modal>
    <Button onClick={setupViewingPick}>{props.children}</Button>
  </React.Fragment>
}

export default DraftPickCardLink;

