import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks'
import { League, TeamPlayerClaims } from '../../../sdk/model';
import { useLoadLeagueData, useLoadPlayerClaimData } from '../../../app/dataLoaderHooks';
import {Box} from '@mui/material';
import TeamPlayerClaimsGroupView from './TeamPlayerClaimsGroupView';

function TeamPlayerClaimsView() {
  const {leagueId, teamId} = useParams();
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isPlayerClaimLoadComplete, setPlayerClaimLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player?.id, player]));
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: [], loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const playerClaimGroups : Array<TeamPlayerClaims> = useLoadPlayerClaimData({teamId: teamId!, setIsException: setIsException, isLoadComplete: isPlayerClaimLoadComplete, setLoadComplete: setPlayerClaimLoadComplete});

  if (!isLoadComplete) {
    return <p>Loading...</p>
  }
  const waiverPriority = [...Array(league?.waiverPriorityOrder!.length).keys()].find(waiverIndex => league?.waiverPriorityOrder![waiverIndex] === teamId)! + 1;
  
  if (playerClaimGroups.reduce((partialSum, playerClaims) => partialSum + playerClaims.waiverClaims!.length + playerClaims.faabClaims!.length, 0) === 0) {
    return <p>No Active Player Claims</p>
  }
  return <Box>
    <p>Waiver Priority: {waiverPriority}</p>
    {playerClaimGroups.map(playerClaims => <TeamPlayerClaimsGroupView key={playerClaims.id} leagueId={leagueId!} teamId={teamId!} playerClaims={playerClaims} playerMap={playerMap} />)}
    
  </Box>
}

export default TeamPlayerClaimsView;
