import * as React from 'react';
import { Box, Typography, IconButton, createTheme, ThemeProvider, Card, CardActionArea, CardHeader, CardContent, Badge, Avatar, Collapse } from '@mui/material';
import Grid  from '@mui/material/Unstable_Grid2';
import { Team } from '../../../../sdk/model';
import { toSalary } from '../../../util/SalaryDisplay';
import KeyboardArrowDownIcon from  
    "@mui/icons-material/KeyboardArrowDown"; 
import KeyboardArrowUpIcon from  
    "@mui/icons-material/KeyboardArrowUp"; 

interface teamAuctionInfoProps {
  team: Team,
  remainingRosterSpots: number | undefined,
  maxBid: number | undefined,
  currentSalary: number,
  contractLengths: object,
  salaryCap: number,
  myTurn: boolean,
  expanded: boolean
}

function TeamAuctionInfoModal(props: teamAuctionInfoProps) {
  const [open, setOpen] = React.useState(props.expanded);
  
  React.useEffect(() => {
    setOpen(props.expanded)
  }, [props.expanded])
  
  const leagueCardTheme = createTheme({
    palette: {
      background: {
        paper: props.remainingRosterSpots === 0 ? '#979797' : (props.myTurn ? '#f44336' : '#1565c0')
      }
    },
  });
  return <ThemeProvider theme={leagueCardTheme}>
    <Grid>
      <Card variant="outlined" sx={{mb: 1}}>
        <CardActionArea>
        <CardHeader sx={{paddingBottom: 0}}
            title={<Box>
              <Avatar sx={{display: 'inline-block'}} alt={props.team?.name} src={props.team?.picture}>{!props.team?.picture && props.team?.name?.substring(0,1)}</Avatar>{props.team.name}
            </Box>}
            action={
              <IconButton onClick={() => setOpen(!open)} aria-label="expand" size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }/>
          <Typography
              variant="body2" sx={{paddingLeft: '16px'}}
              color="text.primary" gutterBottom>
            Cap space: {toSalary(props.salaryCap - props.currentSalary)}
          </Typography>
          <Collapse in={open}>
            <CardContent sx={{paddingTop: 0}}>
              <Typography
                  variant="body2"
                  color="text.primary" gutterBottom>
                Players left to sign: {props.remainingRosterSpots}
              </Typography>
              <Typography
                  variant="body2"
                  color="text.primary" gutterBottom>
                Max bid: {toSalary(props.maxBid)}
              </Typography>
              <br />
              <Badge badgeContent={props.contractLengths["2"] || '0'} color="secondary" title="Remaining 2-year contracts">
                <Avatar sx={props.contractLengths["2"] ? { bgcolor: '#002884' } : {}}>2</Avatar>
              </Badge>
              <Badge badgeContent={props.contractLengths["3"] || '0'} color="secondary" title="Remaining 3-year contracts">
                <Avatar sx={props.contractLengths["3"] ? { bgcolor: '#002884' } : {}}>3</Avatar>
              </Badge>
              <Badge badgeContent={props.contractLengths["4"] || '0'} color="secondary" title="Remaining 4-year contracts">
                <Avatar sx={props.contractLengths["4"] ? { bgcolor: '#002884' } : {}}>4</Avatar>
              </Badge>
            </CardContent>
          </Collapse>
        </CardActionArea>
      </Card>
    </Grid>
  </ThemeProvider>
}

export default TeamAuctionInfoModal;
