import * as React from 'react';
import { useAppSelector } from '../../app/hooks';
import { useLoadLeagueData } from '../../app/dataLoaderHooks';
import { DraftPick, League } from '../../sdk/model';
import {Card, CardHeader, Avatar, CardActions, styled, Collapse, CardContent, Box, Typography,
    TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { red } from '@mui/material/colors';
import {getNumberOrdinal} from '../util/DraftPickDisplay'
import ContractDetails from '../body/contract/ContractDetails';
import EventActivityDisplay from '../body/leagueEvent/EventActivityDisplay';
import { draftApi } from '../../adapters/APIExporter';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface draftPickCardProps {
  pick: DraftPick,
  leagueId: string | undefined
}

export default function DraftPickCard(props: draftPickCardProps) {
  const user = useAppSelector((state) => state.user);
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [history, setHistory] = React.useState(null);
  
  // League Information
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicAuctionData: false, 
    loadFullAuctionData: false, loadContractData: false, loadRosterData: false,
    setLoadComplete: setLoadComplete, isLoadComplete: isLoadComplete,
    setIsException: setIsException, isException: isException});
    
  const teamMap = new Map(league?.teams.map(team => [team.id, team]));
  
  const subtitle = <div><b>Pick Number:</b> {props.pick.pickNumber ? props.pick.pickNumber : 'Undetermined'} <br/> <b>Owner:</b> {teamMap.get(props.pick.teamId)!.name} <br/> <b>Original Team:</b> {teamMap.get(props.pick.originalTeamId)!.name}</div>

  function loadPickHistory() {
    draftApi.getDraftPickHistory(props.pick.id).then(response => setHistory(response.data))
  }

  return <Card style={{maxHeight: 800, overflow: 'auto'}}>
    <CardHeader
      avatar={
        <Avatar sx={{ bgcolor: red[500] }}>
          {getNumberOrdinal(props.pick.roundNumber)}
        </Avatar>
      }
      title={props.pick.seasonYear + " " + getNumberOrdinal(props.pick.roundNumber)}
      subheader={subtitle}
    />
    <CardActions disableSpacing>
      <ExpandMore
        expand={expanded}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore>
    </CardActions>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        {props.pick.contract ? <Box>
            <Typography  variant='h5'>Contract Details:</Typography>
            <ContractDetails leagueId={props.leagueId!} player={props.pick} contract={props.pick.contract} />
          </Box> : <p>Contract Details Undetermined</p>}
        {history ? (history.length ? 
          <Box>
            <Typography variant='h5'>Draft Pick Transaction History:</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{fontSize : 20}}>
                  <TableRow>
                    <TableCell>Date/Time</TableCell>
                    <TableCell>Transaction</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((eventActivity, eventIndex) => 
                    <EventActivityDisplay key={eventIndex} activity={eventActivity} teamMap={teamMap} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box> : <p>No Transaction History for Pick</p>) :
          <Button onClick={loadPickHistory}>Show Transaction History</Button>
        }
      </CardContent>
    </Collapse>
  </Card>
}