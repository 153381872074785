import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import { useAppSelector } from '../../app/hooks'
import './index.css';
import { League, Player, ContractStatus, LeaguePhase } from '../../sdk/model';
import { getColorByPosition } from '../util/ColorByPosition';
import { dataloadToPlayerDataFlat } from '../util/PlayerDataUtil';
import { useLoadLeagueData } from '../../app/dataLoaderHooks';
import {Box, FormControl, Modal, RadioGroup, Radio, FormControlLabel, InputLabel, Checkbox, Button} from '@mui/material';
import PlayerInfoCard from '../cards/playerCard';
import { getUsersTeamFromLeague } from '../util/TeamUtils';
import BidOnPlayerButton from '../body/playerClaims/BidOnPlayerButton';

//Using https://www.material-react-table.com/docs/examples/basic

const PlayerSearch = ({}) => {
  const {leagueId} = useParams();
  const navigate = useNavigate();
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [viewingPlayer, setViewingPlayer] = React.useState(undefined);
  const [includeExpired, setIncludeExpired] = React.useState(true);
  
  const user = useAppSelector((state) => state.user);

  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                              loadFullDraftData: false, loadContractData: false,
                                                              loadBasicAuctionData: false, loadFullAuctionData: false,
                                                              loadRosterData: true, loadWaivedContracts: true,
                                                              isException: isException, setIsException: setIsException,
                                                              isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'quick_stats.rankOnYear',
        id: 'rank',
        header: 'Rank',
        minSize: 15,
        size: 15,
      },
      {
        accessorKey: 'first_name', //access nested data with dot notation
        header: 'First Name',
        size: 75,
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 75,
      },
      {
        accessorKey: 'position', //normal accessorKey
        id: 'position',
        header: 'Position',
        minSize: 15,
        size: 15,
      },
      {
        accessorKey: 'nfl_team.alias',
        header: 'Team',
        minSize: 15,
        size: 15,
      },
      { 
        accessorKey: 'fantasyTeam',
        header: 'Fantasy Team',
        minSize: 15,
        size: 15
      },
      {
        id: 'rushing',
        header: 'Rushing',
        columns: [
          {
            accessorKey: 'quick_stats.currentWeeksStats.statistics.rushing.yards',
            header: 'Yards',
            minSize: 15,
            size: 15,
          },
          {
            accessorKey: 'quick_stats.currentWeeksStats.statistics.rushing.touchdowns',
            header: 'TDs',
            minSize: 15,
            size: 15,
          },
        ]
      },
      {
        id: 'receiving',
        header: 'Receiving',
        columns: [
          {
            accessorKey: 'quick_stats.currentWeeksStats.statistics.receiving.yards',
            header: 'Yards',
            minSize: 15,
            size: 15,
          },
          {
            accessorKey: 'quick_stats.currentWeeksStats.statistics.receiving.touchdowns',
            header: 'TDs',
            minSize: 15,
            size: 15,
          },
        ]
      },
      {
        id: 'passing',
        header: 'Passing',
        columns: [
          {
            accessorKey: 'quick_stats.currentWeeksStats.statistics.passing.yards',
            header: 'Yards',
            minSize: 15,
            size: 15,
          },
          {
            accessorKey: 'quick_stats.currentWeeksStats.statistics.passing.touchdowns',
            header: 'TDs',
            minSize: 15,
            size: 15,
          },
        ]
      }
    ],
    [],
  );

  // TODO: Make this happen at the top level so we are not reloading the data every time
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  const myTeam = getUsersTeamFromLeague(league, user?.id);
  
  console.log("Player Data Load: ");
  console.log(playerDataLoad);
  
  // IMPORTANT: This is where we are loading the data into the table
  const [data, setData] = React.useState<Player[]>([])
  const [availability, setAvailability] = React.useState("available");
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));

  React.useEffect(() => {
    setData(dataloadToPlayerDataFlat(playerDataLoad));
  }, [playerDataLoad]);
  
  if (!isLoadComplete || !league?.waivedContractsLoaded) {
    return <p>Loading...</p>
  }
  
  const playerIdToTeamMap = new Map(league?.teams.flatMap(team =>  {
    const playerRoster = team.playerRoster ? team.playerRoster : [];
    return Object.values(playerRoster).flat().filter(player => player).map(player => [player.playerId, team]);
  }));

  console.log("setPlayerSelection is not null, rendering row selection")
  // Mark Player as unranked if we did not set one?
  let filteredData = data.map((player) => {
    if (player?.quick_stats?.rankOnYear === -1) {
      return {
        ...player,
        quick_stats: {
          ...player.quick_stats,
          rankOnYear: 999,
        },
        fantasyTeam: playerIdToTeamMap.get(player.id)?.name,
      };
    }
    return {
      ...player,
      fantasyTeam: playerIdToTeamMap.get(player.id)?.name,
    };
  });

  const unavailablePlayers = new Set(league?.teams.flatMap(team => {
    const playerRoster = team.playerRoster ? team.playerRoster : [];
    return Object.values(playerRoster).flat().filter(player => player && (!includeExpired || !player.contractExpired)).map(player => player.playerId);
  }));
  const waivedPlayerIds = new Set(league?.waivedContracts!.map(contract => contract.playerId));
  if (availability === 'available') {
    filteredData = filteredData.filter((player) => !unavailablePlayers.has(player?.id));
  } else if (availability === 'waived') {
    filteredData = filteredData.filter((player) => waivedPlayerIds.has(player?.id));
  }
  return <Box>
    <Modal open={viewingPlayer !== undefined} onClose={() => setViewingPlayer(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <PlayerInfoCard player={viewingPlayer} leagueId={leagueId}/>
    </Modal>
    <FormControl>
      <RadioGroup
        name="radio-buttons-group"
        value={availability}
        onChange={(event) => setAvailability(event.target.value)}
        sx={{display: 'inline-block'}}>
        <FormControlLabel value="available" control={<Radio />} label="Available" />
        <FormControlLabel value="waived" control={<Radio />} label="Waived" />
        <FormControlLabel value="all" control={<Radio />} label="All" />
      </RadioGroup>
    </FormControl>
    {availability === 'available' && league.phase === LeaguePhase.Offseason &&
      <React.Fragment>
        <InputLabel id="expiredLabel">Include players on Expired Contracts?</InputLabel>
        <Checkbox checked={includeExpired} labelId="expiredLabel" onChange={() => setIncludeExpired(!includeExpired)}/>
      </React.Fragment>
    }
    <MaterialReactTable enableMultiRowSelection={false}
      getRowId={(row) => row.id} columns={columns} 
      data={filteredData} enableRowActions
      initialState={{
        sorting: [
          {
            id: 'rank',
            desc: false,
          },
        ],
        
      }}
      
      renderRowActions={({ row }) => {
        const owningTeam = playerIdToTeamMap.get(row.id);
        return owningTeam ? 
          (owningTeam.id === myTeam?.id ? null :
            <Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + leagueId + '/trade?teamId=' + owningTeam.id + '&playerId=' + row.id)}>Propose Trade</Button>
          ) :
          (waivedPlayerIds.has(row.id) ? 
            <Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + leagueId + '/team/' + myTeam?.id + '/waivers/' + row.id)}>Claim</Button> :
            <BidOnPlayerButton leagueId={leagueId!} player={row.original} teamId={myTeam?.id!} />
          )
      }}
      
      //clicking anywhere on the row will select it
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => setViewingPlayer(playerMap.get(row.id)),
        sx: getColorByPosition(row.getValue('position'))
      })}

      muiTableBodyCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            color: 'black'
          },
      }}
      muiTableHeadCellProps={{
        sx: {
          "& .Mui-TableHeadCell-Content-Labels": {
            padding: "0px",
          },
          "& .MuiBox-root": {
            padding: "0px",
          },
        },
      }}

      />
    </Box>
};

export default PlayerSearch;
