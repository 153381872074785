import * as React from 'react';
import { ContractTag, Team, Player } from '../../../sdk/model';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PlayerCardLink from '../../cards/playerCardLink';
import { toSalary } from '../../util/SalaryDisplay';

interface contractTagDisplayProps {
  contractTag: ContractTag,
  team: Team,
  playerMap: Map<string | undefined, Player | undefined>
}

function ContractTagDisplay(props: contractTagDisplayProps) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return <Box>
    <Typography>{props.team.name} finalized the following player tags:</Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{fontSize : 20}}>
          <TableRow>
            <StyledTableCell>Tag Type</StyledTableCell>
            <StyledTableCell>Player</StyledTableCell>
            <StyledTableCell>Price</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.contractTag.contractTags.map(tag => {
            const player = props.playerMap.get(tag.playerId)!;
            return <TableRow key={tag.playerId}>
              <StyledTableCell>{tag.tagType}</StyledTableCell>
              <StyledTableCell>
                <PlayerCardLink player={player} leagueId={props.contractTag.leagueId}>
                  {player.abbr_name}
                </PlayerCardLink>
              </StyledTableCell>
              <StyledTableCell>{toSalary(tag.price)}</StyledTableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

export default ContractTagDisplay;
