import Close from "@mui/icons-material/Close";
import FirstPage from "@mui/icons-material/FirstPage";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LeagueEventApi } from "../../../../sdk/api/league-event-api";

// Valid states for this button to be shown
const validStates = ['STARTED','RESUMED','PAUSED','COMPLETED']

export interface ResetDraftButtonProps {
  leagueEventApi: LeagueEventApi;
  draftStatus: string;
  draftId: string;
}

function ResetDraftButton(props: ResetDraftButtonProps) {

  const [resetting, setResetting] = React.useState(false);

  if(!showButton(props.draftStatus)){
    return null;
  }
  
  const resetDraft = function() {
    props.leagueEventApi.resetEvent(props.draftId).then((response) => {
      setResetting(false);
    });
  }
  
  return (
    <Box >
      <Dialog open={resetting} maxWidth="sm" fullWidth>
        <DialogTitle>Reset Draft?</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setResetting(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure you want to reset back to the beginning, undoing all picks made so far?  This action is irreversable!</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setResetting(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={resetDraft}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
            color="inherit"
            sx={{ width: 20}}
            aria-label="reset draft"
            onClick={() => setResetting(true)}>
          <FirstPage />
        </IconButton>
    </Box>
  );
}


function showButton(draftStatus: string){
  return validStates.includes(draftStatus);
}

export default ResetDraftButton;