import * as React from 'react';
import { LeagueGame, Team } from '../../../sdk/model';
import { useNavigate } from 'react-router-dom';
import {Box, Select, MenuItem, Typography, TableContainer, Table, Paper, TableBody, TableRow, TableCell, Button} from '@mui/material';

interface allTeamsScheduleProps {
  leagueId: string,
  gamesByWeek: LeagueGame,
  displayTeam: Function,
  teams: Team[],
  editMode: boolean,
  editSchedule: Function | null,
}

function AllTeamsSchedule(props: allTeamsScheduleProps) {
  const navigate = useNavigate();
  function getTeamCell(teamId, gameId, homeAway) {
    return props.editMode ? <Select value={teamId} onChange={(event) => props.editSchedule!(gameId, homeAway, event.target.value)}>
        {props.teams.map(team => <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>)}
      </Select> :
      props.displayTeam(teamId);
  }
  return <Box>
    {Object.keys(props.gamesByWeek).sort((week1, week2) => parseInt(week1) < parseInt(week2) ? -1 : 1).map(weekNumber => {
      const weeklyGames : LeagueGame[] = props.gamesByWeek[weekNumber];
      const postseasonRoundNumber = weeklyGames[0].postseasonRoundNumber;
      return <Box key={weekNumber}>
        <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          {postseasonRoundNumber ? "Playoffs Round " + postseasonRoundNumber + ", " : ""} Week {weekNumber}
        </Typography>
        <TableContainer className='centered' sx={{ width: '50%' }} component={Paper}>
          <Table>
            <TableBody>
              {weeklyGames.map(game => <TableRow key={game.id}>
                <TableCell>{getTeamCell(game.awayTeamId, game.id, "away")}</TableCell>
                <TableCell>{game.awayTeamScore}</TableCell>
                <TableCell><Button onClick={() => navigate(`/league/${props.leagueId}/matchup/id/${game.id}`)}>vs</Button></TableCell>
                <TableCell>{getTeamCell(game.homeTeamId, game.id, "home")}</TableCell>
                <TableCell>{game.homeTeamScore}</TableCell>
                <TableCell>{game.finalScore && "F"}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    })}
  </Box>
}

export default AllTeamsSchedule;