// TODO: Make the inital state admin

import { createSlice } from '@reduxjs/toolkit'
import { GlobalConfig, League } from '../sdk/model'

const initialState : GlobalConfig = {}

const globalConfigDataLoadSlice = createSlice({
  name: 'globalConfigDataLoad',
  initialState,
  reducers: {
    setGlobalConfigDataLoad (state, action) {
      console.log("Action Type: " + action.type)
      console.log(action)
      return state = action.payload
    }
  }
})

export const { setGlobalConfigDataLoad } = globalConfigDataLoadSlice.actions
export default globalConfigDataLoadSlice.reducer