import PlayIcon from '@mui/icons-material/PlayArrow';
import { IconButton, Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from "@mui/material";
import Close from "@mui/icons-material/Close";
import React from "react";
import { LeagueEventApi } from "../../../../sdk/api/league-event-api";

// Valid states for this button to be shown
const validStates = ['PENDING','PAUSED']

export interface ResumeDraftButtonProps {
  leagueEventApi: LeagueEventApi;
  draftStatus: string;
  draftId: string;
}

function ResumeDraftButton(props: ResumeDraftButtonProps) {

  const [starting, setStarting] = React.useState(false);
  
  if(!showButton(props.draftStatus)){
    return null;
  }

  const resumeDraft = function() {
    props.leagueEventApi.resumeEvent(props.draftId);
  }
  
  return (
    <Box>
      <Dialog open={starting} maxWidth="sm" fullWidth>
        <DialogTitle>Begin?</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setStarting(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure you want to begin before the scheduled start time? Please make sure everyone is ready to begin!</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setStarting(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={resumeDraft}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
          sx={{ width: 20}}
          color="inherit"
          aria-label="resume draft"
          onClick={() => {
            if (props.draftStatus === 'PENDING') {
              setStarting(true);
            } else {
              resumeDraft();
            }
          }}>
        <PlayIcon />
      </IconButton>
    </Box>
  );
}

function showButton(draftStatus: string){
  return validStates.includes(draftStatus);
}

export default ResumeDraftButton;