import * as React from 'react';
import { Team } from '../../../sdk/model';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface tradeOptionsMenuProps {
  handleTradeProposalChange: Function,
  asset: object,
  teamId: string,
  myTeam: boolean,
  otherTeamIds: Array<string | undefined>,
  teamMap: Map<string | undefined, Team | undefined>,
  value: string,
  droppable: boolean,
  disabled: boolean
}

function TradeOptionsMenu(props: tradeOptionsMenuProps) {
  const [value, setValue] = React.useState(props.value);
  const [otherTeamIds, setOtherTeamIds] = React.useState(props.otherTeamIds);
  React.useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);
  React.useEffect(() => {
    if (props.otherTeamIds !== otherTeamIds) {
      setOtherTeamIds(props.otherTeamIds);
    }
  }, [props.otherTeamIds]);
  function handleChange(assetId, fromTeamId, value) {
    setValue(value);
    props.handleTradeProposalChange(assetId, fromTeamId, value);
  }
  return <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Action</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="playerAction"
      disabled={props.disabled}
      onChange={(e) => handleChange(props.asset.id, props.teamId, e.target.value)}
    >
      <MenuItem value="default"></MenuItem>
      {props.myTeam && props.droppable && <MenuItem value="drop">Drop Player</MenuItem>}
      {otherTeamIds.filter(otherTeamId => otherTeamId).map(otherTeamId => {
        const otherTeam = props.teamMap.get(otherTeamId);
        return <MenuItem key={otherTeamId} value={otherTeamId}>
          Trade to {otherTeam?.name}
        </MenuItem>
      })}
    </Select>
  </FormControl>
}

export default TradeOptionsMenu;