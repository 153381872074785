import { Properties } from "../Properties"
import { UserApi } from "../sdk/api/user-api";
import { PlayerApi } from "../sdk/api/player-api";
import { ConfigApi } from "../sdk/api/config-api";
import { LeagueApi } from "../sdk/api/league-api";
import { TeamApi } from "../sdk/api/team-api";
import { ServiceApi } from "../sdk/api/service-api";
import { Configuration } from "../sdk/configuration";
import { LeagueEventApi } from "../sdk/api/league-event-api";
import { LeagueSeasonApi } from "../sdk/api/league-season-api";
import { LeagueEventQueueApi } from "../sdk/api/league-event-queue-api";
import { AuctionApi } from "../sdk/api/auction-api";
import { TradeApi } from "../sdk/api/trade-api";
import { ContractApi } from "../sdk/api/contract-api";
import { DraftApi } from "../sdk/api/draft-api";
import { PlayerClaimApi } from "../sdk/api/player-claim-api";
import { NflGameApi } from "../sdk/api/nfl-game-api";
import { PlayerGameApi } from "../sdk/api/player-game-api";
import { LeagueGameApi } from "../sdk/api/league-game-api";

const globalConfig: Configuration = new Configuration({basePath: Properties.backendUrl});
const authenticateUrl = Properties.backendUrl; 

const redirectWrapper = (apiClass) => {
  const prototype = Object.getPrototypeOf(apiClass);
  Object.getOwnPropertyNames(prototype).forEach((key) => {
    if (typeof apiClass[key] === 'function') {
      const originalFunction = apiClass[key];
      apiClass[key] = function() {
        return originalFunction.apply(this, arguments).catch((err) => {
	    	if (err.response && err.response.status === 499) {
	    	    document.cookie = "nav=" + window.location.pathname;
	            window.location.assign(authenticateUrl);
	        }
	        throw err;
		});
      }
    }
  });
  return apiClass;
}

export const userApi = redirectWrapper(new UserApi(globalConfig));
export const playerApi = new PlayerApi(globalConfig);
export const playerGameApi = new PlayerGameApi(globalConfig);
export const leagueGameApi = new LeagueGameApi(globalConfig);
export const configApi = new ConfigApi(globalConfig);
export const leagueApi = redirectWrapper(new LeagueApi(globalConfig));
export const leagueSeasonApi = redirectWrapper(new LeagueSeasonApi(globalConfig));
export const serviceApi = redirectWrapper(new ServiceApi(globalConfig));
export const teamApi = redirectWrapper(new TeamApi(globalConfig));
export const leagueEventApi = redirectWrapper(new LeagueEventApi(globalConfig));
export const leagueEventQueueApi = redirectWrapper(new LeagueEventQueueApi(globalConfig));
export const nflGameApi = redirectWrapper(new NflGameApi(globalConfig));
export const auctionApi = redirectWrapper(new AuctionApi(globalConfig));
export const tradeApi = redirectWrapper(new TradeApi(globalConfig));
export const contractApi = redirectWrapper(new ContractApi(globalConfig));
export const draftApi = redirectWrapper(new DraftApi(globalConfig));
export  const playerClaimApi = redirectWrapper(new PlayerClaimApi(globalConfig));
