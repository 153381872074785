import * as React from 'react'
import { League, Player, PlayerPositionEnum, RosterPositionEnum, Team, TeamPlayerInfo } from '../../sdk/model';
import { List } from 'reselect/es/types';

interface validRosterSpotsProps {
  roster: Map<String, List<TeamPlayerInfo>> | undefined,
  currentRosterPosition: RosterPositionEnum
  player: Player | undefined,
  playerMap: Map<string | undefined, Player | undefined>,
  rosterSettings: Map<string | undefined, number | undefined>
}

const ROSTER_SPOTS_FOR_POSITION : Map<PlayerPositionEnum, RosterPositionEnum[]> = new Map([
  [PlayerPositionEnum.Qb, [RosterPositionEnum.Qb, RosterPositionEnum.Sflex, RosterPositionEnum.Bn]],
  [PlayerPositionEnum.Rb, [RosterPositionEnum.Rb, RosterPositionEnum.Sflex, RosterPositionEnum.Flex, RosterPositionEnum.Bn]],
  [PlayerPositionEnum.Wr, [RosterPositionEnum.Wr, RosterPositionEnum.Sflex, RosterPositionEnum.Flex, RosterPositionEnum.Bn]],
  [PlayerPositionEnum.Te, [RosterPositionEnum.Te, RosterPositionEnum.Sflex, RosterPositionEnum.Flex, RosterPositionEnum.Bn]],
]);

export const positionOrder: Array<RosterPositionEnum> = [RosterPositionEnum.Qb,
                                                  RosterPositionEnum.Rb,
                                                  RosterPositionEnum.Wr,
                                                  RosterPositionEnum.Te,
                                                  RosterPositionEnum.Sflex,
                                                  RosterPositionEnum.Flex,
                                                  RosterPositionEnum.Def,
                                                  RosterPositionEnum.K,
                                                  RosterPositionEnum.P,
                                                  RosterPositionEnum.Dl,
                                                  RosterPositionEnum.Lb,
                                                  RosterPositionEnum.Db,
                                                  RosterPositionEnum.Dflex,
                                                  RosterPositionEnum.Bn
                                                  ];

export function validRosterSpots(props: validRosterSpotsProps) {
  if (props.player) {
    var validPositions: Array<RosterPositionEnum> = ROSTER_SPOTS_FOR_POSITION.get(props.player?.position!)!;
    
    return Object.keys(props.rosterSettings)
      .sort((pos1, pos2) => positionOrder.indexOf(pos1) - positionOrder.indexOf(pos2))
      .filter(position => validPositions.includes(position))
      .filter(position => props.rosterSettings[position])
      .flatMap(position => {
        if (props.roster![position].length) {
          return props.roster![position]
            .filter(rosterSpot => rosterSpot.playerId !== props.player?.id)
            .filter(rosterSpot => {
              if (position === RosterPositionEnum.Bn) {
                if (props.currentRosterPosition === RosterPositionEnum.Bn) {
                  return false;
                }
                const player = props.playerMap.get(rosterSpot.playerId)!;
                return ROSTER_SPOTS_FOR_POSITION.get(player.position!)!.includes(props.currentRosterPosition);
              }
              return true;
            }).map((rosterSpot, rosterIndex) => {
              let playerId = rosterSpot.playerId;
              if (position !== RosterPositionEnum.Bn) {
                const player = props.playerMap.get(rosterSpot.playerId)!;
                if (!ROSTER_SPOTS_FOR_POSITION.get(player.position!)!.includes(props.currentRosterPosition)) {
                  playerId = null;
                }
              }
              return {
                position: position,
                index: rosterIndex,
                playerId: playerId
              }
            })
        }
        return [{
          position: position,
          index: 0,
          playerId: null
        }]
      });
  }
  return Object.keys(props.roster!)
    .flatMap(position => props.roster![position]!
      .filter(rosterSpot => {
        const player = props.playerMap.get(rosterSpot.playerId)!;
        return ROSTER_SPOTS_FOR_POSITION.get(player.position!)!.includes(props.currentRosterPosition);
      }).map((rosterSpot, rosterIndex) => {
        return {
          position: position,
          index: rosterIndex,
          playerId: rosterSpot.playerId
        }
      })
    );
}

export function stringToRosterPositionEnum(value: string): RosterPositionEnum | undefined {
  let keys = Object.keys(RosterPositionEnum).filter(x => RosterPositionEnum[x] == value);
  return keys.length > 0 ? RosterPositionEnum[keys[0]] : undefined;
}

export function getRosterSize(team: Team | undefined) {
  return Object.values(team?.playerRoster!).flat().filter(player => player && !player.contractExpired).length;
}

export default validRosterSpots;
  
export function getTeamFromLeague(league: League, teamId: string | undefined): Team | undefined {
  if(!teamId) {
    return undefined;
  }
  return league?.teams.find((team: any) => team.id === teamId);
}

export function getUsersTeamFromLeague(league: League | undefined, userId: string | undefined): Team | undefined {
  return league?.teams.find((team: any) => team.owners.some((owner: any) => owner.id === userId));
}

export function isOwnerOfTeam(team: Team | undefined, userId: string | undefined): boolean {
  return team?.owners?.some((owner: any) => owner.id === userId) ?? false;
}
  
export function getRemainingRosterSpots(team: Team, league: League) : number {
  const teamSize = team.playerRoster === undefined ? 0 : Object.values(team.playerRoster).flat().filter(player => player && !player.contractExpired).length;
  const maxTeamSize = Object.values(league!.rosterSettings).reduce((partialSum, rosterSpots) => partialSum + rosterSpots, 0);
  return maxTeamSize - teamSize;
}
