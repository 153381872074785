import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { leagueEventApi } from '../../../adapters/APIExporter';
import {Box, Button} from '@mui/material';
import { Team, Trade } from '../../../sdk/model';

import TradeAtAGlance from './TradeAtAGlance';

function CompletedTradeViewer({league}) {
  const navigate = useNavigate();
  
  const {tradeId} = useParams();
  const [trade, setTrade] = React.useState<Trade | undefined>(undefined);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  React.useEffect(() => {
    leagueEventApi.getLeagueEvent(tradeId).then(response => setTrade(response.data))
  }, []);
  
  if (!trade) {
    return <p>Loading...</p>
  }
  
  const tradeProposal = trade.tradeProposal;
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  
  return <Box>
    {trade.draftYear === undefined || <p>
      Trade made during the <Button onClick={() => navigate('/league/' + trade.leagueId + '/draftHistory?seasonYear=' + trade.draftYear)}>{trade.draftYear} Rookie Draft</Button>
    </p>}
    <TradeAtAGlance leagueId={trade.leagueId} tradeStatus={tradeProposal.status} teams={tradeProposal.teams} picks={tradeProposal.picks} players={tradeProposal.players}
        playerMap={playerMap} teamMap={teamMap} pickMap={undefined} />
  </Box>
}

export default CompletedTradeViewer;