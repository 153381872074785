import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData, useAddUpdatePlayerClaim } from '../../../app/dataLoaderHooks';
import { League, Team, TradeProposalPlayerActionEnum, TradeProposalPlayer, WaiverClaim, TeamPlayerClaims } from '../../../sdk/model';
import {Box, Typography, Button} from '@mui/material';
import TradeTeamViewer from '../trade/TradeTeamViewer';
import TradeDetails from '../trade/TradeDetails';
import { getNewSalary, getSalaryForSeason } from '../../util/SalaryDisplay';
import PlayerCardLink from '../../cards/playerCardLink';
import { playerClaimApi } from '../../../adapters/APIExporter';
import { getRosterSize } from '../../util/TeamUtils';

function DropForWaiverClaim() {
  const navigate = useNavigate();
  const {leagueId, teamId, playerId} = useParams();
  
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [dropPlayerIds, setDropPlayerIds] = React.useState<string[]>([]);
  const [navigateAway, setNavigateAway] = React.useState(false);
  const [newTeamPlayerClaims, setNewTeamPlayerClaims] = React.useState<TeamPlayerClaims | undefined>(undefined);
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: [teamId!], loadWaivedContracts: true,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));                                           
  const myTeam = teamMap.get(teamId);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player?.id, player]));
  
  useAddUpdatePlayerClaim(teamId!, newTeamPlayerClaims?.id, newTeamPlayerClaims);
  if (newTeamPlayerClaims) {
    setNewTeamPlayerClaims(undefined);
  }
  
  React.useEffect(() => {
    if (navigateAway) {
      navigate('/league/' + leagueId);
    }
  }, [navigateAway]);
  
  function handleDropChange(playerId: string, fromTeamId: string, action: string) {
    const newDropPlayerIds = [...dropPlayerIds];
    if (action === 'drop') {
      newDropPlayerIds.push(playerId);
    } else {
      const playerIndex = dropPlayerIds.indexOf(playerId);
      newDropPlayerIds.splice(playerIndex, 1);
    }
    setDropPlayerIds(newDropPlayerIds);
  }
  
  function makeWaiverClaim() {
    const claim : WaiverClaim = {
      type: 'waiver_claim',
      playerId: playerId,
      dropPlayerIds: dropPlayerIds
    }
    playerClaimApi.claimWaivedContract(claim, teamId).then(response => {
      setNewTeamPlayerClaims(response.data);
      setNavigateAway(true);
    });
  }
  
  if (!isLoadComplete || !myTeam?.rosterLoaded || !leagueId || !teamId || !playerId) {
    return <p>Loading...</p>
  }
  
  const players : TradeProposalPlayer[] = dropPlayerIds.map(dropPlayerId => {
    return {
      playerId: dropPlayerId,
      fromTeamId: teamId,
      action: TradeProposalPlayerActionEnum.Drop
    }
  })
  
  players.push({
    playerId: playerId,
    toTeamId: teamId,
    action: TradeProposalPlayerActionEnum.Trade
  });
  
  const proposalPlayerMap = new Map(players.map(player => [player.playerId, player]));
  const teamPlayerMap = new Map(Object.values(myTeam.playerRoster!).flatMap(playerList => 
    playerList.filter(player => player).map(player => [player.playerId, player])
  ));
  teamPlayerMap.set(playerId, {
    playerId: playerId,
    contract: league!.waivedContracts!.find(contract => contract.playerId === playerId)
  });
  
  const rosterSize = getRosterSize(myTeam);
  const newRosterSize = players.reduce((partialSum, tradePlayer) => partialSum + (tradePlayer.toTeamId === myTeam?.id ? 1 : (tradePlayer.fromTeamId === myTeam?.id ? -1 : 0)), rosterSize);
  const maxRosterSize = Object.values(league?.rosterSettings!).reduce((partialSum, numberOfSpots) => partialSum + numberOfSpots, 0);
  const newEmptyRosterSpots = maxRosterSize - newRosterSize;
  const overRosterLimit = newEmptyRosterSpots < 0;
  const currentSalary = getSalaryForSeason(myTeam!.paymentsBySeason![league?.currentSeasonYear!]);
  const newSalary = getNewSalary(myTeam!, teamPlayerMap, new Map(), currentSalary, players, [], league?.currentSeasonYear!, 0, newEmptyRosterSpots, league?.currentSeason)
  const overCap = newSalary > league?.currentSeason?.nflSeason?.salaryCap!;
  const player = playerMap.get(playerId)!;
  
  return <Box>
    <p>You are about to make a waiver claim for <PlayerCardLink player={player} leagueId={leagueId!}>{player.first_name} {player.last_name}</PlayerCardLink>.  Please select below if you would like to conditionally drop player(s) as a part of this claim.</p>
    <TradeTeamViewer team={myTeam} currentSeason={league?.currentSeason?.seasonYear!}
            playerMap={playerMap} teamMap={new Map([[teamId, myTeam]])} userId={user?.id} myTeam={true} 
            otherTeamIds={[]} handleTradeProposalChange={handleDropChange}
            proposalPlayerMap={proposalPlayerMap} proposalPickMap={undefined} />
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Full Details
    </Typography>
    <TradeDetails leagueId={leagueId} teams={[myTeam]} currentSeason={league?.currentSeasonYear} players={players} picks={[]} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={new Map()} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
    <Button color="primary" variant="contained" onClick={makeWaiverClaim} disabled={overCap || overRosterLimit}>Complete Claim</Button>
  </Box>
}

export default DropForWaiverClaim;