import * as React from 'react'
import Box from '@mui/material/Box';
import { Typography, InputLabel, Select, MenuItem } from '@mui/material';
import DraggableList from '../../../util/DraggableList';

function LeagueEventOrderSelector({event, handleEventChange, editMode, firstSeason, teamSet, setEvent, roundBased}) {
  function reorderTeam(destination, source, roundIndex) {
    // dropped outside the list
    if (!destination) return;
    const newEvent = {...event};
    const [removed] = newEvent.draftOrder[roundIndex].splice(source.index, 1);
    newEvent.draftOrder[roundIndex].splice(destination.index, 0, removed);
    setEvent(newEvent);
  }
  return (<Box>
    <InputLabel id="orderBaseLabel">Draft Order</InputLabel>
      <Select name="orderBase" label="Draft Order" labelId="orderBaseLabel" value={event.orderBase}
          onChange={handleEventChange} disabled={!editMode}>
        {firstSeason || <MenuItem value='STANDINGS'>Reverse Order of Last Year's Standings</MenuItem>}
        <MenuItem value='RANDOM'>Randomized one hour before the draft</MenuItem>
        <MenuItem value='CUSTOM'>Custom (Set Below)</MenuItem>
      </Select>
      <InputLabel id="draftTypeLabel">Draft Type</InputLabel>
      <Select name="draftType" label="Draft Type" labelId="draftTypeLabel" value={event.draftType}
          onChange={handleEventChange} disabled={!editMode}>
        <MenuItem value='SNAKE'>Snake</MenuItem>
        <MenuItem value='LINEAR'>Linear</MenuItem>
        {event.orderBase === 'CUSTOM' && roundBased && <MenuItem value='MANUAL'>Manually Set Order for Each Round</MenuItem>}
      </Select>
      {event.orderBase === 'CUSTOM' && Object.entries(event.draftOrder).map(([roundIndex, order]) => {
        const draggableItems = order.map(teamId => teamSet[teamId]).map(team => {return {id : team.id, primary: team.name}});
        return <Box key={roundIndex}>
          {event.draftOrder.length > 1 && <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            Round {parseInt(roundIndex) + 1}
          </Typography>}
          <DraggableList items={draggableItems} disabled={!editMode} onDragEnd={({destination, source}) => reorderTeam(destination, source, roundIndex)} />
        </Box>
      })}
    </Box>);
}

export default LeagueEventOrderSelector;
