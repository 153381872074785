import Close from "@mui/icons-material/Close";
import FastForward from "@mui/icons-material/FastForward";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LeagueEventApi } from "../../../../sdk/api/league-event-api";

// Valid states for this button to be shown
const validStates = ['STARTED', 'RESUMED','PAUSED']

export interface FastForwardDraftButtonProps {
  leagueEventApi: LeagueEventApi;
  draftStatus: string;
  draftId: string;
}

function FastForwardDraftButton(props: FastForwardDraftButtonProps) {

  const [fastForwarding, setFastForwarding] = React.useState(false);
  
  if(!showButton(props.draftStatus)){
    return null;
  }
  
  const fastForwardPick = function() {
    props.leagueEventApi.autoPick(props.draftId).then((response) => {
    setFastForwarding(false);
    })
  }
  
  return (
    <Box >
      <Dialog open={fastForwarding} maxWidth="sm" fullWidth>
        <DialogTitle>Auotpick?</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setFastForwarding(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure you want to trigger an autopick for the current pick?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setFastForwarding(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={fastForwardPick}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
          color="inherit"
          sx={{ width: 20}}
          aria-label="autopick current pick"
          onClick={() => setFastForwarding(true)}>
        <FastForward />
      </IconButton>
    </Box>
  );
}

function showButton(draftStatus: string){
  return validStates.includes(draftStatus);
}

export default FastForwardDraftButton;