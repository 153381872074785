/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * A Phase for a `league`
 * @export
 * @enum {string}
 */

export const LeaguePhase = {
    Offseason: 'OFFSEASON',
    DraftPrep: 'DRAFT_PREP',
    Draft: 'DRAFT',
    TrainingCamp: 'TRAINING_CAMP',
    AuctionPrep: 'AUCTION_PREP',
    Auction: 'AUCTION',
    PreSeason: 'PRE_SEASON',
    RegularSeason: 'REGULAR_SEASON',
    Playoffs: 'PLAYOFFS',
    SeasonComplete: 'SEASON_COMPLETE'
} as const;

export type LeaguePhase = typeof LeaguePhase[keyof typeof LeaguePhase];



