import { Paper, Typography } from '@mui/material';
import * as React from 'react'
import { EventActivity, Player, Team } from '../../../../sdk/model';
import TradeAtAGlance from '../../trade/TradeAtAGlance';

interface draftHistoryProps {
  leagueId: string,
  activityHistory: Array<EventActivity>,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team>
}

function DraftHistory(props: draftHistoryProps) {
  const activityTypeToDisplayFunction = new Map([
    ['draft_pick', activity => {
      const player = props.playerMap.get(activity?.contract?.playerId);
      return <React.Fragment>
          {props.teamMap.get(activity!.teamId!)?.name} select {player?.position} {player?.abbr_name} with pick {activity?.roundNumber}.{String(activity?.pickNumber).padStart(2, '0')}
        </React.Fragment>
    }],
    ['mid_draft_trade', activity => <React.Fragment>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Trade Executed:
      </Typography>
      <TradeAtAGlance leagueId={props.leagueId} tradeStatus="COMPLETE" teams={activity.tradeProposal.teams} picks={activity.tradeProposal.picks} players={activity.tradeProposal.players} playerMap={props.playerMap} teamMap={props.teamMap} pickMap={undefined} />
    </React.Fragment>]
  ])
  return  <Paper>
  <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
    Draft History:
  </Typography>
  {props.activityHistory.map(activity => <p key={activity.id!}>
    {activityTypeToDisplayFunction.get(activity.type)!(activity)}
  </p>)}
</Paper>
}

export default DraftHistory;