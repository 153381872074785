import { Box, Button, Typography, Modal } from '@mui/material';
import * as React from 'react'
import { Draft, Player, Team } from '../../../../sdk/model';
import { getCurrentSalary, toSalary } from '../../../util/SalaryDisplay';
import DraftPickCard from '../../../cards/draftPickCard';

interface draftPickModalProps {
  leagueId: string,
  draft: Draft | undefined,
  playerMap: Map<string | undefined, Player | undefined> | undefined,
  teamMap: Map<string | undefined, Team>,
  currentRound: number,
  currentPick: number,
  currentSeasonYear: number
}

function DraftPickModal(props: draftPickModalProps) {
  const [viewingPick, setViewingPick] = React.useState(undefined);
  
  return <Box sx={{ display: 'inline-block', whiteSpace: 'nowrap', overflowX: 'auto'}}>
    <Modal open={viewingPick} onClose={() => setViewingPick(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <DraftPickCard pick={viewingPick} leagueId={props.leagueId} />
    </Modal>
    {Object.entries(props.draft?.draftPicks).map(([roundIndex, round]) => {
    const roundNumber = Number(roundIndex) + 1;
    return <Box key={roundIndex} sx={{ display: 'inline' }}>
        {round?.some(pick => !pick.contract.playerId) && <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 16 }}>
          Round {roundNumber}:
        </Typography>}
        {Object.entries(round).map(([pickIndex, pick]) => {
        if (pick?.contract?.playerId) {
        return null;
        } else if (pick?.teamId === null) {
          return <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 16 }}>Pick is null</Typography>
        }
        const pickNumber = Number(pickIndex) + 1;
          const isCurrentPick = props.currentRound === roundNumber && props.currentPick === pickNumber
          const selectedPlayer = pick?.contract?.playerId ? props?.playerMap?.get(pick.contract.playerId) : null;
          return <Box key={pickIndex} sx={{ display: 'inline-block' }}>
            <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 16 }}>
              {roundNumber}.{String(pickNumber).padStart(2, '0')}
            </Typography>
            <br/>
            <Button color={isCurrentPick ? "primary" : "secondary"} className={isCurrentPick ? "current-pick" : ""} variant="contained" onClick={() => setViewingPick(props.teamMap.get(pick.teamId)!.draftPicks!.find(draftPick => draftPick.id === pick.id))}>
              <Box>
                <b>{props.teamMap.get(pick?.teamId)?.name}</b> {pick.teamId !== pick.originalTeamId && "(via " + props.teamMap.get(pick.originalTeamId).name + ")"}
                <br/>
                {toSalary(pick?.contract?.totalSalary)}/{pick.contract.numberOfSeasons}yrs, {toSalary(pick.contract.averageAnnualSalary)} APY
                <br/>
                {getCurrentSalary(pick!, props.currentSeasonYear)} {props.currentSeasonYear}
              </Box>
            </Button>
          </Box>
        })}
      </Box>
    })}
  </Box>
}

export default DraftPickModal;