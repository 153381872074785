/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PlayerClaim } from './player-claim';

/**
 * A Result of Claims for a Player
 * @export
 * @interface PlayerClaimResult
 */
export interface PlayerClaimResult {
    /**
     * 
     * @type {string}
     * @memberof PlayerClaimResult
     */
    'teamId'?: string;
    /**
     * 
     * @type {PlayerClaim}
     * @memberof PlayerClaimResult
     */
    'bid'?: PlayerClaim;
    /**
     * 
     * @type {string}
     * @memberof PlayerClaimResult
     */
    'result'?: PlayerClaimResultResultEnum;
}

export const PlayerClaimResultResultEnum = {
    Success: 'SUCCESS',
    InvalidAlreadyClaimed: 'INVALID_ALREADY_CLAIMED',
    InvalidDropPlayer: 'INVALID_DROP_PLAYER',
    LosingPriority: 'LOSING_PRIORITY',
    LosingBid: 'LOSING_BID',
    RosterFilled: 'ROSTER_FILLED',
    SalaryCapExceeded: 'SALARY_CAP_EXCEEDED'
} as const;

export type PlayerClaimResultResultEnum = typeof PlayerClaimResultResultEnum[keyof typeof PlayerClaimResultResultEnum];


