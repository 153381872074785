import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Label } from '@mui/icons-material';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { TextField } from '@mui/material';
import { logIn, logOut } from '../../../adapters/ServiceAPI';
import { User } from '../../../sdk/model';
import { setUser } from '../../../slices/user';
import { useNavigate } from 'react-router-dom';
import Grid  from '@mui/material/Unstable_Grid2';
import { createTheme, Input, ThemeProvider } from '@mui/material';
import { userApi } from '../../../adapters/APIExporter'


function Profile() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const [editMode, setEditMode] = React.useState(!user.username);
  const [initialSetupMode] = React.useState(!user.username);

  const [usernameInput, setUserNameInput] = React.useState("");
  const [pictureInput, setPictureInput] = React.useState("");
  const [errors, setErrors] = React.useState({});



  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  let userInfo = <Box>
                  <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>{user.username}</Typography>
                  <IconButton sx={{ p: 0 }}>
                    <Avatar alt={user?.username} src={user?.picture} />
                  </IconButton>
                  <br/>
                  <Button className='capsized-button' variant="contained" onClick={() => {
                    setUserNameInput(user!.username!);
                    setPictureInput(user!.picture!);
                  	setEditMode(true);
                  }}>Edit</Button>
                </Box>;
  if(editMode) {
    userInfo = <Box>
                  <TextField name='username' label="Enter User Name" value={usernameInput}
                    onChange= {(e) => setUserNameInput(e.target.value)}/>
                  <br/>
                  <TextField name='picture' label="Enter Image URL" value={pictureInput}
                    onChange= {(e) => setPictureInput(e.target.value)}/>
                  <IconButton sx={{ p: 0 }}>
                    <Avatar alt={user?.username} src={pictureInput} />
                  </IconButton>
                  <br/>
                  <Button className='capsized-button' variant="contained" onClick={() => handelSavePressed()}>Save</Button> 
                  {initialSetupMode ? null :
	                  <Button className='capsized-button' variant="contained" onClick={() => {
	                  	setErrors({});
	                  	setEditMode(false);
	                  }}>Cancel</Button> 
	              }
                </Box>
  }
  
 let errorInfo = Object.entries(errors).map(([key, value]) => {
						if (value) {
							return (<p className='error' key={'error_' + key}>{value}</p>)
						}
						return (<p className='error' key={'error_' + key}></p>)
					});

  function handelSavePressed(){
    let errors = {};
    validateUsername(usernameInput, errors);
    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      return;
    }
    let newUser: User = {...user};
    newUser.username= usernameInput;
    newUser.picture = pictureInput;
    userApi.updateUser(String(user.id), newUser).then((response) => {
      dispatch(setUser(newUser));
      setEditMode(false);
      if (initialSetupMode) {
        window.location.assign('/');
      }
    }).catch((error) => {
      console.log(error);
      let errors = { serverError: error.response.data.message };
      setErrors(errors);
    });
    setUser(usernameInput);
  }
  return (
    // TBD Move this to the App.tsx level
    <ThemeProvider theme={darkTheme}>
      <Grid className='component'>
        <Box>
        <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
              >User Settings:</Typography>
          {userInfo}
          {errorInfo}
        </Box>
      </Grid>
    </ThemeProvider>
  );

}


const validateUsername = (value, errors) => {
  if (value.length < 3 || value.length > 25) {
    errors.username = 'You must provide a Username with a length between 3 and 25 characters';
  }
}

export default Profile;