import * as React from 'react';
import { Auction, Player, Team } from '../../../../sdk/model';
import Box from '@mui/material/Box';
import { Typography, Tab, Tabs, Paper } from '@mui/material';
import { toSalary } from '../../../util/SalaryDisplay';

interface auctionHistoryProps {
  auction: Auction,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team>
}

function AuctionHistory(props: auctionHistoryProps) {
  const [view, setView] = React.useState('CURRENT_PICK');
  const [scrollLock, setScrollLock] = React.useState(false);
  
  React.useEffect(() => {
    if (!scrollLock) {
      var objDiv = document.getElementById("historyBox")!;
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, [props.auction.bidsForCurrentPlayer, props.auction.activityHistory]);
  
  React.useEffect(() => {
    var objDiv = document.getElementById("historyBox")!;
    objDiv.scrollTop = objDiv.scrollHeight;
    setScrollLock(false);
  }, [view]);
  
  function handleScroll(e) {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    setScrollLock(offsetHeight + scrollTop < scrollHeight);
  }
  
  return <Paper>
    
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Auction History:
    </Typography>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={view} onChange={(event, value) => {
        setView(value)
        }} aria-label="Auction History Tabs">
        <Tab label="Current Pick" value="CURRENT_PICK" />
        <Tab label="Auction Results" value="AUCTION_RESULTS" />
      </Tabs>
    </Box>
    <Box sx={{ height: 300, overflow: 'auto' }} id='historyBox' onScroll={handleScroll}>
      {view === 'CURRENT_PICK' && Object.entries(props.auction.bidsForCurrentPlayer).map(([bidIndex, bid]) => 
        <p key={bidIndex}>
          {props.teamMap.get(bid?.teamId)?.name}: {bid?.years}yrs/{toSalary(bid?.totalAmount)}
        </p>
      )}
      {view === 'AUCTION_RESULTS' && Object.entries(props.auction.activityHistory).map(([activityIndex, activity]) => {
        if (activity.type === 'auction_pick') {
          const player = props.playerMap.get(activity?.contract?.playerId);
          return <p key={activityIndex}>
            Pick {activity.pickNumber}: {player?.position} <b>{player?.abbr_name}</b>, nominated by {props.teamMap.get(activity!.nominatingTeamId!)?.name}, signed by <b>{props.teamMap.get(activity!.teamId!)?.name}</b> for <b>{activity?.contract?.numberOfSeasons}yrs/{toSalary(activity?.contract?.totalSalary)}</b>
          </p>
        }
      })}
    </Box>
  </Paper>
}

export default AuctionHistory;
