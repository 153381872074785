import { Typography, Box, Button, Modal } from '@mui/material';
import * as React from 'react'
import { Draft, Team } from '../../../../sdk/model';
import { getColorByPosition } from '../../../util/ColorByPosition'
import PlayerInfoCard from '../../../cards/playerCard'

interface draftBoardProps {
  draft: Draft | undefined,
  playerMap: Map<string | undefined, Player | undefined> | undefined,
  teamMap: Map<string | undefined, Team>
}

function DraftBoard(props: draftBoardProps) {
  const [viewingPlayer, setViewingPlayer] = React.useState(undefined);
  
  return <Box sx={{ display: 'inline-block', whiteSpace: 'nowrap', overflowX: 'auto'}}>
    <Modal open={viewingPlayer} onClose={() => setViewingPlayer(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <PlayerInfoCard player={viewingPlayer} leagueId={props.draft?.leagueId}/>
    </Modal>
    {Object.entries(props.draft?.draftPicks).map(([roundIndex, round]) => {
      const roundNumber = Number(roundIndex) + 1;
      return <Box key={roundIndex} sx={{ display: 'block' }}>
        {Object.entries(round).map(([pickIndex, pick]) => {
          const pickNumber = Number(pickIndex) + 1;
          const selectedPlayer = pick?.contract?.playerId ? props?.playerMap?.get(pick.contract.playerId) : null;
          return <Box key={pickIndex} sx={{ display: 'inline-block' }}>
            <Button variant="contained" onClick={() => setViewingPlayer(selectedPlayer)} sx={{ width: 100, height: 75, overflow: 'hidden', ...getColorByPosition(selectedPlayer ? selectedPlayer.position : null)}}>
              <Box>
                <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 10, color : 'black' }}>
                  {roundNumber}.{String(pickNumber).padStart(2, '0')}
                </Typography>
                <br/>
                <b>{selectedPlayer ? selectedPlayer.abbr_name : null}</b>
                <br/>
                <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 10, color : 'black' }}>
                  {props.teamMap.get(pick?.teamId)?.name} {pick.teamId !== pick.originalTeamId && "(via " + props.teamMap.get(pick.originalTeamId)!.name + ")"}
                </Typography>
              </Box>
            </Button>
          </Box>
        })}
      </Box>
    })}
  </Box>
}

export default DraftBoard;