import PauseIcon from '@mui/icons-material/Pause';
import { IconButton } from "@mui/material";
import React from "react";
import { LeagueEventApi } from "../../../../sdk/api/league-event-api";

// Valid states for this button to be shown
const validStates = ['STARTED', 'RESUMED']

export interface PauseDraftButtonProps {
  leagueEventApi: LeagueEventApi;
  draftStatus: string;
  draftId: string;
}

function PauseDraftButton(props: PauseDraftButtonProps) {

  if(!showButton(props.draftStatus)){
    return null;
  }

  const pauseDraft = function() {
    props.leagueEventApi.pauseEvent(props.draftId);
  }
  return (
    <IconButton
      sx={{ width: 30}}
      color="inherit"
      aria-label="pause draft"
      onClick={pauseDraft}>
    <PauseIcon />
  </IconButton>
  );
}

function showButton(draftStatus: string){
  return validStates.includes(draftStatus);
}

export default PauseDraftButton;