import * as React from 'react';
import { TeamPlayerClaims, Player, GlobalConfig } from '../../../sdk/model';
import { useAddUpdatePlayerClaim } from '../../../app/dataLoaderHooks';
import {Box, Typography} from '@mui/material';
import WaiverClaimsView from './WaiverClaimsView';
import FaabClaimsView from './FaabClaimsView';
import { useAppSelector } from '../../../app/hooks';

interface teamPlayerClaimsGroupViewProps {
  leagueId: string,
  teamId: string,
  playerClaims: TeamPlayerClaims,
  playerMap: Map<string | undefined, Player | undefined>
}

function TeamPlayerClaimsGroupView(props : teamPlayerClaimsGroupViewProps) {
  const [newTeamPlayerClaims, setNewTeamPlayerClaims] = React.useState<TeamPlayerClaims | undefined>(undefined);
  const globalConfig: GlobalConfig = useAppSelector((state) => state.globalConfigDataLoad)
  
  useAddUpdatePlayerClaim(props.teamId, newTeamPlayerClaims?.id, newTeamPlayerClaims);
    if (newTeamPlayerClaims) {
    setNewTeamPlayerClaims(undefined);
  }
  
  if (!props.playerClaims.waiverClaims!.length && !props.playerClaims.faabClaims!.length) {
    return null;
  }
  
  function getClaimDate() {
    if (props.playerClaims.claimDate) {
      return new Date(props.playerClaims.claimDate);
    }
    const claimDate = new Date(globalConfig.nextWeekBeginDate!);
    claimDate.setDate(claimDate.getDate() + 1);
    return claimDate;
  }
  
  const claimDate = getClaimDate();
  return <Box sx={{border: 1}}>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Executing on {claimDate.toLocaleDateString(undefined, { weekday: 'short' })} at {claimDate.toLocaleTimeString()}
    </Typography>
    <WaiverClaimsView playerClaims={props.playerClaims} playerMap={props.playerMap} leagueId={props.leagueId} setNewTeamPlayerClaims={setNewTeamPlayerClaims} />
    <FaabClaimsView playerClaims={props.playerClaims} playerMap={props.playerMap} leagueId={props.leagueId} setNewTeamPlayerClaims={setNewTeamPlayerClaims} />
  </Box>;
}

export default TeamPlayerClaimsGroupView;