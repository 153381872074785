import { createSlice } from '@reduxjs/toolkit'
import { TradeProposal } from '../sdk/model'
import { enableMapSet } from 'immer';

enableMapSet();

const initialState : Map<string, TradeProposal[]> = new Map();

const tradeDataLoadSlice = createSlice({
  name: 'tradeDataLoad',
  initialState,
  reducers: {
    setTradeDataLoad (state, action) {
      return state = action.payload
    }
  }
})

export const { setTradeDataLoad } = tradeDataLoadSlice.actions
export default tradeDataLoadSlice.reducer