import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeagueGame } from '../../../sdk/model';
import {Box, TableContainer, Table, Paper, TableBody, TableRow, TableCell, Button} from '@mui/material';


interface teamScheduleProps {
  leagueId: string,
  gamesByWeek: LeagueGame,
  displayTeam: Function,
  teamId: string
}

function TeamSchedule(props: teamScheduleProps) {
  const navigate = useNavigate();
  return <Box><TableContainer className='centered' sx={{ width: '50%' }} component={Paper}><Table><TableBody>
    {Object.keys(props.gamesByWeek).sort((week1, week2) => parseInt(week1) < parseInt(week2) ? -1 : 1).map(weekNumber => {
      const weeklyGame : LeagueGame = props.gamesByWeek[weekNumber].find(game => game.homeTeamId === props.teamId || game.awayTeamId === props.teamId);
      return <TableRow key={weekNumber}>
        <TableCell>Week {weekNumber}</TableCell>
        <TableCell>{props.displayTeam(weeklyGame.awayTeamId)}</TableCell>
        <TableCell>{weeklyGame.awayTeamScore}</TableCell>
        <TableCell><Button onClick={() => navigate(`/league/${props.leagueId}/matchup/id/${weeklyGame.id}`)}>vs</Button></TableCell>
        <TableCell>{props.displayTeam(weeklyGame.homeTeamId)}</TableCell>
        <TableCell>{weeklyGame.homeTeamScore}</TableCell>
        <TableCell>{weeklyGame.finalScore && "F"}</TableCell>
      </TableRow>
    })}
  </TableBody></Table></TableContainer></Box>
}

export default TeamSchedule;