
export const displayDraftPick = function(pick, originalTeam) {
  return displayDraftPickDetails(pick.seasonYear, pick.roundNumber, pick.pickNumber, originalTeam);
}

export const displayDraftPickDetails = function(seasonYear, roundNumber, pickNumber, originalTeam) {
  return seasonYear + " " + getNumberOrdinal(roundNumber) + " Round" + 
      (pickNumber ? ", " + getNumberOrdinal(pickNumber) + " Pick" : "") + 
      " (" + originalTeam.name + ")";
}

export const getNumberOrdinal = function(number: number) {
     var onesDigit = number % 10,
        lastTwoDigits = number % 100;
    if (onesDigit === 1 && lastTwoDigits !== 11) {
        return number + "st";
    }
    if (onesDigit === 2 && lastTwoDigits !== 12) {
        return number + "nd";
    }
    if (onesDigit === 3 && lastTwoDigits !== 13) {
        return number + "rd";
    }
    return number + "th";
  }