import { Box, Button, Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { GlobalConfig, League, LeagueGame, Player, PlayerGameScore, ScoringSettings, Team, TeamPlayerInfo } from '../../../sdk/model';
import { leagueGameApi } from '../../../adapters/APIExporter';
import MatchupStarters from './MatchupPlayers';
import { getTeamFromLeague, getUsersTeamFromLeague, isOwnerOfTeam } from '../../util/TeamUtils';
import { usePlayerDataLoadFlat } from '../../util/PlayerDataUtil';
import { getPlayerScoreFromStats } from '../../util/ScoreService';
import { loadGameRosterFromQuickstats } from '../../util/GameUtil';
import { useDispatch } from 'react-redux';
import { TeamAvatar } from '../../util/AvatarUtils';

function LeagueMatchupView({ league }) {
  // TODO: Make matchup also take in user
  var { leagueId, week, season } = useParams();

  const [games, setGames] = React.useState<LeagueGame[] | undefined>(undefined);

  const user = useAppSelector((state) => state.user);

  const globalConfig: GlobalConfig = useAppSelector((state) => state.globalConfigDataLoad)

  const navigate = useNavigate();

  if (!week) {
    week = globalConfig.currentWeek?.toString();
  }
  if (!season) {
    season = globalConfig.currentSeason?.toString();
  }

  console.debug("Week: " + week + " Season: " + season);

  React.useEffect(() => {
    leagueGameApi.getGameByWeek(leagueId!, Number(week), Number(season)).then(response => setGames(response.data));
  }, [league, week, season])

  if (!league || !games) {
    return <p>Loading...</p>;
  } else if(games.length === 0){
    return (
      <Box>
        <LeagueMatchupLinks seasonYear={season} currentWeek={week} league={league} />
        <Typography>Games Not Found for Week {week}</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Box>
          <Typography variant="h4" align="center" fontWeight="bold">
            Week {week}
          </Typography>
          <Typography variant="body2" align="center">
            {season}
          </Typography>
          <LeagueMatchupLinks seasonYear={season} currentWeek={week} league={league} />
          <Box className="gameInfo">
            <Grid container rowSpacing={2} columnSpacing={5}>
            {games.map((game) => (
              // TODO: Play around more with sizing here for differeing size screens
              <Grid item xs={6} onClick={() => navigate(`/league/${leagueId}/matchup/id/${game.id}`)}>
                <GameSummary game={game} league={league} userId={user?.id || ''} />
              </Grid>
            ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function GameSummary({ game, league, userId }: { game: LeagueGame, league: League, userId: string }) {
  // TODO: Implement game summary logic
  const homeTeam = getTeamFromLeague(league, game.homeTeamId);
  const homeTeamClass = "home-team left-align" + (isOwnerOfTeam(homeTeam, userId) ? " ownersTeam" : "");
  const awayTeam = getTeamFromLeague(league, game.awayTeamId);

  return (
    <Grid container className="league-game" spacing={2} alignItems="center" >
      <Grid item className={homeTeamClass} container xs={3} alignItems="center">
        <Grid item xs={12} className="user">
            {homeTeam?.owners?.at(0)?.username ? `@${homeTeam?.owners?.at(0)?.username}` : 'N/A'}
        </Grid>
        <Grid item xs={12} className="team-avatar right-align" justifyContent="flex-start">
          <TeamAvatar {...homeTeam} />
        </Grid>
        <Grid item xs={12} className="team-name">
          {homeTeam?.name}
        </Grid>
      </Grid>
      <Grid item container xs={2} className="score right-align" alignItems="center" justifyContent="flex-end">
        <ScoreInfo game={game} league={league} home={true} />
      </Grid>
      <Grid item container xs={2} alignItems="center" justifyContent="center">
        <Box className="vs">
          VS.
        </Box>
      </Grid>
      <Grid item container xs={2} className="score left-align" alignItems="center" justifyContent="flex-start">
        <ScoreInfo game={game} league={league} home={false} />
      </Grid>
      <Grid item className="away-team" container xs={3}>
        <Grid item xs={12} className="user right-align">
            {awayTeam?.owners?.at(0)?.username ? `@${awayTeam?.owners?.at(0)?.username}` : 'N/A'}
        </Grid>
        <Grid item container xs={12} className="team-avatar" justifyContent="flex-end">
          <TeamAvatar {...awayTeam} />
        </Grid>
        <Grid item xs={12} className="team-name right-align">
          {awayTeam?.name}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ScoreInfo({ game, league, home }: { game: LeagueGame, league: League, home: boolean }) {
  const score = home ? game.homeTeamScore : game.awayTeamScore;
  const winner = game?.homeTeamScore > game?.awayTeamScore ? game.homeTeamId : game.awayTeamId;
  const isWinner = (game.finalScore && winner === (home ? game.homeTeamId : game.awayTeamId));
  const isLoser = (game.finalScore && winner !== (home ? game.homeTeamId : game.awayTeamId));

  console.log("Score: " + score); // TODO: Remove
  return (
    <Box className={`score-${home ? 'home' : 'away'} ${isWinner ? 'winner-box' : ''} ${isLoser ? 'loser-box' : ''}`}>
      {score}
    </Box>
  );
}

function LeagueMatchupLinks({ seasonYear, league, currentWeek }: { seasonYear: String | undefined, league: League, currentWeek: String | undefined }) {

  // Navigator/Dispatch
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const globalConfig: GlobalConfig =  useAppSelector((state) => state.globalConfigDataLoad)
  const maxWeek = seasonYear == globalConfig.currentSeason ? globalConfig.currentWeek : 17;

  return (
    <Box className="week-links">
      {Array.from({ length: maxWeek }, (_, i) => (
        <Button
          key={i + 1}
          className="matchup-link"
          disabled={currentWeek === (i + 1).toString()}
          onClick={() => {
            if (currentWeek === (i + 1).toString()) {
              return;
            }
            navigate("/league/" + league.id + `/week/${i + 1}/${seasonYear}`);
          }}
        >
          {i + 1}
        </Button>
      ))}
    </Box>
  );
}

export default LeagueMatchupView;
