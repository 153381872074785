import React, { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { dataloadToPlayerDataFlat } from '../util/PlayerDataUtil'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import './index.css';
import { Player } from '../../sdk/model';
import { playerApi } from '../../adapters/APIExporter';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { getColorByPosition } from '../util/ColorByPosition'

//Using https://www.material-react-table.com/docs/examples/basic

const SelectPlayer = ({rookieYear, unavailablePlayers, playerSelection, setPlayerSelection, queue, setQueue}) => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'quick_stats.rankOnYear', //access nested data with dot notation
        id: 'rank',
        header: 'Rank',
        minSize: 15,
        size: 15,
      },
      {
        accessorKey: 'first_name', //access nested data with dot notation
        header: 'First Name',
        size: 75,
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 75,
      },
      {
        accessorKey: 'position', //normal accessorKey
        id: 'position',
        header: 'Position',
        minSize: 15,
        size: 15,
      },
      {
        accessorKey: 'nfl_team.alias',
        header: 'Team',
        minSize: 15,
        size: 15,
      },
      {
        accessorKey: 'ageYears',
        header: 'Age',
        minSize: 15,
        size: 15,
      },
    ],
    [],
  );

  // TODO: Make this happen at the top level so we are not reloading the data every time
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );

  console.log("Player Data Load: ");
  console.log(playerDataLoad);
  
  // IMPORTANT: This is where we are loading the data into the table
  const [data, setData] = React.useState<Player[]>([])
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function filter(players) {
    if (queue) {
      players = queue.map(queuedPlayerId => players.find(player => player.id === queuedPlayerId));
    }
    if (rookieYear) {
      players = players.filter(player => player.rookie_year === rookieYear);
    }
    if (unavailablePlayers) {
	  players = players.filter(player => !unavailablePlayers.has(player.id));
    }
    return players;
  }

  React.useEffect(() => {
    setData(filter(dataloadToPlayerDataFlat(playerDataLoad)));
    if (Object.keys(playerSelection).length > 0 && unavailablePlayers.has(Object.keys(playerSelection)[0])) {
	  setPlayerSelection({});
    }
  }, [playerDataLoad, unavailablePlayers]);

  console.log("Player Selection: " + playerSelection);  

  if(setPlayerSelection === undefined || setPlayerSelection === null)  {
    console.log("setPlayerSelection is null, not rendering row selection");  
    return <MaterialReactTable columns={columns} data={data}
      ></MaterialReactTable>
  }

  console.log("setPlayerSelection is not null, rendering row selection")
  return <MaterialReactTable enableRowSelection={true} enableMultiRowSelection={false} 
      onRowSelectionChange={setPlayerSelection} state={{ rowSelection : playerSelection, pagination}} 
      getRowId={(row) => row.id} columns={columns} 
      data={data.filter((player) => player?.quick_stats?.rankOnYear !== -1)} 
      enableRowOrdering={queue} enableRowSorting={!queue} autoResetPageIndex={false}
      onPaginationChange={newPagination => {
        const nextState = newPagination(pagination);
        if (nextState.pageIndex !== pagination.pageIndex || nextState.pageSize !== pagination.pageSize) {
          setPagination(nextState);
        }
      }}
      initialState={queue ? null : {
        sorting: [
          {
            id: 'rank',
            desc: false,
          },
        ],
        
      }}
      
      //clicking anywhere on the row will select it
      muiTableBodyRowProps={({ row }) => ({
        onClick: row.getToggleSelectedHandler(),
        sx: getColorByPosition(row.getValue('position'))
      })}

      muiTableBodyCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            color: 'black'
          },
      }}
      muiTableHeadCellProps={{
        sx: {
          "& .Mui-TableHeadCell-Content-Labels": {
            padding: "0px",
          },
          "& .MuiBox-root": {
            padding: "0px",
          },
        },
      }}
      muiTableBodyRowDragHandleProps={({ table }) => ({
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState();
          if (hoveredRow && draggingRow && draggingRow !== hoveredRow) {
            var newData = [...data];
            newData.splice(
              hoveredRow.index,
              0,
              newData.splice(draggingRow.index, 1)[0],
            );
            setQueue(newData.map(data => data.id));
          }
        },
      })}

      />;
    
};

export default SelectPlayer;



