import * as React from 'react';
import { League } from '../../../../sdk/model';
import { useAppSelector } from '../../../../app/hooks';
import { useLoadLeagueData } from '../../../../app/dataLoaderHooks';
import {Box, FormControl, InputLabel, Select, MenuItem, Typography, Button} from '@mui/material';
import { leagueSeasonApi, leagueApi } from '../../../../adapters/APIExporter';
import AllTeamsSchedule from '../AllTeamsSchedule';

interface scheduleSettingsProps {
  league: League
}

const FINAL_WEEK_OF_NFL_SEASON_REGULAR_SEASON = 18;

function getNumberOfPlayoffRounds(playoffTeams: number) {
  if (playoffTeams === 0) {
    return 0;
  } else if (playoffTeams === 2) {
    return 1;
  } else if (playoffTeams <= 4) {
    return 2;
  } else if (playoffTeams <= 8) {
    return 3;
  } else if (playoffTeams <= 16) {
    return 4;
  } else {
    return 5;
  }
}

function ScheduleSettings(props: scheduleSettingsProps) {
  const [editMode, setEditMode] = React.useState(false);
  const [editMatchupsMode, setEditMatchupsMode] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [mutableSeason, setMutableSeason] = React.useState(props.league.currentSeason);
  
  const teamMap = new Map(props.league?.teams.map(team => [team.id, team]));
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: props.league.id, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadScheduleData: true,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  React.useEffect(() => {
    if (league?.currentSeason?.scheduleLoaded) {
      setMutableSeason(league?.currentSeason);
    }
  }, [league?.currentSeason]);
  
  if (!isLoadComplete || !league!.currentSeason || !mutableSeason!.schedule) {
    return <p>Loading...</p>
  }
  const gamesByWeek = mutableSeason!.schedule?.reduce((partialResults, game) => {
    partialResults[game.weekNumber!] = partialResults[game.weekNumber!] || [];
    partialResults[game.weekNumber!].push(game);
    return partialResults;
  }, {});
  
  function handleChange(event) {
    const {name, value} = event.target;
    const newMutableSeason = {...mutableSeason};
    newMutableSeason[name] = value;
    if (name === "playoffTeams") {
      const numberOfPlayoffRounds = getNumberOfPlayoffRounds(value);
      const playoffRoundConfig = [...Array(numberOfPlayoffRounds).keys()]
        .map(playoffRoundIndex => playoffRoundIndex + 1)
        .map(playoffRound => {
          return {
            roundNumber : playoffRound,
            weeks: 1
          }
        });
      newMutableSeason.playoffRoundConfig = playoffRoundConfig;
    }
    setMutableSeason(newMutableSeason);
  }
  
  function cancelEdit() {
    setMutableSeason(league?.currentSeason);
    setEditMode(false);
    setEditMatchupsMode(false);
  }
  
  function saveScheduleChange() {
    leagueSeasonApi.updateCurrentLeagueSeason(mutableSeason?.leagueId, mutableSeason).then(() => setEditMode(false));
  }
  
  function hasInvalidMatchups() {
    return Object.values(gamesByWeek).some(weeklyMatchups => {
      const weeklyTeams = weeklyMatchups.reduce((partialResult, game) => {
        partialResult.push(game.homeTeamId);
        partialResult.push(game.awayTeamId);
        return partialResult;
      }, []);
      return (new Set(weeklyTeams)).size !== weeklyTeams.length;
    });
  }
  
  function saveMatchupsChange() {
    leagueApi.updateScheduleData(mutableSeason?.schedule, league?.id).then(() => setEditMatchupsMode(false));
  }
  
  function displayTeam(teamId) {
    const team = teamMap.get(teamId);
    if (!team){
      return ""
    }
    if (team.owners?.some(owner => owner.id === user.id)) {
      return <b>{team.name}</b>
    }
    return team.name;
  }
  
  function editSchedule(gameId, homeAway, teamId) {
    const newSeason = {...mutableSeason, schedule: mutableSeason?.schedule?.map(game => {return {...game}})};
    const game = newSeason.schedule?.find(game => game.id === gameId);
    if(homeAway === 'home') {
      game!.homeTeamId = teamId;
    } else {
      game!.awayTeamId = teamId;
    }
    setMutableSeason(newSeason);
  }
  
  function randomizeSchedule() {
    leagueApi.randomizeSchedule(league?.id);
  }
  
  const finalWeek = mutableSeason?.finalWeekOfRegularSeason! + mutableSeason?.playoffRoundConfig!.reduce((partialSum, roundConfig) => partialSum + roundConfig.weeks!, 0)!;
  const invalidMatchups = hasInvalidMatchups();
  
  return <Box>
    <FormControl sx={{minWidth:250}}>
      <InputLabel id="finalWeekOfRegularSeasonLabel">Final Week of Regular Season</InputLabel>
      <Select name="finalWeekOfRegularSeason" labelId="finalWeekOfRegularSeasonLabel"value={mutableSeason?.finalWeekOfRegularSeason} disabled={!editMode} onChange={handleChange}>
        {[...Array(FINAL_WEEK_OF_NFL_SEASON_REGULAR_SEASON + 1).keys()].map(weekNumber => <MenuItem key={weekNumber} value={weekNumber}>{weekNumber}</MenuItem>)}
      </Select>
    </FormControl>
    <FormControl sx={{minWidth:250}}>
      <InputLabel id="playoffTeamsLabel">Number of Playoff Teams</InputLabel>
      <Select name="playoffTeams" labelId="playoffTeams" value={mutableSeason?.playoffTeams} disabled={!editMode} onChange={handleChange}>
        {[...Array(props.league.teams.length + 1).keys()].filter(playoffTeamsOption => playoffTeamsOption !== 1).map(playoffTeamsOption => <MenuItem key={playoffTeamsOption} value={playoffTeamsOption}>{playoffTeamsOption}</MenuItem>)}
      </Select>
    </FormControl>
    <br /><br />
    <Typography variant="h6">
      Number of Weeks Per Matchup in the Playoffs:
    </Typography>
    <br />
    {mutableSeason?.playoffRoundConfig!.map(roundConfig => <FormControl key={roundConfig.roundNumber} sx={{minWidth:100}}>
      <InputLabel id={"round" + roundConfig.roundNumber + "WeeksLabel"}>Round {roundConfig.roundNumber}</InputLabel>
      <Select name={"round" + roundConfig.roundNumber + "Weeks"} labelId={"round" + roundConfig.roundNumber + "WeeksLabel"} value={roundConfig.weeks} disabled={!editMode} 
          onChange={(event) => {
            const newMutableSeason = {...mutableSeason, playoffRoundConfig: mutableSeason?.playoffRoundConfig!.map(config => {return {...config}})};
            newMutableSeason.playoffRoundConfig![roundConfig?.roundNumber! - 1].weeks = event.target.value;
            setMutableSeason(newMutableSeason);
          }}>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
      </Select>
    </FormControl>)}
    <Typography sx={finalWeek > FINAL_WEEK_OF_NFL_SEASON_REGULAR_SEASON ? {color: 'red'} : {}}>Final Week of Fantasy Season: {finalWeek}</Typography>
    <Typography>(NFL Regular Season ends Week {FINAL_WEEK_OF_NFL_SEASON_REGULAR_SEASON})</Typography>
    {editMode ?
      <Box>
        <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
        <Button className='capsized-button' disabled={finalWeek > FINAL_WEEK_OF_NFL_SEASON_REGULAR_SEASON} onClick={saveScheduleChange}>Save Schedule Settings</Button>
      </Box> : (editMatchupsMode ? <Box>
          <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
          <Button className='capsized-button' onClick={saveMatchupsChange} disabled={invalidMatchups}>Save Updated Schedule</Button>
        </Box> :
        <Box>
          <Button className='capsized-button' onClick={() => setEditMode(true)}>Edit Schedule Settings</Button>
          <Button className='capsized-button' onClick={() => setEditMatchupsMode(true)}>Manually Edit Matchups</Button>
          <Button className='capsized-button' onClick={() => randomizeSchedule()}>Randomize Schedule</Button>
        </Box>
      )
    }
    <br />
    <Typography variant="h6">
      Full Schedule:
    </Typography>
    <AllTeamsSchedule leagueId={league?.id!} gamesByWeek={gamesByWeek} displayTeam={displayTeam} teams={props.league.teams} editMode={editMatchupsMode} editSchedule={editSchedule} />
  </Box>
}

export default ScheduleSettings;
