import * as React from 'react';
import { Team } from '../../../../sdk/model';
import type { CSSProperties } from 'react'
import { useDrag } from 'react-dnd';
import type { DragSourceMonitor } from 'react-dnd'
import { Box, IconButton, Avatar } from '@mui/material';
import { TeamAvatar } from '../../../util/AvatarUtils';
interface divisionTeamItemProps {
  team: Team,
  divisionIndex: number,
  moveDivision: Function,
  editMode: boolean
}

interface DropResult {
  allowedDropEffect: string
  dropEffect: string
  name: string
  divisionIndex: number
}

const style: CSSProperties = {
  border: '1px dashed gray',
  width: 'fit-content',
  cursor: 'grab'
}

function DivisionTeamItem(props: divisionTeamItemProps) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'divisionTeam',
      item: props,
      end(item, monitor) {
        const dropResult = monitor.getDropResult() as DropResult
        if (item && dropResult) {
          const isDropAllowed = dropResult.allowedDropEffect === dropResult.dropEffect &&
              dropResult.divisionIndex !== props.divisionIndex;

          if (isDropAllowed) {
            props.moveDivision(props.team.id, props.divisionIndex, dropResult.divisionIndex);
          }
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [props.moveDivision],
  )
  
  return <Box><Box ref={props.editMode ? drag : null} style={props.editMode ? { ...style, opacity} : {}} className="centered">
    <IconButton sx={{ p: 0 }} disabled>
      <TeamAvatar team={props.team}>{!props.team.picture && props.team.name?.substring(0,1)}</TeamAvatar>
    </IconButton>
    {props.team.name}
  </Box></Box>
}

export default DivisionTeamItem;
