import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Tabs, Tab } from '@mui/material';
import DraftSettings from './DraftSettings';
import AuctionSettings from './AuctionSettings';
import LeagueSettings from './LeagueSettings';
import AllTeamsSettings from './AllTeamsSettings';
import DivisionSettings from './DivisionSettings';
import ScheduleSettings from './ScheduleSettings';
import ManageRosters from './ManageRosters';

function CommissionerTools({league}) {
  const [view, setView] = React.useState("leagueSettings");
  const viewMap = new Map([
    ["leagueSettings", () => <LeagueSettings league={league} />],
    ["teamSettings", () => <AllTeamsSettings league={league} />],
    ["divisionSettings", () => <DivisionSettings league={league} />],
    ["draftAuctionSettings", () => <Box>
      <DraftSettings league={league} />
      <AuctionSettings league={league} />
    </Box>],
    ["scheduleSettings", () => <ScheduleSettings league={league} />],
    ["manageRosters", () => <ManageRosters league={league} />],
  ]);
  return <Box>
      <Typography variant="h4" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Commissioner Tools:
      </Typography>
      <Tabs value={view} onChange={(event, value) => {
          setView(value)
          }} aria-label="Auction Tabs">
        <Tab label="League Settings" value="leagueSettings" />
        <Tab label="Team Settings" value="teamSettings" />
        <Tab label="Division Settings" value="divisionSettings" />
        <Tab label="Schedule Settings" value="scheduleSettings" />
        <Tab label="Draft/Auction Settings" value="draftAuctionSettings" />
        <Tab label="Manage Rosters" value="manageRosters" />
      </Tabs>
      <br/>
      {viewMap.get(view)!.apply(this)}
    </Box>;
}

export default CommissionerTools;