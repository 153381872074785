import { Box ,Button,Link,Typography } from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { GlobalConfig, League, LeagueGame, Player, PlayerGameScore, ScoringSettings, Team, TeamPlayerInfo } from '../../../sdk/model';
import { leagueGameApi } from '../../../adapters/APIExporter';
import MatchupStarters from './MatchupPlayers';
import { getUsersTeamFromLeague } from '../../util/TeamUtils';
import { usePlayerDataLoadFlat } from '../../util/PlayerDataUtil';
import { getPlayerScoreFromStats } from '../../util/ScoreService';
import { loadGameRosterFromQuickstats } from '../../util/GameUtil';
// import confetti from 'canvas-confetti';
import { useDispatch } from 'react-redux';

function MatchupView({league}) {
  // TODO: Make matchup also take in user
  var {leagueId, week: paramsWeek, season: paramsSeason, teamId: paramsTeamId, id} = useParams();

  const user = useAppSelector((state) => state.user);
  const myTeam = getUsersTeamFromLeague(league, user?.id);
  const globalConfig: GlobalConfig =  useAppSelector((state) => state.globalConfigDataLoad)
  const location = useLocation();
  
  const [game, setGame] = React.useState<LeagueGame | undefined>({"loading": true}); 
  // TODO: If the game exist (i.e. after a reload, then we want to set the week and season to the game's week and season)
  const [week, setWeek] = React.useState(paramsWeek || globalConfig.currentWeek);
  const [season, setSeason] = React.useState(paramsSeason || globalConfig.currentSeason);
  const [teamId, setTeamId] = React.useState(paramsTeamId || myTeam?.id);

  const playerMap :  Map<string | undefined, Player | undefined> = usePlayerDataLoadFlat();


  // This is required for navigation on page using the matchup links
  React.useEffect(() => {
    setWeek(paramsWeek || week);
    setSeason(paramsSeason || season);
  }, [location]);

  // Load game from backend (should this be saved in a slice state?)
  React.useEffect(() => {
    getLeagueGame(leagueId, week, season, teamId, id, setGame);
  }, [league, week, season])

  React.useEffect(() => {
    // TODO: This should probably be based on something other than the homeTeamRoster existing (like maybe final score... or something else) but I am not sure
    if(game && !game?.loading && !game.finalScore) {

      setSeason(game?.seasonYear);
      setWeek(game?.weekNumber);

      // If the game we retrieve has not been populated with score data AND it is the current weeks game then load the score info from quickstats
      if(season != globalConfig.currentSeason || week != globalConfig.currentWeek) {
        console.error("Game not found for week " + week + " season " + season + " in league " + leagueId)
        setGame(undefined)
      } else {
        console.debug("Loading game roster...")
        setGame(loadGameRosterFromQuickstats(league, game, playerMap));
      }
      
    }
  }, [game])

  if (!league || (game && game.loading)) {
    return <p>Loading...</p>;
  } 
  else if (game && !game.homeTeamRoster) {
    return <p>Loading Score...</p>;
  } 
  else if (!game) {
    return (
      <Box>
        <MatchupLinks seasonYear={season} currentWeek={week} league={league} setGame={setGame} />
        <Typography>Game not found</Typography>
      </Box>
    );
  }

  // Shoot some confetti if the user won
  // if(!game.loading && game.winningTeamId === myTeam?.id) {
  //   confetti({
  //     particleCount: 100,
  //     spread: 70,
  //     origin: { y: 0.6 }
  //   });
  // }

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Box>
          <Typography variant="h4" align="center" fontWeight="bold">
            Week {game.weekNumber}
          </Typography>
          <Typography variant="body2" align="center">
            {game.seasonYear}
          </Typography>
          <MatchupLinks seasonYear={game?.seasonYear} currentWeek={week} league={league} setGame={setGame} />
        </Box>
      </Box>
      <MatchupStarters league={league!} game={game} />
    </Box>
  );

}


function MatchupLinks({ seasonYear, league, setGame, currentWeek }: { seasonYear: number | undefined, league: League, setGame: React.Dispatch<React.SetStateAction<LeagueGame | undefined>>, currentWeek: number | undefined }) {

  // Navigator/Dispatch
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const globalConfig: GlobalConfig =  useAppSelector((state) => state.globalConfigDataLoad)
  const maxWeek = seasonYear == globalConfig.currentSeason ? globalConfig.currentWeek : 17;


  return (
    <Box className="matchup-links">
      {Array.from({ length: maxWeek }, (_, i) => (
        <Button 
          key={i+1}
          className="matchup-link"
          disabled={currentWeek === (i+1)}
          onClick={() => {
            if (currentWeek === (i+1)) {
              return;
            }
            setGame({"loading": true});
            navigate("/league/" + league.id + `/matchup/${i+1}/${seasonYear}`);
          }}
        >
          {i+1}
        </Button>
      ))}
    </Box>
  );
}

function getLeagueGame(leagueId: string, week: number, season: number, teamId: string | undefined, id: string, 
  setGame: React.Dispatch<React.SetStateAction<LeagueGame | undefined>>) {
  if(id) {
    console.log("Getting game by id: " + id)
    leagueGameApi.getGame(id).then(response => setGame(response.data));
    return;
  }
  console.log("Getting game by week: " + week + " season: " + season + " league: " + leagueId + " team: " + teamId)
  leagueGameApi.getGameByWeekAndPlayer(Number(week), Number(season), leagueId, teamId ?? '').then(response => setGame(response.data));
}

export default MatchupView;
