import * as React from 'react'
import { Team, ContractSeason } from '../../../sdk/model';
import { Box, Collapse, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Paper, Typography } from '@mui/material';
import { toSalary } from '../../util/SalaryDisplay';
import KeyboardArrowDownIcon  from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon  from '@mui/icons-material/KeyboardArrowUp';

interface contractSeasonDisplayProps {
  contractSeason: ContractSeason,
  teamMap: Map<string | undefined, Team>
}

function getRemainingGuarantee(week) {
  const paid = week.payments
      .filter(payment => payment.completed)
      .reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price, 0);
  return Math.max(week.guaranteedSalary - paid, 0);
}
  
function getRemainingNonGuarantee(week) {
  const paid = week.payments
      .filter(payment => payment.completed)
      .reduce((partialPaymentSum, payment) => partialPaymentSum + payment.price, 0);
  return Math.max(week.fullSalary - week.guaranteedSalary - paid, 0);
}

function ContractSeasonDisplay(props: contractSeasonDisplayProps) {
 const [showPayments, setShowPayments] = React.useState(false);
  
  const paid = props.contractSeason.weeks!
      .flatMap(week => week.payments)
      .filter(payment => payment?.completed)
      .reduce((partialSum, payment) => partialSum + payment?.price!, 0);
  const remaining = props.contractSeason.annual_salary! - paid;
  const remainingGuaranteed = props.contractSeason.weeks!
      .reduce((partialSum, week) => partialSum + getRemainingGuarantee(week), 0);
  const remainingNonGuaranteed = props.contractSeason.weeks!
      .reduce((partialSum, week) => partialSum + getRemainingNonGuarantee(week), 0);
  const paymentTeams =[... new Set(props.contractSeason.weeks!.flatMap(week => week.payments!.map(payment => payment.teamId + "-" + (payment.acceleratedFromYear ? payment.acceleratedFromYear : ""))))]
    .map(paymentId => {
      const paymentInfo = paymentId.split("-");
      return {
        team: props.teamMap.get(paymentInfo[0]),
        accelerated: paymentInfo[1] === '' ? undefined : parseInt(paymentInfo[1])
      }
    });
  return <React.Fragment>
    <TableRow>
      <TableCell>
        <IconButton
            aria-label="show payments"
            size="small"
            onClick={() => setShowPayments(!showPayments)}>
          {showPayments ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell>{props.contractSeason.season_year}</TableCell>
      <TableCell>{toSalary(props.contractSeason.annual_salary)}</TableCell>
      <TableCell>{toSalary(props.contractSeason.annual_salary! * props.contractSeason.guarantee_percentage!)}</TableCell>
      <TableCell>{toSalary(paid)}</TableCell>
      <TableCell>{toSalary(remaining)}</TableCell>
      <TableCell>{toSalary(remainingGuaranteed)}</TableCell>
      <TableCell>{toSalary(remainingNonGuaranteed)}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={showPayments} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant='h5'>{props.contractSeason.season_year} Payments:</Typography>
            <TableContainer className="centered" component={Paper}><Table>
              <TableHead><TableRow>
                <TableCell/>
                {props.contractSeason.weeks?.map(week => <TableCell key={week.weekNumber}>Week {week.weekNumber}</TableCell>)}
              </TableRow></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Full</TableCell>
                  {props.contractSeason.weeks?.map(week => {
                    const fontWeight = week.status === 'TERMINATED' ? 'normal' : 'bold';
                    const color = week.status === 'COMPLETED' ? 'green' : 'white';
                    return <TableCell key={week.weekNumber} sx={{fontWeight: fontWeight, color: color}}>{toSalary(week.fullSalary)}</TableCell>
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Guaranteed</TableCell>
                  {props.contractSeason.weeks?.map(week => <TableCell key={week.weekNumber} sx={week.status === 'TERMINATED' ? {fontWeight: 'bold', fontColor: 'green'} : undefined}>{toSalary(week.guaranteedSalary)}</TableCell>)}
                </TableRow>
                {paymentTeams.map(paymentTeam => {
                  const paymentsByWeek = props.contractSeason.weeks?.map(week => 
                    week.payments!.filter(payment => payment.teamId === paymentTeam.team!.id && payment.acceleratedFromYear === paymentTeam.accelerated)
                  )!
                  const totalColor = paymentsByWeek.every(payments => payments.every(payment => payment.completed)) ? 'green' : 'white';
                  return <TableRow key={paymentTeam.team!.id + "-" + paymentTeam.accelerated}>
                    <TableCell>
                      {paymentTeam.team!.name}
                      {!paymentTeam.accelerated || <p>(Accelerated from {paymentTeam.accelerated})</p>}
                    </TableCell>
                    {paymentsByWeek.map((payments, weekIndex) => {
                      const color = payments.every(payment => payment.completed) ? 'green' : 'white';
                      return <TableCell key={weekIndex} sx={{color: color}}>{toSalary(payments.reduce((partialSum, payment) => partialSum + payment.price!, 0))}</TableCell>
                    })}
                  </TableRow>
                })}
              </TableBody>
            </Table></TableContainer>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
}

export default ContractSeasonDisplay;
