import Close from "@mui/icons-material/Close";
import FastRewind from "@mui/icons-material/FastRewind";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LeagueEventApi } from "../../../../sdk/api/league-event-api";

// Valid states for this button to be shown
const validStates = ['STARTED', 'RESUMED','PAUSED']

export interface RewindDraftButtonProps {
  leagueEventApi: LeagueEventApi;
  draftStatus: string;
  draftId: string;
}

function RewindDraftButton(props: RewindDraftButtonProps) {

  const [rewinding, setRewinding] = React.useState(false);

  if(!showButton(props.draftStatus)){
    return null;
  }

  const rewindPick = function() {
    props.leagueEventApi.rewindEvent(props.draftId).then((response) => {
      setRewinding(false);
      });
  }
  
  return (
    <Box>
      <Dialog open={rewinding} maxWidth="sm" fullWidth>
        <DialogTitle>Rewind pick?</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setRewinding(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure you want to undo the most recent pick made?  This action is irreversable!</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setRewinding(false)}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={rewindPick}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
          color="inherit"
          sx={{ width: 20}}
          aria-label="rewind last pick"
          onClick={() => setRewinding(true)}>
        <FastRewind />
      </IconButton>
    </Box>
  );
}


function showButton(draftStatus: string){
  return validStates.includes(draftStatus);
}

export default RewindDraftButton;