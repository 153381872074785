/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LeagueGame } from '../model';
/**
 * LeagueGameApi - axios parameter creator
 * @export
 */
export const LeagueGameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGame: async (gameId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('getGame', 'gameId', gameId)
            const localVarPath = `/league_game/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Game info from a specific week
         * @param {string} leagueId 
         * @param {number} week 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameByWeek: async (leagueId: string, week: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getGameByWeek', 'leagueId', leagueId)
            // verify required parameter 'week' is not null or undefined
            assertParamExists('getGameByWeek', 'week', week)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getGameByWeek', 'year', year)
            const localVarPath = `/league_game/league/{leagueId}/week/{week}/season/{year}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets game info given week/year/player
         * @param {number} week 
         * @param {number} year 
         * @param {string} leagueId 
         * @param {string} playerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameByWeekAndPlayer: async (week: number, year: number, leagueId: string, playerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'week' is not null or undefined
            assertParamExists('getGameByWeekAndPlayer', 'week', week)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getGameByWeekAndPlayer', 'year', year)
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getGameByWeekAndPlayer', 'leagueId', leagueId)
            // verify required parameter 'playerId' is not null or undefined
            assertParamExists('getGameByWeekAndPlayer', 'playerId', playerId)
            const localVarPath = `/league_game/league/{leagueId}/week/{week}/season/{year}/user/{playerId}`
                .replace(`{${"week"}}`, encodeURIComponent(String(week)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueGameApi - functional programming interface
 * @export
 */
export const LeagueGameApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueGameApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGame(gameId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueGame>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGame(gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Game info from a specific week
         * @param {string} leagueId 
         * @param {number} week 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGameByWeek(leagueId: string, week: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeagueGame>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGameByWeek(leagueId, week, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets game info given week/year/player
         * @param {number} week 
         * @param {number} year 
         * @param {string} leagueId 
         * @param {string} playerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGameByWeekAndPlayer(week: number, year: number, leagueId: string, playerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueGame>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGameByWeekAndPlayer(week, year, leagueId, playerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeagueGameApi - factory interface
 * @export
 */
export const LeagueGameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueGameApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGame(gameId: string, options?: any): AxiosPromise<LeagueGame> {
            return localVarFp.getGame(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Game info from a specific week
         * @param {string} leagueId 
         * @param {number} week 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameByWeek(leagueId: string, week: number, year: number, options?: any): AxiosPromise<Array<LeagueGame>> {
            return localVarFp.getGameByWeek(leagueId, week, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets game info given week/year/player
         * @param {number} week 
         * @param {number} year 
         * @param {string} leagueId 
         * @param {string} playerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameByWeekAndPlayer(week: number, year: number, leagueId: string, playerId: string, options?: any): AxiosPromise<LeagueGame> {
            return localVarFp.getGameByWeekAndPlayer(week, year, leagueId, playerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueGameApi - object-oriented interface
 * @export
 * @class LeagueGameApi
 * @extends {BaseAPI}
 */
export class LeagueGameApi extends BaseAPI {
    /**
     * 
     * @summary Returns Game
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueGameApi
     */
    public getGame(gameId: string, options?: AxiosRequestConfig) {
        return LeagueGameApiFp(this.configuration).getGame(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Game info from a specific week
     * @param {string} leagueId 
     * @param {number} week 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueGameApi
     */
    public getGameByWeek(leagueId: string, week: number, year: number, options?: AxiosRequestConfig) {
        return LeagueGameApiFp(this.configuration).getGameByWeek(leagueId, week, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets game info given week/year/player
     * @param {number} week 
     * @param {number} year 
     * @param {string} leagueId 
     * @param {string} playerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueGameApi
     */
    public getGameByWeekAndPlayer(week: number, year: number, leagueId: string, playerId: string, options?: AxiosRequestConfig) {
        return LeagueGameApiFp(this.configuration).getGameByWeekAndPlayer(week, year, leagueId, playerId, options).then((request) => request(this.axios, this.basePath));
    }
}
