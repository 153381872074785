export const enum LeaguePhase {
  OFFSEASON = "Off Season", 
  DRAFT_PREP = "Draft Prep",
  DRAFT = "Draft",
  TRAINING_CAMP = "Training Camp", 
  AUCTION_PREP = "Auction Prep", 
  AUCTION = "Auction",
  PRE_SEASON = "Pre Season",
  REGULAR_SEASON = "Regular Season",
  PLAYOFFS = "Playoffs",
  SEASON_COMPLETE = "Season Complete"
}
