import * as React from 'react';
import type { CSSProperties } from 'react'
import { Division, Team } from '../../../../sdk/model';
import { Box, TextField, Typography, IconButton, Avatar } from '@mui/material';
import DivisionTeamItem from './DivisionTeamItem';
import { useDrop } from 'react-dnd'

interface divisionContainerProps {
  division: Division,
  divisionIndex: number,
  editMode: boolean,
  updateDivision: Function,
  moveDivision: Function
  teamMap: Map<string | undefined, Team | undefined>
}

const style: CSSProperties = {
  border: '1px solid white', 
  width: '50%', 
  marginBottom: 15, 
  padding: 15
}

function selectBackgroundColor(isActive: boolean, canDrop: boolean) {
  if (isActive) {
    return 'darkgreen'
  } else if (canDrop) {
    return 'grey'
  } else {
    return '#222'
  }
}

function DivisionContainer(props: divisionContainerProps) {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'divisionTeam',
      drop: () => ({
        name: 'Division Container',
        divisionIndex: props.divisionIndex,
        allowedDropEffect: 'move',
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && monitor.getItem().divisionIndex !== props.divisionIndex,
      }),
    }),
    [],
  );
  
  function handleDivisionChange(event) {
    const {name, value} = event.target;
    const newDivision = {...props.division};
    newDivision[name] = value;
    props.updateDivision(newDivision, props.divisionIndex);
  }
  
  const isActive = canDrop && isOver
  const backgroundColor = selectBackgroundColor(isActive, canDrop)
  return <Box ref={drop} className="centered" style={{ ...style, backgroundColor}}>
    {props.editMode ?
      <TextField name='name' label="Division Name" value={props.division.name} onChange={handleDivisionChange}/> :
      <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        {props.division.name}
      </Typography>
    }
    <br />
    {props.editMode && <TextField name='picture' label="Image URL" value={props.division.picture} onChange={handleDivisionChange}/>}
    <IconButton sx={{ p: 0 }} disabled>
      <Avatar alt={props.division.name} src={props.division.picture}>{!props.division.picture && props.division.name?.substring(0,1)}</Avatar>
    </IconButton>
    <br/>
    <Box>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Teams:
      </Typography>
      {props.division.teams!.map(teamId => props.teamMap.get(teamId)!).map(team => <DivisionTeamItem key={team.id} team={team} divisionIndex={props.divisionIndex} moveDivision={props.moveDivision} editMode={props.editMode} />)}
    </Box>
  </Box>
}

export default DivisionContainer;
