import * as React from 'react';
import { Player } from '../../../sdk/model';
import {Button} from '@mui/material';
import {isPlayerLocked} from '../../util/PlayerDataUtil';

interface bidOnPlayerButtonProps {
  leagueId : string,
  player : Player,
  teamId : string
}

function BidOnPlayerButton(props: bidOnPlayerButtonProps) {
  return isPlayerLocked(props.player) ? <Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + props.leagueId + '/team/' + props.teamId + '/faab/' + props.player.id)}>Bid</Button> : null;
}

export default BidOnPlayerButton;
