/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { League } from '../model';
// @ts-ignore
import { LeagueCreator } from '../model';
// @ts-ignore
import { LeagueGame } from '../model';
/**
 * LeagueApi - axios parameter creator
 * @export
 */
export const LeagueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league
         * @param {LeagueCreator} leagueCreator A new &#x60;league&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeague: async (leagueCreator: LeagueCreator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueCreator' is not null or undefined
            assertParamExists('createLeague', 'leagueCreator', leagueCreator)
            const localVarPath = `/leagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueCreator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permanently deletes an existing `league`.
         * @summary Delete a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeague: async (leagueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('deleteLeague', 'leagueId', leagueId)
            const localVarPath = `/leagues/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the details of a single instance of a `league`.
         * @summary Get a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
         * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
         * @param {boolean} [includeContractInfo] Roster will also contain contract information
         * @param {boolean} [includeRosterInfo] Populate team\&#39;s rosters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeague: async (leagueId: string, quickView?: boolean, username?: string, includeContractInfo?: boolean, includeRosterInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('getLeague', 'leagueId', leagueId)
            const localVarPath = `/leagues/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (quickView !== undefined) {
                localVarQueryParameter['quick_view'] = quickView;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (includeContractInfo !== undefined) {
                localVarQueryParameter['includeContractInfo'] = includeContractInfo;
            }

            if (includeRosterInfo !== undefined) {
                localVarQueryParameter['includeRosterInfo'] = includeRosterInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `league` entities.
         * @summary List All leagues
         * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
         * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagues: async (quickView?: boolean, username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leagues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (quickView !== undefined) {
                localVarQueryParameter['quick_view'] = quickView;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the schedule for the given `league` for the given season year
         * @param {string} [leagueId] A unique identifier for a league
         * @param {number} [seasonYear] The season for which the schedule should be loaded
         * @param {number} [week] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleData: async (leagueId?: string, seasonYear?: number, week?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/league/{leagueId}/season/{seasonYear}/schedule`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)))
                .replace(`{${"seasonYear"}}`, encodeURIComponent(String(seasonYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (week !== undefined) {
                localVarQueryParameter['week'] = week;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Randomizes the `league`\'s current season schedule based on the current season settings
         * @param {string} [leagueId] A unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomizeSchedule: async (leagueId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/league/{leagueId}/schedule/randomize`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing `league`.
         * @summary Update a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {League} league Updated &#x60;league&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeague: async (leagueId: string, league: League, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueId' is not null or undefined
            assertParamExists('updateLeague', 'leagueId', leagueId)
            // verify required parameter 'league' is not null or undefined
            assertParamExists('updateLeague', 'league', league)
            const localVarPath = `/leagues/{leagueId}`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(league, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the schedule for the given `league` for the given season year
         * @param {Array<LeagueGame>} leagueGame Information about the scheduled matchups to be updated
         * @param {string} [leagueId] A unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduleData: async (leagueGame: Array<LeagueGame>, leagueId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueGame' is not null or undefined
            assertParamExists('updateScheduleData', 'leagueGame', leagueGame)
            const localVarPath = `/league/{leagueId}/schedule`
                .replace(`{${"leagueId"}}`, encodeURIComponent(String(leagueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueGame, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueApi - functional programming interface
 * @export
 */
export const LeagueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league
         * @param {LeagueCreator} leagueCreator A new &#x60;league&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLeague(leagueCreator: LeagueCreator, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLeague(leagueCreator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permanently deletes an existing `league`.
         * @summary Delete a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeague(leagueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeague(leagueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the details of a single instance of a `league`.
         * @summary Get a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
         * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
         * @param {boolean} [includeContractInfo] Roster will also contain contract information
         * @param {boolean} [includeRosterInfo] Populate team\&#39;s rosters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeague(leagueId: string, quickView?: boolean, username?: string, includeContractInfo?: boolean, includeRosterInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<League>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeague(leagueId, quickView, username, includeContractInfo, includeRosterInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `league` entities.
         * @summary List All leagues
         * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
         * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagues(quickView?: boolean, username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<League>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagues(quickView, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the schedule for the given `league` for the given season year
         * @param {string} [leagueId] A unique identifier for a league
         * @param {number} [seasonYear] The season for which the schedule should be loaded
         * @param {number} [week] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleData(leagueId?: string, seasonYear?: number, week?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeagueGame>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleData(leagueId, seasonYear, week, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Randomizes the `league`\'s current season schedule based on the current season settings
         * @param {string} [leagueId] A unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async randomizeSchedule(leagueId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.randomizeSchedule(leagueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing `league`.
         * @summary Update a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {League} league Updated &#x60;league&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLeague(leagueId: string, league: League, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLeague(leagueId, league, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the schedule for the given `league` for the given season year
         * @param {Array<LeagueGame>} leagueGame Information about the scheduled matchups to be updated
         * @param {string} [leagueId] A unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScheduleData(leagueGame: Array<LeagueGame>, leagueId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScheduleData(leagueGame, leagueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeagueApi - factory interface
 * @export
 */
export const LeagueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueApiFp(configuration)
    return {
        /**
         * Creates a new instance of a `league`.
         * @summary Create a league
         * @param {LeagueCreator} leagueCreator A new &#x60;league&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeague(leagueCreator: LeagueCreator, options?: any): AxiosPromise<string> {
            return localVarFp.createLeague(leagueCreator, options).then((request) => request(axios, basePath));
        },
        /**
         * Permanently deletes an existing `league`.
         * @summary Delete a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeague(leagueId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeague(leagueId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the details of a single instance of a `league`.
         * @summary Get a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
         * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
         * @param {boolean} [includeContractInfo] Roster will also contain contract information
         * @param {boolean} [includeRosterInfo] Populate team\&#39;s rosters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeague(leagueId: string, quickView?: boolean, username?: string, includeContractInfo?: boolean, includeRosterInfo?: boolean, options?: any): AxiosPromise<League> {
            return localVarFp.getLeague(leagueId, quickView, username, includeContractInfo, includeRosterInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `league` entities.
         * @summary List All leagues
         * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
         * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagues(quickView?: boolean, username?: string, options?: any): AxiosPromise<Array<League>> {
            return localVarFp.getLeagues(quickView, username, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the schedule for the given `league` for the given season year
         * @param {string} [leagueId] A unique identifier for a league
         * @param {number} [seasonYear] The season for which the schedule should be loaded
         * @param {number} [week] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleData(leagueId?: string, seasonYear?: number, week?: number, options?: any): AxiosPromise<Array<LeagueGame>> {
            return localVarFp.getScheduleData(leagueId, seasonYear, week, options).then((request) => request(axios, basePath));
        },
        /**
         * Randomizes the `league`\'s current season schedule based on the current season settings
         * @param {string} [leagueId] A unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomizeSchedule(leagueId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.randomizeSchedule(leagueId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing `league`.
         * @summary Update a league
         * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
         * @param {League} league Updated &#x60;league&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLeague(leagueId: string, league: League, options?: any): AxiosPromise<void> {
            return localVarFp.updateLeague(leagueId, league, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the schedule for the given `league` for the given season year
         * @param {Array<LeagueGame>} leagueGame Information about the scheduled matchups to be updated
         * @param {string} [leagueId] A unique identifier for a league
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScheduleData(leagueGame: Array<LeagueGame>, leagueId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateScheduleData(leagueGame, leagueId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueApi - object-oriented interface
 * @export
 * @class LeagueApi
 * @extends {BaseAPI}
 */
export class LeagueApi extends BaseAPI {
    /**
     * Creates a new instance of a `league`.
     * @summary Create a league
     * @param {LeagueCreator} leagueCreator A new &#x60;league&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public createLeague(leagueCreator: LeagueCreator, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).createLeague(leagueCreator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permanently deletes an existing `league`.
     * @summary Delete a league
     * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public deleteLeague(leagueId: string, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).deleteLeague(leagueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the details of a single instance of a `league`.
     * @summary Get a league
     * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
     * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
     * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
     * @param {boolean} [includeContractInfo] Roster will also contain contract information
     * @param {boolean} [includeRosterInfo] Populate team\&#39;s rosters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getLeague(leagueId: string, quickView?: boolean, username?: string, includeContractInfo?: boolean, includeRosterInfo?: boolean, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getLeague(leagueId, quickView, username, includeContractInfo, includeRosterInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `league` entities.
     * @summary List All leagues
     * @param {boolean} [quickView] If true will return minimum leage data. Used for things like the front-end
     * @param {string} [username] Provide user information. Currently ignored unless you are using the quick view funcitonaly 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getLeagues(quickView?: boolean, username?: string, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getLeagues(quickView, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the schedule for the given `league` for the given season year
     * @param {string} [leagueId] A unique identifier for a league
     * @param {number} [seasonYear] The season for which the schedule should be loaded
     * @param {number} [week] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getScheduleData(leagueId?: string, seasonYear?: number, week?: number, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getScheduleData(leagueId, seasonYear, week, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Randomizes the `league`\'s current season schedule based on the current season settings
     * @param {string} [leagueId] A unique identifier for a league
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public randomizeSchedule(leagueId?: string, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).randomizeSchedule(leagueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing `league`.
     * @summary Update a league
     * @param {string} leagueId A unique identifier for a &#x60;league&#x60;.
     * @param {League} league Updated &#x60;league&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public updateLeague(leagueId: string, league: League, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).updateLeague(leagueId, league, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the schedule for the given `league` for the given season year
     * @param {Array<LeagueGame>} leagueGame Information about the scheduled matchups to be updated
     * @param {string} [leagueId] A unique identifier for a league
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public updateScheduleData(leagueGame: Array<LeagueGame>, leagueId?: string, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).updateScheduleData(leagueGame, leagueId, options).then((request) => request(this.axios, this.basePath));
    }
}
