import * as React from 'react'

function EventCountdown({dateTime}) {
  
  const [timeRemaining, setTimeRemaining] = React.useState(0);
  const [days, setDays] = React.useState(0)
  const [hours, setHours] = React.useState(0)
  const [minutes, setMinutes] = React.useState(0)
  const [seconds, setSeconds] = React.useState(0)

  const getTimeRemaining = () => {
	const time = Date.parse(dateTime) - Date.parse(new Date());
	setTimeRemaining(time);
    setSeconds(Math.floor((time/1000) % 60));
    setMinutes(Math.floor((time/1000/60) % 60));
    setHours(Math.floor((time/(1000*60*60)% 24)));
    setDays(Math.floor((time/(1000*60*60*24))));
  }

  React.useEffect(() => {
	getTimeRemaining();
  });

  React.useEffect(() => {
    setInterval(() => getTimeRemaining(), 1000)
  }, [dateTime]);

  if (timeRemaining < 0) {
	return <span>Now!</span>
  }
  return <span>in: {days} days, {hours} hours, {minutes} minutes, {seconds} seconds</span>;
}

export default EventCountdown;
