import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { DraftPick, Team } from '../../../sdk/model';
import { Typography, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import {getNumberOrdinal} from '../../util/DraftPickDisplay'
import { draftApi } from '../../../adapters/APIExporter';
import EventActivityDisplay from './EventActivityDisplay';

interface draftPickSelectionDisplayProps {
  pick: DraftPick,
  teamMap: Map<string | undefined, Team>
}

function DraftPickSelectionDisplay(props: draftPickSelectionDisplayProps) {
  const navigate = useNavigate();
  
  const [history, setHistory] = React.useState(null);
  
  const pickDateTime = new Date(props.pick.updatedTimeStamp);
  const draftYear = pickDateTime.getFullYear();
  
  function loadPickHistory() {
    draftApi.getDraftPickHistory(props.pick.id).then(response => setHistory(response.data))
  }
  
  return <Box>
    {props.teamMap.get(props.pick.teamId)!.name} selected with the {getNumberOrdinal(props.pick.pickNumber)} pick of the {getNumberOrdinal(props.pick.roundNumber)} round of the 
    <Button onClick={() => navigate('/league/' + props.pick.leagueId + '/draftHistory?seasonYear=' + draftYear)}>{draftYear} Rookie Draft</Button>
    <Button onClick={loadPickHistory}>({props.teamMap.get(props.pick.originalTeamId)!.name}'s Pick)</Button>
    {history && (history.length ? <Box>
      <Typography variant='h5'>Draft Pick Transaction History:</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{fontSize : 20}}>
            <TableRow>
              <TableCell>Date/Time</TableCell>
              <TableCell>Transaction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((eventActivity, eventIndex) => 
              <EventActivityDisplay key={eventIndex} activity={eventActivity} teamMap={props.teamMap} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box> : <p>No Transaction History for Pick</p>)}
  </Box>
}

export default DraftPickSelectionDisplay;
