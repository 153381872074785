import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League } from '../../../sdk/model';
import { Modal, Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, Button } from '@mui/material';
import { displayDraftPick } from '../../util/DraftPickDisplay';
import DraftPickCard from '../../cards/draftPickCard';

function LeagueDraftPicks() {
  const {leagueId} = useParams();
  
  const user = useAppSelector((state) => state.user);
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [viewingPick, setViewingPick] = React.useState(undefined);
  
  // League Information
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicAuctionData: false, 
    loadFullAuctionData: false, loadContractData: true, loadRosterData: true,
    setLoadComplete: setLoadComplete, isLoadComplete: isLoadComplete,
    setIsException: setIsException, isException: isException});
    
  if (!isLoadComplete) {
    return <p>Loading...</p>
  }
  
  const teamMap = new Map(league?.teams.map(team => [team.id, team]));
  const draftPickSeasons = Array.from(new Set(league?.teams.flatMap(team => team.draftPicks?.map(pick => pick.seasonYear))));
  
  return <Box>
    <Modal open={viewingPick} onClose={() => setViewingPick(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <DraftPickCard pick={viewingPick} leagueId={league?.id} />
    </Modal>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Draft Picks
    </Typography>
    <TableContainer className="centered" component={Paper}><Table>
      <TableHead><TableRow>
        <TableCell>Team</TableCell>
        {draftPickSeasons.map(season => <TableCell key={season}>{season}</TableCell>)}
      </TableRow></TableHead>
      <TableBody>
        {league?.teams.map(team => <TableRow key={team.id}>
          <TableCell>{team.name}</TableCell>
          {draftPickSeasons.map(season => {
            const picksForSeason = team.draftPicks!.filter(pick => pick.seasonYear === season);
            return <TableCell key={season}>
              {picksForSeason.length ?
                picksForSeason.map(pick => {
                  const originalTeam = teamMap.get(pick.originalTeamId);
                  return <p><Button onClick={() => setViewingPick(pick)}>{displayDraftPick(pick, originalTeam)}</Button></p>
                }) : "No picks"
              }
            </TableCell>
          })}
        </TableRow>)}
      </TableBody>
    </Table></TableContainer>
  </Box>
}

export default LeagueDraftPicks;