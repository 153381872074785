import * as React from 'react';
import { Player } from '../../sdk/model';
import { Modal, Button } from '@mui/material';
import PlayerInfoCard from './playerCard';

interface playerCardLinkProps {
  player: Player | undefined,
  leagueId: string,
  children
}

// TODO: Deal with an undefined player
function PlayerCardLink(props: playerCardLinkProps) {
  const [viewingPlayer, setViewingPlayer] = React.useState<Player | undefined>(undefined);
  return <React.Fragment>
    <Modal open={viewingPlayer !== undefined} onClose={() => setViewingPlayer(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <PlayerInfoCard player={viewingPlayer} leagueId={props.leagueId}/>
    </Modal>
    <Button onClick={() => setViewingPlayer(props.player)}>{props.children}</Button>
  </React.Fragment>
}

export default PlayerCardLink;

