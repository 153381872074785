import * as React from 'react';
import { League, LeagueSeason, TeamSeason, LeagueGame, Team } from '../../../sdk/model';
import { leagueApi } from '../../../adapters/APIExporter';
import { Box } from '@mui/material';
import TeamDisplay from '../team/TeamDisplay';
import AllTeamsForSeason from '../team/AllTeamsForSeason';
import AllTeamsSchedule from './AllTeamsSchedule';
import PlayoffBracket from './PlayoffBracket';

interface seasonReportProps {
  league: League,
  leagueSeason: LeagueSeason,
  teamSeasons: TeamSeason[],
}

function SeasonReport(props: seasonReportProps) {
  const [schedule, setSchedule] = React.useState<LeagueGame[]>([]);
  
  React.useEffect(() => {
    leagueApi.getScheduleData(props.league.id!, props.leagueSeason.seasonYear).then(response => {
      setSchedule(response.data);
    });
  }, []);
  
  function getGamesByWeek(games : LeagueGame[]) {
    return games.reduce((partialResults, game) => {
      partialResults[game.weekNumber!] = partialResults[game.weekNumber!] || [];
      partialResults[game.weekNumber!].push(game);
      return partialResults;
    }, {})
  }
 
  const regularSeasonGamesByWeek = getGamesByWeek(schedule!.filter(game => props.leagueSeason.finalWeekOfRegularSeason! >= game.weekNumber!));
  const playoffGamesByWeek = getGamesByWeek(schedule!.filter(game => props.leagueSeason.finalWeekOfRegularSeason! < game.weekNumber!));
  const teams : Team[] = props.teamSeasons.map(teamSeason => {
    return {
      id: teamSeason.teamId,
      name: teamSeason.teamName,
      currentSeason: teamSeason,
    }
  })
  
  const teamMap = new Map(teams.map(team => [team.id, team]));
  
  function displayTeam(teamId) {
    const team = teamMap.get(teamId)!;
    return <TeamDisplay leagueId={props.league.id!} team={team} emphasized={false} />
  }
  
  return <Box>
    <AllTeamsForSeason league={props.league} leagueSeason={props.leagueSeason} 
        teamSeasons={props.teamSeasons} joinEnabled={false} showWaiverPriority={false} />
    <AllTeamsSchedule leagueId={props.league.id!} gamesByWeek={regularSeasonGamesByWeek} displayTeam={displayTeam} teams={teams} editMode={false} editSchedule={null} />
    <PlayoffBracket leagueId={props.league.id!} gamesByWeek={playoffGamesByWeek} displayTeam={displayTeam} teams={teams} finalWeekOfRegularSeason={props.leagueSeason.finalWeekOfRegularSeason!} playoffRoundConfig={props.leagueSeason.playoffRoundConfig!} />
  </Box>
}

export default SeasonReport;
