import { Properties } from '../Properties'
import axios from 'axios'

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "JavaScript";

export const getUserInfo = () => axios.get(Properties.backendUrl + '/service/userinfo');
export const authenticateUrl = Properties.backendUrl + '/service/callback';
export const authenticatedCallHelper = (serviceCall, successFunction, errorFunction) => serviceCall()
		.then((resp) => successFunction(resp))
		.catch((err) => {
	    	if (err.response && err.response.status == 499) {
							console.log("Redirecting to " + authenticateUrl);
	            window.location.assign(authenticateUrl);
	        } else if (errorFunction) {
	        	errorFunction(err);
	        }
		});
export const logIn = (successFunction) => authenticatedCallHelper(getUserInfo, successFunction);
export const logOut = (successFunction) => authenticatedCallHelper(() => axios.post(Properties.backendUrl + '/service/logout'), successFunction);
