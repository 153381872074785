import * as React from 'react'
import { League, Player, Team, TeamPlayerInfo, ContractStatus, RosterPositionEnum, PlayerPositionEnum, LeaguePhase, TagType, Contract } from '../../../sdk/model';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import {Box, Typography, FormControlLabel, RadioGroup, Radio, IconButton, Avatar, Button} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayerCardLink from '../../cards/playerCardLink';
import { getCurrentSalaryAsNumber, getFinalSeasonOfContract, getFinalSeasonOfContractFromContract, toSalary } from '../../util/SalaryDisplay';
import { teamApi } from '../../../adapters/APIExporter';
import DropPlayerButton from './DropPlayerButton';
import FranchiseTagButton from './FranchiseTagButton';
import RookieOptionTagButton from './RookieOptionTagButton';
import RemoveTagButton from './RemoveTagButton';
import FinalizeTagButton from './FinalizeTagButton';
import {getNumberOrdinal} from '../../util/DraftPickDisplay';
import { positionOrder } from '../../util/PositionOrder';
import playerMatchupDisplay from '../../util/PlayerMatchupDisplay';
import {isPlayerLocked} from '../../util/PlayerDataUtil';
import LockIcon from '@mui/icons-material/Lock';

const ROSTER_SPOTS_FOR_POSITION : Map<PlayerPositionEnum, RosterPositionEnum[]> = new Map([
  [PlayerPositionEnum.Qb, [RosterPositionEnum.Qb, RosterPositionEnum.Sflex, RosterPositionEnum.Bn]],
  [PlayerPositionEnum.Rb, [RosterPositionEnum.Rb, RosterPositionEnum.Sflex, RosterPositionEnum.Flex, RosterPositionEnum.Bn]],
  [PlayerPositionEnum.Wr, [RosterPositionEnum.Wr, RosterPositionEnum.Sflex, RosterPositionEnum.Flex, RosterPositionEnum.Bn]],
  [PlayerPositionEnum.Te, [RosterPositionEnum.Te, RosterPositionEnum.Sflex, RosterPositionEnum.Flex, RosterPositionEnum.Bn]],
]);

function canMove(player, position) {
  return player && ROSTER_SPOTS_FOR_POSITION.get(player.position)!.includes(position);
}

enum SwapOption {
  SWAP, 
  MOVE_TO, 
  MOVE_FROM, 
  NONE,
}

function getSwapOption(position1Info, position2Info, roster, playerMap) {
  const position1 = position1Info[0];
  const player1Index = position1Info[1];
  const player1Info = roster[position1][player1Index];
  const player1 = player1Info ? playerMap.get(player1Info.playerId)! : null;
  const position2 = position2Info[0];
  const player2Index = position2Info[1];
  const player2Info = roster[position2][player2Index];
  const player2 = player2Info ? playerMap.get(player2Info.playerId)! : null;
  if (position1 !== RosterPositionEnum.Bn || position2 !== RosterPositionEnum.Bn) {
    if (canMove(player1, position2)) {
      if (canMove(player2, position1)) {
        return SwapOption.SWAP;
      }
      return SwapOption.MOVE_TO;
    } else if (position1 !== RosterPositionEnum.Bn && canMove(player2, position1)) {
      return SwapOption.MOVE_FROM;
    }
  }
  return SwapOption.NONE;
}

const SWAP_BUTTON_MAP = new Map([
  [SwapOption.SWAP, <SwapHorizIcon />],
  [SwapOption.MOVE_TO, <ArrowForwardIcon />],
  [SwapOption.MOVE_FROM, <ArrowBackIcon />]
]);

interface ContractType {
  display: string,
  details: string
}

const TAG_TYPE_INFO_MAPPER : Map<TagType, Function> = new Map([
  [TagType.Franchise, (contract : Contract, draft : boolean) => {
    let consecutiveTagNumber = contract.consecutiveTagNumber!;
    if (draft) {
      consecutiveTagNumber++;
    }
    return {
      display: (draft ? "*" : "") + 'F' + consecutiveTagNumber,
      details: getNumberOrdinal(contract.consecutiveTagNumber!) + " Consecutive Franchise Tag" + (draft ? " - NOT FINALIZED" : "")
    };
  }],
  [TagType.RookieOption, (contract : Contract, draft : boolean) => {
    return {
      display: (draft ? "*" : "") + "5",
      details: "5th-Year Rookie Option" + (draft ? " - NOT FINALIZED" : "")
    };
  }]
]);

const ACTIVE_LEAGUE_PHASES = new Set([LeaguePhase.RegularSeason, LeaguePhase.Playoffs]);

interface teamRosterProps {
  leagueId: string,
  team: Team,
  playerMap: Map<string | undefined, Player | undefined>,
  currentSeasonYear: number,
  rosterSettings: Map<string | undefined, number | undefined>,
  myTeam: boolean
}

function TeamRoster(props: teamRosterProps){
  const [selectedPosition, setSelectedPosition] = React.useState<string | null>(null);
  const [roster, setRoster] = React.useState(props.team.playerRoster);
  const [errors, setErrors] = React.useState<string[]>([]);
  
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  React.useEffect(() => {
    setRoster(props.team.playerRoster);
  }, [props.team.playerRoster])
  
  function getPlayerInfo(rosterPosition) {
    if (!rosterPosition) {
      return null;
    }
    const positionInfo = rosterPosition?.split('-')!;
    const position = positionInfo[0];
    const playerIndex = positionInfo[1];
    return roster![position][playerIndex];
  }
  
  function getContractTypeInfo(playerInfo : TeamPlayerInfo) {
    const info : ContractType[] = []
    if (!playerInfo) {
      return info;
    }
    if (playerInfo.contract?.rookieContract) {
      info.push({
        display: 'R' + playerInfo.contract?.rookieDraftRound!,
        details: getNumberOrdinal(playerInfo.contract?.rookieDraftRound!) + " Round Rookie Pick"
      });
    }
    if (playerInfo.contract?.markedTagForNextContract) {
      info.push(TAG_TYPE_INFO_MAPPER.get(playerInfo.contract?.markedTagForNextContract)!(playerInfo.contract!, true))
    }
    if (playerInfo.contract?.tag) {
      info.push(TAG_TYPE_INFO_MAPPER.get(playerInfo.contract?.tag)!(playerInfo.contract!, false))
    }
    return info;
  }
  
  const selectedPlayerInfo = getPlayerInfo(selectedPosition);
  const selectedPlayer = selectedPlayerInfo ? props.playerMap.get(selectedPlayerInfo.playerId)! : null;
  
  function rosterSpotToRowData(rosterPosition, playerInfo, playerPositionIndex) {
    return {
      id: rosterPosition + '-' + playerPositionIndex,
      contractTypes: getContractTypeInfo(playerInfo),
      position: rosterPosition,
      player: playerInfo ? props.playerMap.get(playerInfo.playerId) : null,
      previousYearSalary: playerInfo ? getCurrentSalaryAsNumber(playerInfo, props.currentSeasonYear - 1) : 0,
      currentYearSalary: playerInfo ? getCurrentSalaryAsNumber(playerInfo, props.currentSeasonYear) : 0,
      nextYearSalary: playerInfo ? getCurrentSalaryAsNumber(playerInfo, props.currentSeasonYear + 1) : 0,
      finalYearOfContract: playerInfo ? getFinalSeasonOfContract(playerInfo) : null
    }
  }
  function setErrorsAndFocus(errors) {
    setErrors(errors);
    document.getElementById("errors")!.focus();
  }
  function swapSpots(otherPosition) {
    const position1Info = selectedPosition?.split('-')!;
    const position2Info = otherPosition?.split('-')!;
    const payload = {
      team1Position: position1Info[0],
      team1Index: position1Info[1],
      team2Position: position2Info[0],
      team2Index: position2Info[1]
    }
    teamApi.swapRosterPositions(props.team.id, payload).then(response => {
      setRoster(response.data);
      setSelectedPosition(null);
    }).catch(error => {
      let errors = [error.response.data.message];
      setErrorsAndFocus(errors);
    });
  }
  
  function handleActionResponse(type, message) {
    if (type === 'error') {
      setErrorsAndFocus([message]);
    } else {
      setErrors([]);
      setSelectedPosition(null);
    }
  }
  
  let startersColumns = 
    [
      {
        accessorKey: 'id',
        id: 'select',
        header: 'Select',
        minSize: 15,
        size: 15,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          if (isPlayerLocked(row.original.player)) {
            return <LockIcon />
          } 
          if (selectedPosition && selectedPosition !== row.id) {
            const position1Info = selectedPosition.split('-');
            const position2Info = row.id.split('-');
            const swapOption = getSwapOption(position1Info, position2Info, roster, props.playerMap);
            const swapButton = SWAP_BUTTON_MAP.get(swapOption);
            if (swapButton) {
              return <IconButton onClick={() => swapSpots(row.id)}>
                {swapButton}
              </IconButton>
            }
            return null;
          }
         
          return <FormControlLabel value={renderedCellValue} control={<Radio />} label="" onClick={() => {
            if (selectedPosition) {
              setSelectedPosition(null);
            }
          }} />
        }
      },
      {
        accessorKey: 'contractTypes',
        id: 'contractTypes',
        header: 'Contract Tags',
        minSize: 15,
        size: 15,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => 
          renderedCellValue.map(contractType => 
            <Avatar title={contractType.details} sx={{display: 'inline-flex', marginRight: 1}}>{contractType.display}</Avatar>
          )
      },
      {
        accessorKey: 'position',
        id: 'position',
        header: 'Position',
        minSize: 15,
        size: 15
      },
      {
        accessorKey: 'player',
        id: 'player',
        header: 'Player',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => {
          const player = renderedCellValue;
          return player ? <PlayerCardLink player={player} leagueId={props.leagueId}>{player.abbr_name} - {player.position}</PlayerCardLink> : 'Empty';
        },
        sortingFn: (rowA, rowB) => {
          const playerA = rowA.getValue("player");
          const playerB = rowB.getValue("player");
          if (playerA) {
            if (playerB) {
              return positionOrder.indexOf(playerB.position) - positionOrder.indexOf(playerA.position);
            }
            return 1;
          } 
          if (playerB) {
            return -1
          }
          return 0;
        }
      },
      {
        accessorKey: 'player',
        id: 'currentMatchup',
        header: 'Current Matchup',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue }) => {
          const player : Player | undefined = renderedCellValue;
          return player ? playerMatchupDisplay(player.quick_stats?.currentWeeksStats) : 'N/A'
        }
      },
      {
        accessorKey: 'previousYearSalary',
        id: 'previousYearSalary',
        header: (props.currentSeasonYear - 1) + ' Salary',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => renderedCellValue ? toSalary(renderedCellValue) : '-'
      },
      {
        accessorKey: 'currentYearSalary',
        id: 'currentYearSalary',
        header: props.currentSeasonYear + ' Salary',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => renderedCellValue ? toSalary(renderedCellValue) : '-'
      },
      {
        accessorKey: 'nextYearSalary',
        id: 'nextYearSalary',
        header: (props.currentSeasonYear + 1) + ' Salary',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => renderedCellValue ? toSalary(renderedCellValue) : '-'
      },
      {
        accessorKey: 'finalYearOfContract',
        id: 'finalYearOfContract',
        header: 'Final Year of Contract',
        minSize: 15,
        size: 15,
        Cell: ({renderedCellValue, row }) => renderedCellValue || '-'
      }
    ];
  if (!props.myTeam) {
    startersColumns = startersColumns.slice(1);
  }
  const starterData = positionOrder
    .filter(rosterPosition => RosterPositionEnum.Bn !== rosterPosition && roster![rosterPosition])
    .flatMap(rosterPosition => 
      roster![rosterPosition].map((playerInfo, playerPositionIndex) => rosterSpotToRowData(rosterPosition, playerInfo, playerPositionIndex))
    );
  const benchColumns = [...startersColumns].filter(column => column.id !== 'position');
  let benchData = roster![RosterPositionEnum.Bn].map((playerInfo, playerPositionIndex) => rosterSpotToRowData(RosterPositionEnum.Bn, playerInfo, playerPositionIndex));
  if (selectedPosition) {
    const selectedPositionInfo = selectedPosition.split('-');
    benchData = benchData.filter(benchRow => {
      if (selectedPosition === benchRow.id) {
        return true;
      }
      const position2Info = benchRow.id.split('-');
      return new Set([SwapOption.SWAP, SwapOption.MOVE_FROM]).has(getSwapOption(selectedPositionInfo, position2Info, roster!, props.playerMap));
    })
    const selectedPos = selectedPositionInfo[0];
    if (selectedPlayer && selectedPos !== RosterPositionEnum.Bn) {
      benchData.push(rosterSpotToRowData(RosterPositionEnum.Bn, null, roster![RosterPositionEnum.Bn].length));
    }
  } else {
    const emptySpotCount = props!.rosterSettings![RosterPositionEnum.Bn] - benchData.length;
    if (emptySpotCount > 0) {
      [...Array(emptySpotCount).keys()].map(index => index + benchData.length).forEach(index => benchData.push(rosterSpotToRowData(RosterPositionEnum.Bn, null, index)));
    }
  }
  function getActionButtons() {
    if(selectedPlayer) {
      if (ContractStatus.Expired === selectedPlayerInfo?.contract?.status) {
        if (props.team.currentSeason?.franchiseTagsUsed! < 1) {
          return <FranchiseTagButton leagueId={props.leagueId} player={selectedPlayer!} contract={selectedPlayerInfo?.contract!} currentSeasonYear={props.currentSeasonYear} team={props.team} callback={handleActionResponse} />
        }
        if (selectedPlayerInfo?.contract?.markedTagForNextContract) {
          return <React.Fragment>
            <RemoveTagButton league={league!} player={selectedPlayer!} tagType={TagType.Franchise} callback={handleActionResponse} />
            <FinalizeTagButton league={league!} player={selectedPlayer!} tagType={TagType.Franchise} callback={handleActionResponse} />
          </React.Fragment>
        }
        return undefined;
      }
      return <React.Fragment>
        {getFinalSeasonOfContractFromContract(selectedPlayerInfo?.contract!) === league?.currentSeasonYear && selectedPlayerInfo?.contract?.rookieDraftRound === 1 &&
          (selectedPlayerInfo?.contract?.markedTagForNextContract ? 
            <RemoveTagButton league={league!} player={selectedPlayer!} tagType={TagType.RookieOption} callback={handleActionResponse} /> :
            <RookieOptionTagButton leagueId={props.leagueId} player={selectedPlayer!} contract={selectedPlayerInfo?.contract!} currentSeasonYear={props.currentSeasonYear} team={props.team} callback={handleActionResponse} />
          )
        }
        <DropPlayerButton leagueId={props.leagueId} player={selectedPlayer!} contract={selectedPlayerInfo?.contract!} currentSeasonYear={props.currentSeasonYear} team={props.team}
          callback={newRoster => {
            setRoster(newRoster);
            setSelectedPosition(null);
          }} 
        />
      </React.Fragment>
    }
    return undefined;
  }
  
  const actionButtons = getActionButtons()
  
  const columnVisibility = {
    previousYearSalary : league?.phase === LeaguePhase.Offseason,
    nextYearSalary: false,
    currentMatchup: ACTIVE_LEAGUE_PHASES.has(league?.phase)
  }
  
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  
  return <Box>
    <Box tabIndex="0" id="errors">{errorInfo}</Box>
    {actionButtons}
    <RadioGroup value={selectedPosition} name="selectedPosition" sx={{display: 'block'}} onChange={(_event, newValue) => setSelectedPosition(newValue)}>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Starters:
      </Typography>
      <MaterialReactTable enableRowSelection={false} enableMultiRowSelection={false} 
        getRowId={(row) => row.id!} columns={startersColumns} 
        data={starterData} 
        enableRowOrdering={false} enableSorting={false} enableFilters={false} enablePagination={false}
        initialState={{
          columnVisibility: columnVisibility  
        }}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Labels": {
              padding: "0px",
            },
            "& .MuiBox-root": {
              padding: "0px",
            },
          },
        }}
        muiTableBodyRowProps={({ table, row }) => {
          if (selectedPosition && selectedPosition !== row.id) {
            const position1Info = selectedPosition.split('-');
            const position2Info = row.id.split('-');
            if (getSwapOption(position1Info, position2Info, roster!, props.playerMap) === SwapOption.NONE) {
              return {
                sx: {
                  opacity: 0.1
                }
              }
            }
          }
          return {sx: {}}
        }}
        
  
      />
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Bench:
      </Typography>
      <MaterialReactTable enableRowSelection={false} enableMultiRowSelection={false} 
        getRowId={(row) => row.id!} columns={benchColumns} 
        data={benchData} 
        enableRowOrdering={false} enableSorting={true} enablePagination={false}
        muiTableHeadCellProps={{
          sx: {
            "& .Mui-TableHeadCell-Content-Labels": {
              padding: "0px",
            },
            "& .MuiBox-root": {
              padding: "0px",
            },
          },
        }}
        initialState={{
          sorting: [
            {
              id: 'player',
              desc: true,
            },
            {
              id: 'currentYearSalary',
              desc: true,
            },
          ],
          columnVisibility: columnVisibility
        }}
      />
    </RadioGroup>
    {actionButtons}
  </Box>
}

export default TeamRoster;
