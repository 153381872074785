import * as React from 'react';
import { TeamPlayerClaims, Player } from '../../../sdk/model';
import {Box, Typography, IconButton} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import ClearIcon from '@mui/icons-material/Clear';
import PlayerCardLink from '../../cards/playerCardLink';
import { playerClaimApi } from '../../../adapters/APIExporter';
import { toSalary } from '../../util/SalaryDisplay';

interface faabClaimsViewProps {
  playerClaims: TeamPlayerClaims,
  playerMap: Map<string | undefined, Player | undefined>,
  leagueId: string,
  setNewTeamPlayerClaims: Function
}

function FaabClaimsView(props: faabClaimsViewProps) {
  const columns = 
    [
      {
        accessorKey: 'priority',
        id: 'priority',
        header: 'Priority',
        minSize: 15,
        size: 15
      },
      {
        accessorKey: 'playerId',
        id: 'claimedPlayer',
        header: 'Claimed Player',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => {
          const player = props.playerMap.get(renderedCellValue)!;
          return <PlayerCardLink player={player} leagueId={props.leagueId}>{player.abbr_name}</PlayerCardLink>
        }
      },
      {
        accessorKey: 'bidAmount',
        id: 'bidAmount',
        header: 'Bid (Full-Season Value)',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => toSalary(renderedCellValue)
      },
      {
        accessorKey: 'dropPlayerIds',
        id: 'dropPlayers',
        header: 'Player(s) to be dropped',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => {
          if (!renderedCellValue.length) {
            return 'None';
          }
          const players = renderedCellValue.map(playerId => props.playerMap.get(playerId)!);
          return players.map(player => <p key={player.id}>
            <PlayerCardLink  player={player} leagueId={props.leagueId}>{player.abbr_name}</PlayerCardLink>
          </p>)
        }
      },
      {
        accessorKey: 'priority',
        id: 'actions',
        header: 'Actions',
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue, row }) => <IconButton onClick={() => {
              var newPlayerClaims = {...props.playerClaims};
              var newFaabClaims = [...props.playerClaims.faabClaims!];
              newFaabClaims.splice(row.index, 1);
              newPlayerClaims.faabClaims = newFaabClaims;
              playerClaimApi.updateTeamPlayerClaims(newPlayerClaims, newPlayerClaims.id);
              props.setNewTeamPlayerClaims(newPlayerClaims);
        }} title="Remove Claim">
          <ClearIcon />
        </IconButton>
      }
    ];
  const faabData = props.playerClaims.faabClaims?.map((claim, claimIndex) => {
    return {...claim, priority: claimIndex + 1};
  })!;
  if (faabData.length === 0) {
    return null;
  }
  return <Box>
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      FAAB Claims:
    </Typography>
    <MaterialReactTable enableRowSelection={false} enableMultiRowSelection={false} 
      getRowId={(row) => row.id!} columns={columns} 
      data={faabData} 
      enableRowOrdering={true} enableRowSorting={false}
      muiTableHeadCellProps={{
        sx: {
          "& .Mui-TableHeadCell-Content-Labels": {
            padding: "0px",
          },
          "& .MuiBox-root": {
            padding: "0px",
          },
        },
      }}
      muiTableBodyRowProps={({ table, row }) => {
        const { draggingRow } = table.getState();
        if (draggingRow) {
          const claim1 = props.playerClaims.faabClaims![draggingRow.index];
          const claim2 = props.playerClaims.faabClaims![row.index];
          if (claim1.bidAmount !== claim2.bidAmount) {
            return {
              sx: {
                opacity: 0
              }
            }
          }
        }
        return {}
      }}
      muiTableBodyRowDragHandleProps={({ table }) => ({
        onDragEnter: (event) => {
          const { draggingRow, hoveredRow } = table.getState();
          if (hoveredRow && draggingRow) {
            const claim1 = props.playerClaims.faabClaims![draggingRow.index];
            const claim2 = props.playerClaims.faabClaims![hoveredRow.index];
            if (claim1.bidAmount !== claim2.bidAmount) {
              return;
            }
          }
        },
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState();
          if (hoveredRow && draggingRow && draggingRow !== hoveredRow) {
            const claim1 = props.playerClaims.faabClaims![draggingRow.index];
            const claim2 = props.playerClaims.faabClaims![hoveredRow.index];
            if (claim1.bidAmount !== claim2.bidAmount) {
              return;
            }
            var newPlayerClaims = {...props.playerClaims};
            var newFaabClaims = [...props.playerClaims.faabClaims!];
            newFaabClaims.splice(
              hoveredRow.index,
              0,
              newFaabClaims.splice(draggingRow.index, 1)[0],
            );
            newPlayerClaims.faabClaims = newFaabClaims;
            playerClaimApi.updateTeamPlayerClaims(newPlayerClaims, newPlayerClaims.id);
            props.setNewTeamPlayerClaims(newPlayerClaims);
          }
        }
      })}

    />
  </Box>
}

export default FaabClaimsView;
