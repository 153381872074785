import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { tradeApi } from '../../../adapters/APIExporter';
import { useLoadLeagueData, useLoadTradeData } from '../../../app/dataLoaderHooks';
import { useAppSelector } from '../../../app/hooks';
import { League, Team, DraftPick, TeamPlayerInfo, TradeProposal, TradeProposalPlayer, TradeProposalPick } from '../../../sdk/model';
import { findDefaultTeam } from '../draft/service';
import {Box, Typography, Button} from '@mui/material';
import TradeTeamViewer from './TradeTeamViewer';
import TradeDetails from './TradeDetails';
import { getNewSalary, getSalaryForSeason } from '../../util/SalaryDisplay';
import { getRosterSize } from '../../util/TeamUtils';

function DropForTrade() {
  const navigate = useNavigate();
  const {leagueId, tradeId} = useParams();
  const [errors, setErrors] = React.useState([]);
  
  const [trade, setTrade] = React.useState(null);
  const [isTradeLoadComplete, setTradeLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [allInvolvedTeamIds, setAllInvolvedTeamIds] = React.useState([]);
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: allInvolvedTeamIds,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));                                           
  const myTeam = league?.teams ? teamMap.get(findDefaultTeam(league?.teams, user?.id)) : undefined;
  
  const trades : Array<TradeProposal> = useLoadTradeData({teamId: myTeam?.id!, setIsException: setIsException, isLoadComplete: isTradeLoadComplete, setLoadComplete: setTradeLoadComplete});
  
  React.useEffect(() => {
    if (isTradeLoadComplete) {
      const thisTrade = trades.find(teamTrade => teamTrade.id === tradeId);
      if (thisTrade) {
        setTrade(thisTrade);
        setAllInvolvedTeamIds(thisTrade.teams.map(team => team.teamId));
      } else {
        setErrors(['Trade not found!']);
      }
    }
  }, [isTradeLoadComplete]);
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  if (!isLoadComplete || !trade || !myTeam?.rosterLoaded) {
    return <p>Loading...</p>
  }
  
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player?.id, player]));
  const pickMap : Map<string | undefined, DraftPick | undefined> = new Map(league?.teams?.filter(team => allInvolvedTeamIds.includes(team.id)).flatMap(team => 
    team?.draftPicks?.map(pick => [pick.id, pick])
  ));
  const teamPlayerMap : Map<string | undefined, TeamPlayerInfo | undefined> = new Map(league?.teams?.filter(team => allInvolvedTeamIds.includes(team.id)).flatMap(team => 
    Object.values(team.playerRoster).flatMap(playerList => 
      playerList.filter(player => player).map(player => [player.playerId, player])
    )
  ));
  
  const proposalPlayerMap: Map<string | undefined, TradeProposalPlayer | undefined> = new Map(trade.players.map(player => [player.playerId, player]));
  
  const allInvolvedTeams = trade.teams.map(team => teamMap.get(team.teamId));
  const rosterSize = getRosterSize(myTeam);
  const newRosterSize = trade.players.reduce((partialSum, tradePlayer) => partialSum + (tradePlayer.toTeamId === myTeam?.id ? 1 : (tradePlayer.fromTeamId === myTeam?.id ? -1 : 0)), rosterSize);
  const maxRosterSize = Object.values(league.rosterSettings).reduce((partialSum, numberOfSpots) => partialSum + numberOfSpots, 0);
  const newEmptyRosterSpots = maxRosterSize - newRosterSize;
  const overRosterLimit = newEmptyRosterSpots < 0;
  const currentSalary = getSalaryForSeason(myTeam!.paymentsBySeason![league.currentSeasonYear]);
  const newSalary = getNewSalary(myTeam!, teamPlayerMap, pickMap, currentSalary, trade.players, trade.picks, league.currentSeasonYear, 0, newEmptyRosterSpots, league?.currentSeason)
  const overCap = newSalary > league.currentSeason.nflSeason.salaryCap;
  
  function handleTradeProposalChange(playerId: string, fromTeamId: string, action: string) {
    const newTrade = {...trade};
    newTrade.players = [...newTrade.players];
    if (action === 'drop') {
      newTrade.players.push({
        playerId: playerId,
        action: 'DROP',
        fromTeamId: myTeam?.id
      })
    } else {
      const playerIndex = newTrade.players.map(player => player.playerId).indexOf(playerId);
      newTrade.players.splice(playerIndex, 1);
    }
    setTrade(newTrade);
  }
  
  function acceptTrade() {
    const droppedPlayers = trade.players
        .filter(player => player.action === 'DROP' && player.fromTeamId === myTeam?.id)
        .map(player => player.playerId);
    tradeApi.acceptTradeProposal(trade?.id, myTeam?.id, droppedPlayers).then(() => navigate("/league/" + league?.id));
  }
  
  return <Box>
    <TradeTeamViewer team={myTeam} currentSeason={league?.currentSeason?.seasonYear!}
            playerMap={playerMap} teamMap={teamMap} userId={user?.id} myTeam={true} 
            otherTeamIds={[]} handleTradeProposalChange={handleTradeProposalChange}
            proposalPlayerMap={proposalPlayerMap} proposalPickMap={undefined} />
    <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Full Details
    </Typography>
    <TradeDetails leagueId={leagueId!} teams={allInvolvedTeams} currentSeason={league?.currentSeasonYear} players={trade.players} picks={trade.picks} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={pickMap} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
    <Button color="primary" variant="contained" onClick={acceptTrade} disabled={overCap || overRosterLimit}>Accept Trade</Button>
  </Box>
}
  
export default DropForTrade;
