import * as React from 'react'
import { League, Player, TagType } from '../../../sdk/model';
import {Button} from '@mui/material';
import { contractApi } from '../../../adapters/APIExporter';
import { useUpdateLeagueData } from '../../../app/dataLoaderHooks';

interface removeTagButtonProps {
  league: League,
  player: Player,
  tagType: TagType
  callback: Function
}

function RemoveTagButton(props: removeTagButtonProps) {
  const [mutableLeague, setMutableLeague] = React.useState<League | undefined>(undefined);
  useUpdateLeagueData(mutableLeague);
  if (mutableLeague) {
    setMutableLeague(undefined);
    props.callback('success', mutableLeague);
  }
  function removeTag() {
    contractApi.removeTag(props.league.id, props.player.id, props.tagType).then(result => {
      const newLeague = {...props.league, teams : [...props.league.teams]};
      const teamIndex = newLeague.teams.findIndex(team => team.id === result.data.id);
      newLeague.teams[teamIndex] = result.data;
      setMutableLeague(newLeague);
    }).catch(error => {
      props.callback('error', error.response.data.message);
    })
  }
  return <Button color="primary" variant="contained" onClick={removeTag}>Remove Franchise Tag</Button>
}

export default RemoveTagButton;
