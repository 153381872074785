import * as React from 'react';
import { Auction, Team, Player, EventActivity } from '../../../../sdk/model';
import { Typography, Box, Button, Modal } from '@mui/material';
import PlayerInfoCard from '../../../cards/playerCard'
import { getColorByPosition } from '../../../util/ColorByPosition'
import { toSalary } from '../../../util/SalaryDisplay';

interface auctionBoardProps {
  auction: Auction | undefined,
  playerMap: Map<string | undefined, Player | undefined> | undefined,
  teams: Array<Team>
}

function AuctionBoard(props: auctionBoardProps) {
  const [viewingPlayer, setViewingPlayer] = React.useState(undefined);
  const signeesByTeam: Map<string, Array<EventActivity>> | undefined = props.auction?.activityHistory!
      .filter(activity => activity.type === "auction_pick")
      .reduce((map, pick) => {
        let teamList = map.get(pick.teamId);
        if (!teamList) {
          teamList = [];
          map.set(pick.teamId, teamList);
        }
        teamList.push(pick);
        return map;
      }, new Map());
  const maxSigneesOnTeam = signeesByTeam?.size === 0 ? 0 : Math.max(...Array.from(signeesByTeam?.values()).map(list => list.length));
  return <Box sx={{ display: 'inline-block', width: '100%', whiteSpace: 'nowrap', overflowX: 'auto'}}>
    <Modal open={viewingPlayer} onClose={() => setViewingPlayer(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <PlayerInfoCard player={viewingPlayer} leagueId={props.auction?.leagueId}/>
    </Modal>
    <Box sx={{ display: 'block' }}>
      {props.teams.map((team, teamIndex) => 
        <Box key={teamIndex} name sx={{ display: 'inline-block', width: 100 }}>
          <Typography variant="h5" component="div" color="text.secondary" sx={{fontSize: 14}} title={team.name} >
            {team.name!.length > 15 ? team.name.substring(0,12) + "..." : team.name}
          </Typography>
        </Box>
      )}
    </Box>
    {Array.from(Array(maxSigneesOnTeam).keys()).map(playerIndex =>
      <Box key={playerIndex} sx={{ display: 'block' }}>
        {props.teams.map((team, teamIndex) => {
          const activities = signeesByTeam?.get(team?.id!);
          if (!activities || activities.length <= playerIndex) {
            return <Box key={teamIndex} sx={{ display: 'inline-block', width: 100 }}></Box>;
          }
          const activity = signeesByTeam?.get(team?.id!)![playerIndex];
          const playerId = activity?.contract.playerId;
          const player = props.playerMap?.get(playerId);
          return <Box key={teamIndex} sx={{ display: 'inline-block' }}>
            <Button variant="contained" onClick={() => setViewingPlayer(player)} sx={{ width: 100, height: 75, overflow: 'hidden', ...getColorByPosition(player?.position)}}>
              <Box>
                <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 10, color : 'black' }}>
                  Pick {activity?.pickNumber}
                </Typography>
                <br/>
                <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 10, color : 'black' }}>
                  <b>{player?.position + ": " + player?.abbr_name}</b>
                </Typography>
                <br/>
                <Typography variant="body" component="span" color="text.secondary" sx={{ flexGrow: 1, fontSize: 10, color : 'black' }}>
                  {activity?.contract.numberOfSeasons}yrs/{toSalary(activity?.contract?.totalSalary)}
                </Typography>
              </Box>
            </Button>
          </Box>
        })}
      </Box>
    )}
  </Box>;
}

export default AuctionBoard;
