import * as React from 'react';
import { Player } from '../../../../sdk/model';
import { Box } from '@mui/material';
import { Typography, TextField, Select, MenuItem } from '@mui/material';
import AuctionBidButton from './AuctionBidButton';
import { ANNUAL_PERCENTAGE_INCREASE, YEAR_ONE_GUARANTEE_PERCENTAGE,
    FUTURE_YEAR_GUARANTEE_PERCENTAGE, MINIMUM_ONE_YEAR_SALARY, MINIMUM_BID_INCREMENT,
    getTotalAmountFromCurrentYear, getPerSeasonSalaries } from '../../../util/ContractUtils';
import MoneyFormat from '../../../util/MoneyFormat';

interface customBidModalProps {
  minimumOneYearOfferValue: number,
  currentSeason: number | undefined,
  maxBid: number,
  disabled: boolean,
  auctionId: string,
  teamId: string | undefined,
  remainingContractLengths: Map<number, number>,
  selectedPlayer: Player | undefined
}

function CustomBidModal(props: customBidModalProps) {
  const [minimumOneYearOfferValue, setMinimumOneYearOfferValue] = React.useState(props.minimumOneYearOfferValue);
  const [bid, setBid] = React.useState(getInitialBid());
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [minimumAmount, setMinimumAmount] = React.useState(props.minimumOneYearOfferValue);
  const roundedMaxBid = Math.floor(props.maxBid / MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT;
  const [maximumAmount, setMaximumAmount] = React.useState(roundedMaxBid);
  const [disabled, setDisabled] = React.useState(false);
  function getInitialBid() {
    return {
      years : 1,
      amount: props.minimumOneYearOfferValue,
      measurement: "TOTAL"
    };
  }
  
  React.useEffect(() => {
    setMinimumOneYearOfferValue(props.minimumOneYearOfferValue);
    const roundedMinimum = getNewMinimum(bid);
    setMinimumAmount(roundedMinimum);
    if (props.selectedPlayer) {
      handleMinMax();
    } else {
      const newBid = getInitialBid();
      setBid(newBid);
      calculateMaximum(newBid);
    }
  }, [props.minimumOneYearOfferValue]);
  
  function getTotalAmount(years: number, amount: number, measurement: string): number {
    if (measurement === "TOTAL") {
      return Number(amount);
    } else if (measurement === "CURRENT_SEASON") {
      return getTotalAmountFromCurrentYear(amount, years);
    } else if (measurement === "APY") {
      return amount * years;
    } else if (measurement === "GUARANTEED") {
      if (amount === 0) {
        return MINIMUM_ONE_YEAR_SALARY;
      } else {
        const midContractYear = (years - 1) / 2.0;
        const percentageOfTotalSalary = [...Array(years).keys()].reduce((partialSum, contractYear) => {
          const distanceFromMiddleOfContract: number = contractYear - midContractYear;
          const percentageOfTotalSalary = (1 / years) + ANNUAL_PERCENTAGE_INCREASE * distanceFromMiddleOfContract;
          const guaranteedPercentageOfTotalSalary = percentageOfTotalSalary * (contractYear === 0 ? YEAR_ONE_GUARANTEE_PERCENTAGE : FUTURE_YEAR_GUARANTEE_PERCENTAGE);
          return partialSum + guaranteedPercentageOfTotalSalary;
        }, 0);
        return amount / percentageOfTotalSalary;
      }
    }
    return 0;
  }
  
  function getAmount(years: number, totalAmount: number, measurement: string): number {
    const midContractYear = (years - 1) / 2.0;
    if (measurement === "TOTAL") {
      return totalAmount;
    } else if (measurement === "CURRENT_SEASON") {
      const percentageOfTotalSalary = (1 / years) - ANNUAL_PERCENTAGE_INCREASE * midContractYear;
      return totalAmount * percentageOfTotalSalary;
    } else if (measurement === "APY") {
      return totalAmount / years;
    } else if (measurement === "GUARANTEED") {
      if (totalAmount === MINIMUM_ONE_YEAR_SALARY) {
        return 0;
      } else {
        const percentageOfTotalSalary = [...Array(years).keys()].reduce((partialSum, contractYear) => {
          const distanceFromMiddleOfContract: number = contractYear - midContractYear;
          const percentageOfTotalSalary = (1 / years) + ANNUAL_PERCENTAGE_INCREASE * distanceFromMiddleOfContract;
          const guaranteedPercentageOfTotalSalary = percentageOfTotalSalary * (contractYear === 0 ? YEAR_ONE_GUARANTEE_PERCENTAGE : FUTURE_YEAR_GUARANTEE_PERCENTAGE);
          return partialSum + guaranteedPercentageOfTotalSalary;
        }, 0);
        return totalAmount * percentageOfTotalSalary;
      }
    }
    return 0;
  }
  
  React.useEffect(() => {
    setTotalAmount(getTotalAmount(bid.years, getRoundedBid(bid), bid.measurement))
  }, [bid]);
  
  React.useEffect(() => {
    if (!props.selectedPlayer) {
      setBid(getInitialBid());
    }
  }, [props.selectedPlayer]);
  
  React.useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);
  
  function getNewMinimum(newBid) {
    const years = newBid.years;
    const measurement = newBid.measurement;
    const minimumTotalAmount = getTotalAmountFromCurrentYear(props.minimumOneYearOfferValue, years);
    const minimumAmount = getAmount(years, minimumTotalAmount, measurement);
    return Math.ceil(minimumAmount / MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT;
  }
  
  function calculateMaximum(newBid) {
    const years = newBid.years;
    const measurement = newBid.measurement;
    const maximumTotalAmount = getTotalAmountFromCurrentYear(props.maxBid, years);
    const maximumAmount = getAmount(years, maximumTotalAmount, measurement);
    const roundedMaximum = Math.floor(maximumAmount / MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT;
    setMaximumAmount(roundedMaximum);
    return roundedMaximum
  }
  
  function handleChange(event) {
    const {name, value} = event.target;
    let newBid = {...bid};
    newBid[name] = value ? (Number(value) ? Number(value) : value) : 0;
    if (name !== "amount") {
      const roundedMinimum = getNewMinimum(newBid);
      setMinimumAmount(roundedMinimum);
      const roundedMaximum = calculateMaximum(newBid);
      if (newBid.amount < roundedMinimum) {
        newBid.amount = roundedMinimum;
      } else if (newBid.amount > roundedMaximum) {
        newBid.amount = roundedMaximum;
      }
    }
    setBid(newBid);
  }
  function getRoundedBid(bid) {
    const roundedMinimum = getNewMinimum(bid);
    if (bid.amount < roundedMinimum) {
      return roundedMinimum;
    } else if (bid.amount > maximumAmount) {
      return Math.max(maximumAmount, roundedMinimum);
    }
    return Math.floor(bid.amount / MINIMUM_BID_INCREMENT) * MINIMUM_BID_INCREMENT;
  }
  function handleMinMax() {
    const newBid = {...bid};
    newBid.amount = getRoundedBid(bid);
    setBid(newBid);
    setDisabled(props.disabled);
  }
  function getBidDisabled() {
    if (props.remainingContractLengths[bid.years] === 0) {
      return true;
    }
    const perSeasonSalaries = getPerSeasonSalaries(bid.years, totalAmount);
    return props.maxBid < perSeasonSalaries[0];
  }
  return <Box sx={{ display: 'inline-flex'}}>
    <Box>
      <Box>
        <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
          Custom Bid
        </Typography>
        <TextField name="years" label="Years: " value={bid.years} type="number"
            InputProps={{inputProps: {min: 1, max: 4}}} onChange={handleChange}/>
        <TextField name="amount" label="Amount: " value={bid.amount}
            InputProps={{inputProps: {min: minimumAmount, max: maximumAmount, step:MINIMUM_BID_INCREMENT}, inputComponent: MoneyFormat as any}} onChange={handleChange} onBlur={handleMinMax}/>
        <Select name="measurement" label="Measurement" labelId="customBidMeasurementLabel" value={bid.measurement}
            onChange={handleChange}>
          <MenuItem value='TOTAL'>Total</MenuItem>
          <MenuItem value='CURRENT_SEASON'>In {props.currentSeason}</MenuItem>
          <MenuItem value='APY'>APY</MenuItem>
          <MenuItem value='GUARANTEED'>Guaranteed</MenuItem>
        </Select>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
        <AuctionBidButton years={bid.years} totalAmount={totalAmount} currentSeason={props.currentSeason} bidDisabled={props.disabled || disabled || getBidDisabled()} auctionId={props.auctionId} teamId={props.teamId} />
      </Box>
    </Box>
  </Box>
}

export default CustomBidModal;
  