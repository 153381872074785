import { Box, Button, Paper, Typography } from '@mui/material';
import * as React from 'react'
import { LeagueEventApi } from '../../../../sdk/api/league-event-api';
import { Draft, EventActivity, Player, Team } from '../../../../sdk/model';
import { getCurrentSalary, toSalary } from '../../../util/SalaryDisplay';
import FastForwardDraftButton from '../dialogBoxes/fastforward';
import PauseDraftButton from '../dialogBoxes/pause';
import ResetDraftButton from '../dialogBoxes/reset';
import ResumeDraftButton from '../dialogBoxes/resume';
import RewindDraftButton from '../dialogBoxes/rewind';


export interface CommishControlsProps {
  leagueEventApi: LeagueEventApi;
  draftStatus: string;
  draftId: string;
  commish: boolean;
}

function CommishControls(props: CommishControlsProps) {

  console.log("Commish Controls");
  console.log(props);

  if (!props.commish) {
    return null;
  }
  
  return (
    <Box sx={{ flexDirection: 'row', display: 'flex' }}>
      <ResetDraftButton leagueEventApi={props.leagueEventApi} draftStatus={props.draftStatus} draftId={props.draftId} />
      <RewindDraftButton leagueEventApi={props.leagueEventApi} draftStatus={props.draftStatus} draftId={props.draftId} />
      <PauseDraftButton leagueEventApi={props.leagueEventApi} draftStatus={props.draftStatus} draftId={props.draftId} />
      <ResumeDraftButton leagueEventApi={props.leagueEventApi} draftStatus={props.draftStatus} draftId={props.draftId} />
      <FastForwardDraftButton leagueEventApi={props.leagueEventApi} draftStatus={props.draftStatus} draftId={props.draftId} />
    </Box>
  );
  
}

export default CommishControls;