import * as React from 'react';
import { Team } from '../../../sdk/model';
import { Button, IconButton, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface teamDisplayProps {
  leagueId: string,
  team: Team,
  emphasized: boolean
}

function TeamDisplay(props: teamDisplayProps) {
  const navigate = useNavigate();
  
  return <Button onClick={() => navigate('/league/' + props.leagueId + '/team/' + props.team.id)}>
    <IconButton sx={{ p: 0 }}>
      <Avatar alt={props.team?.name} src={props.team?.picture}>{!props.team?.picture && props.team?.name?.substring(0,1)}</Avatar>
    </IconButton>
    {props.emphasized ? <b>{props.team?.name}</b> : props.team?.name}
  </Button>
}

export default TeamDisplay;
