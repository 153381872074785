import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import { League, Player, Team, Contract, ContractStatus } from '../../../sdk/model';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { findDefaultTeam } from '../draft/service';
import { Typography, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box } from '@mui/material';
import { toSalary } from '../../util/SalaryDisplay';
import { contractApi } from '../../../adapters/APIExporter';
import EventActivityDisplay from '../leagueEvent/EventActivityDisplay';
import ContractSeasonDisplay from './ContractSeasonDisplay';
import DropPlayerButton from '../team/DropPlayerButton';
import TeamDisplay from '../team/TeamDisplay'

interface contractDetailsProps {
  leagueId: string,
  player: Player | undefined,
  contract: Contract
}

function ContractDetails(props: contractDetailsProps) {
  const navigate = useNavigate();
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [history, setHistory] = React.useState(null);
  
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: true,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: true, loadFullAuctionData: false,
                                                            loadRosterData: false, 
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));         
  const myDefaultTeam = league?.teams ? teamMap.get(findDefaultTeam(league?.teams, user?.id)) : null;
  
  function loadContractHistory() {
    contractApi.getContractHistory(props.contract.id).then(response => setHistory(response.data));
  }
  
  const team = teamMap.get(props.contract.teamId)!;
  const myTeam = team.owners!.some(owner => owner.id === user.id);
  return <Box sx={{ border: 1 }}>
    <p>
      <b>Status:</b> {props.contract.status} <b>Team:</b> <TeamDisplay leagueId={props.leagueId} team={team} emphasized={myTeam} /> <b>Full contract value:</b> {toSalary(props.contract.totalSalary)}
      {myTeam && props.contract.status === ContractStatus.Active && <DropPlayerButton sx={{marginLeft: 2}} leagueId={props.leagueId} player={props.player!} contract={props.contract} currentSeasonYear={league?.currentSeasonYear!} team={team} callback={() => true} />}
      {!myTeam && props.contract.status === ContractStatus.Active && <Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + league?.id + '/trade?teamId=' + team.id + '&playerId=' + props.contract.playerId)}>Propose Trade</Button>}
      {props.contract.status === ContractStatus.Waived && <React.Fragment>
        <b> Waiver clears on:</b> {new Date(props.contract.waiverExpirationDate!).toLocaleDateString(undefined, { weekday: 'long' })}
        {!myTeam && <Button color="primary" variant="contained" sx={{marginLeft: 2}} onClick={() => navigate('/league/' + props.leagueId + '/team/' + myDefaultTeam?.id + '/waivers/' + props.contract.playerId)}>Claim Waived Contract</Button>}
      </React.Fragment>}
    </p>
    <TableContainer className="centered" component={Paper}><Table>
      <TableHead><TableRow>
        <TableCell />
        <TableCell>Season</TableCell>
        <TableCell>Total Value</TableCell>
        <TableCell>Guaranteed</TableCell>
        <TableCell>Paid</TableCell>
        <TableCell>Remaining</TableCell>
        <TableCell>Remaining Guaranteed</TableCell>
        <TableCell>Remaining Non-Guaranteed</TableCell>
      </TableRow></TableHead>
      <TableBody>
          {props.contract.seasons!.map(contractSeason => <ContractSeasonDisplay key={contractSeason.season_year} contractSeason={contractSeason} teamMap={teamMap} />)}
        </TableBody>
      </Table>
    </TableContainer>
    {history ? 
      <Box>
        <Typography variant='h5'>Transaction History:</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{fontSize : 20}}>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>Transaction</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((eventActivity, eventIndex) => 
                <EventActivityDisplay key={eventIndex} activity={eventActivity} teamMap={teamMap} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> :
      (props.contract.playerId && <Button onClick={loadContractHistory}>Show Transaction History</Button>)
    }
  </Box>
}

export default ContractDetails;
