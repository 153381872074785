/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Action being taken on a player as part of a trade proposal
 * @export
 * @enum {string}
 */

export const TradeProposalPlayerActionEnum = {
    Trade: 'TRADE',
    Drop: 'DROP'
} as const;

export type TradeProposalPlayerActionEnum = typeof TradeProposalPlayerActionEnum[keyof typeof TradeProposalPlayerActionEnum];



