import * as React from 'react'
import { Box, Typography } from '@mui/material';
import { League, Team, Player } from '../../../sdk/model';
import RosterContractDisplay from './RosterContractDisplay';
import TeamDraftPickDisplay from './TeamDraftPickDisplay';
import {getRemainingRosterSpots} from '../../util/TeamUtils';
import { MINIMUM_ONE_YEAR_SALARY } from '../../util/ContractUtils';
import { toSalary } from '../../util/SalaryDisplay';

interface teamAssetsProps {
  league: League,
  team: Team,
  myTeam: boolean,
  currentSeason: number,
  playerMap: Map<string | undefined, Player | undefined>,
  teamMap: Map<string | undefined, Team | undefined>
}

function TeamAssets(props: teamAssetsProps) {
  const emptyRosterSpots = getRemainingRosterSpots(props.team, props.league);
  const emptyRosterSpotMinCost = emptyRosterSpots * MINIMUM_ONE_YEAR_SALARY;
  return <Box>
    <RosterContractDisplay team={props.team!} myTeam={props.myTeam} currentSeason={props.currentSeason} otherTeamIds={[]}
        teamMap={undefined} proposalPlayerMap={undefined} playerMap={props.playerMap}
        handleTradeProposalChange={undefined} />
    <TeamDraftPickDisplay team={props.team} teamMap={props.teamMap} myTeam={props.myTeam} currentSeason={props.currentSeason} />
    {emptyRosterSpots > 0 &&
      <Typography>Empty Roster Spots: {emptyRosterSpots} X (minimum) {toSalary(MINIMUM_ONE_YEAR_SALARY)} = {toSalary(emptyRosterSpotMinCost)}</Typography>
    }
  </Box>
}

export default TeamAssets;
