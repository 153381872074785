import * as React from 'react';
import {Box} from '@mui/material';
import { Draft } from '../../../sdk/model';


interface draftQuickDisplayProps {
  draft: Draft
}

function DraftQuickDisplay(props: draftQuickDisplayProps) {
  
  return <Box>
    <p>{props.draft.seasonYear} Rookie Draft</p>
    <p>{props.draft.rounds}-round {props.draft.draftType}</p>
  </Box>
}

export default DraftQuickDisplay;