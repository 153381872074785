import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { WaiverClear, Team } from '../../../sdk/model';
import {Button} from '@mui/material';

interface waiverClearDisplayProps {
  waiverClear: WaiverClear,
  teamMap: Map<string | undefined, Team>
}

function WaiverClearDisplay(props: waiverClearDisplayProps) {
  const navigate = useNavigate();
  
  return <p>Cleared waivers on <Button onClick={() => navigate('/league/' + props.waiverClear.leagueId + '/playerAcquisitionEvent/' + props.waiverClear.leagueEventId)}>{new Date(props.waiverClear.updatedTimeStamp).toLocaleDateString()}</Button></p>
}

export default WaiverClearDisplay;