export const ANNUAL_PERCENTAGE_INCREASE: number = 0.02;
export const YEAR_ONE_GUARANTEE_PERCENTAGE = 1.0;
export const FUTURE_YEAR_GUARANTEE_PERCENTAGE = 0.5;
export const MINIMUM_ONE_YEAR_SALARY = 500000;
export const MINIMUM_BID_INCREMENT = 500000;

export function getTotalContractValue(oneYearValue: number, years: number) {
  if (years === 1) {
    return oneYearValue;
  }
  const factorReduction = ((years - 1) * 2.0 - 1) / 3.0;
  const factor = years - factorReduction;
  return oneYearValue * factor;
}

export function getTotalAmountFromCurrentYear(amount: number, years: number) {
  const midContractYear = (years - 1) / 2.0;
  const percentageOfTotalSalary = (1 / years) - ANNUAL_PERCENTAGE_INCREASE * midContractYear;
  return amount / percentageOfTotalSalary;
}

export function getPerSeasonSalaries(years: number, totalAmount: number) {
  const midContractYear = (years - 1) / 2.0;
  return [...Array(years).keys()].map(contractYear => {
    const distanceFromMiddleOfContract: number = contractYear - midContractYear;
    const percentageOfTotalSalary = (1 / years) + ANNUAL_PERCENTAGE_INCREASE * distanceFromMiddleOfContract;
    return totalAmount * percentageOfTotalSalary;
  });
}
