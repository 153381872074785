import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaabWin, Team } from '../../../sdk/model';
import {Button} from '@mui/material';

interface faabWinDisplayProps {
  faabWin: FaabWin,
  teamMap: Map<string | undefined, Team>
}

function FaabWinDisplay(props: faabWinDisplayProps) {
  const navigate = useNavigate();
  
  const team = props.teamMap.get(props.faabWin.teamId)!;
  return <p>Signed via FAAB to {team.name} on <Button onClick={() => navigate('/league/' + props.faabWin.leagueId + '/playerAcquisitionEvent/' + props.faabWin.leagueEventId)}>{new Date(props.faabWin.updatedTimeStamp).toLocaleDateString()}</Button></p>
}

export default FaabWinDisplay;