/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NflTeam } from '../model';
// @ts-ignore
import { Player } from '../model';
/**
 * PlayerApi - axios parameter creator
 * @export
 */
export const PlayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the details of a single instance of a `player`.
         * @summary Get a player
         * @param {string} playerId A unique identifier for a &#x60;player&#x60;.
         * @param {boolean} [includeStats] Include Player Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayer: async (playerId: string, includeStats?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerId' is not null or undefined
            assertParamExists('getPlayer', 'playerId', playerId)
            const localVarPath = `/players/{playerId}`
                .replace(`{${"playerId"}}`, encodeURIComponent(String(playerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeStats !== undefined) {
                localVarQueryParameter['includeStats'] = includeStats;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `player` entities.
         * @summary List All players
         * @param {string} [firstName] Player\&#39;s First Name
         * @param {string} [lastName] Player\&#39;s Last Name
         * @param {string} [teamId] Team\&#39;s Id
         * @param {'QB' | 'TE' | 'WR' | 'RB'} [position] Player\&#39;s Position
         * @param {boolean} [includeStats] Include Player Statistics
         * @param {number} [pageIndex] Current Page Number (used for pagination)
         * @param {number} [pageSize] Number of results in a page (used for pagination)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayers: async (firstName?: string, lastName?: string, teamId?: string, position?: 'QB' | 'TE' | 'WR' | 'RB', includeStats?: boolean, pageIndex?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (position !== undefined) {
                localVarQueryParameter['position'] = position;
            }

            if (includeStats !== undefined) {
                localVarQueryParameter['includeStats'] = includeStats;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['page_index'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all players separated by team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPlayersMin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/loadAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerApi - functional programming interface
 * @export
 */
export const PlayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayerApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the details of a single instance of a `player`.
         * @summary Get a player
         * @param {string} playerId A unique identifier for a &#x60;player&#x60;.
         * @param {boolean} [includeStats] Include Player Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayer(playerId: string, includeStats?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayer(playerId, includeStats, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `player` entities.
         * @summary List All players
         * @param {string} [firstName] Player\&#39;s First Name
         * @param {string} [lastName] Player\&#39;s Last Name
         * @param {string} [teamId] Team\&#39;s Id
         * @param {'QB' | 'TE' | 'WR' | 'RB'} [position] Player\&#39;s Position
         * @param {boolean} [includeStats] Include Player Statistics
         * @param {number} [pageIndex] Current Page Number (used for pagination)
         * @param {number} [pageSize] Number of results in a page (used for pagination)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayers(firstName?: string, lastName?: string, teamId?: string, position?: 'QB' | 'TE' | 'WR' | 'RB', includeStats?: boolean, pageIndex?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Player>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayers(firstName, lastName, teamId, position, includeStats, pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all players separated by team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadPlayersMin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NflTeam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadPlayersMin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlayerApi - factory interface
 * @export
 */
export const PlayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayerApiFp(configuration)
    return {
        /**
         * Gets the details of a single instance of a `player`.
         * @summary Get a player
         * @param {string} playerId A unique identifier for a &#x60;player&#x60;.
         * @param {boolean} [includeStats] Include Player Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayer(playerId: string, includeStats?: boolean, options?: any): AxiosPromise<Player> {
            return localVarFp.getPlayer(playerId, includeStats, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `player` entities.
         * @summary List All players
         * @param {string} [firstName] Player\&#39;s First Name
         * @param {string} [lastName] Player\&#39;s Last Name
         * @param {string} [teamId] Team\&#39;s Id
         * @param {'QB' | 'TE' | 'WR' | 'RB'} [position] Player\&#39;s Position
         * @param {boolean} [includeStats] Include Player Statistics
         * @param {number} [pageIndex] Current Page Number (used for pagination)
         * @param {number} [pageSize] Number of results in a page (used for pagination)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayers(firstName?: string, lastName?: string, teamId?: string, position?: 'QB' | 'TE' | 'WR' | 'RB', includeStats?: boolean, pageIndex?: number, pageSize?: number, options?: any): AxiosPromise<Array<Player>> {
            return localVarFp.getPlayers(firstName, lastName, teamId, position, includeStats, pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all players separated by team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPlayersMin(options?: any): AxiosPromise<Array<NflTeam>> {
            return localVarFp.loadPlayersMin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerApi - object-oriented interface
 * @export
 * @class PlayerApi
 * @extends {BaseAPI}
 */
export class PlayerApi extends BaseAPI {
    /**
     * Gets the details of a single instance of a `player`.
     * @summary Get a player
     * @param {string} playerId A unique identifier for a &#x60;player&#x60;.
     * @param {boolean} [includeStats] Include Player Stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayer(playerId: string, includeStats?: boolean, options?: AxiosRequestConfig) {
        return PlayerApiFp(this.configuration).getPlayer(playerId, includeStats, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `player` entities.
     * @summary List All players
     * @param {string} [firstName] Player\&#39;s First Name
     * @param {string} [lastName] Player\&#39;s Last Name
     * @param {string} [teamId] Team\&#39;s Id
     * @param {'QB' | 'TE' | 'WR' | 'RB'} [position] Player\&#39;s Position
     * @param {boolean} [includeStats] Include Player Statistics
     * @param {number} [pageIndex] Current Page Number (used for pagination)
     * @param {number} [pageSize] Number of results in a page (used for pagination)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayers(firstName?: string, lastName?: string, teamId?: string, position?: 'QB' | 'TE' | 'WR' | 'RB', includeStats?: boolean, pageIndex?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return PlayerApiFp(this.configuration).getPlayers(firstName, lastName, teamId, position, includeStats, pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all players separated by team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public loadPlayersMin(options?: AxiosRequestConfig) {
        return PlayerApiFp(this.configuration).loadPlayersMin(options).then((request) => request(this.axios, this.basePath));
    }
}
