import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { League, Team, TradeProposal } from '../../../sdk/model';
import { tradeApi } from '../../../adapters/APIExporter';
import { useAppSelector } from '../../../app/hooks';
import { useLoadTradeData, useAddUpdateTrades, useLoadLeagueData } from '../../../app/dataLoaderHooks';
import {Box, Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableBody} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TradeAtAGlance from './TradeAtAGlance';
import { useValidateAction } from '../../util/ActionValidationUtil';

const ACTIVE_TRADE_STATUSES = new Set(['DRAFT', 'PENDING']);
const TRADE_HISTORY_BATCH_SIZE = 10;

function TradesOverview() {
  const navigate = useNavigate();
  const {leagueId, teamId} = useParams();
  
  const [isTradeLoadComplete, setTradeLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [retrievedTrades, setRetrievedTrades] = React.useState([]);
  const [allHistoryLoaded, setAllHistoryLoaded] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  useAddUpdateTrades(teamId!, retrievedTrades);
  
  if (retrievedTrades.length) {
    setRetrievedTrades([]);
  }
  
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const user = useAppSelector((state) => state.user);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: true, loadFullAuctionData: false,
                                                            loadRosterData: false, 
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  const trades : Array<TradeProposal> = useLoadTradeData({teamId: teamId!, setIsException: setIsException, isLoadComplete: isTradeLoadComplete, setLoadComplete: setTradeLoadComplete});
  
  const tradesAllowed = useValidateAction("trade", league)
  
  function loadHistory(itemsLoaded) {
    tradeApi.getTradeHistoryForTeam(teamId, itemsLoaded).then(response => {
      const newRetievedTrades = response.data;
      setRetrievedTrades(newRetievedTrades);
      if (newRetievedTrades.length < TRADE_HISTORY_BATCH_SIZE) {
        setAllHistoryLoaded(true);
      }
    });
  }
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  if (!isLoadComplete || !isTradeLoadComplete) {
    return <p>Loading...</p>
  }
  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  const playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const draftTrades = trades.filter(trade => trade.status === 'DRAFT');
  const pendingTrades = trades.filter(trade => trade.status === 'PENDING');
  const historicTrades = trades.filter(trade => !ACTIVE_TRADE_STATUSES.has(trade.status))
  return <Box>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Trade Requests
    </Typography>
    {trades.length === 0 ? 
      <p>No pending trade requests</p> :
      <Box>
        {pendingTrades.length > 0 && <Box>
          <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            Pending:
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{fontSize : 20}}>
                <TableRow>
                  <StyledTableCell>Submitted</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Terms</StyledTableCell>
                  <StyledTableCell>Expires</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingTrades.map(trade => {
                  const submittedTimestamp = new Date(trade.submittedDateTime!);
                  const expirationDateTime = trade.expirationDateTime ? new Date(trade.expirationDateTime) : null;
                  return <TableRow key={trade.id}>
                    <StyledTableCell>{submittedTimestamp.toLocaleString()}</StyledTableCell>
                    <StyledTableCell><Button onClick={() => navigate("/league/" + league?.id + "/trade/" + trade.id)}>PENDING</Button></StyledTableCell>
                    <StyledTableCell>
                      <TradeAtAGlance leagueId={leagueId} tradeStatus={trade.status!} teams={trade.teams!} picks={trade.picks!} players={trade.players!}
                          playerMap={playerMap} teamMap={teamMap} pickMap={undefined} />
                    </StyledTableCell>
                    <StyledTableCell>{expirationDateTime ? expirationDateTime.toLocaleString() : '-'}</StyledTableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>}
        {draftTrades.length > 0 && <Box>
          <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
            Drafts:
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{fontSize : 20}}>
                <TableRow>
                  <StyledTableCell>Created</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Terms</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {draftTrades.map(trade => {
                  const createdTimestamp = new Date(trade.createdTimestamp);
                  return <TableRow key={trade.id}>
                    <StyledTableCell>{createdTimestamp.toLocaleString()}</StyledTableCell>
                    <StyledTableCell><Button onClick={() => navigate("/league/" + league?.id + "/trade/" + trade.id)}>DRAFT</Button></StyledTableCell>
                    <StyledTableCell>
                      <TradeAtAGlance leagueId={leagueId} tradeStatus={trade.status!} teams={trade.teams!} picks={trade.picks!} players={trade.players!}
                          playerMap={playerMap} teamMap={teamMap} pickMap={undefined} />
                    </StyledTableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>}
      </Box>
    }
    <Button color="primary" disabled={!tradesAllowed} variant="contained" onClick={() => navigate("/league/" + league?.id + "/trade")}>Create new trade</Button>
    <br/>
    {historicTrades.length > 0 && <Box>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        Trade History:
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{fontSize : 20}}>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Terms</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historicTrades.map(trade => {
              const updatedTimestamp = new Date(trade.updatedTimeStamp!);
              return <TableRow key={trade.id}>
                <StyledTableCell>{updatedTimestamp.toLocaleString()}</StyledTableCell>
                <StyledTableCell>{trade.status}</StyledTableCell>
                <StyledTableCell>
                  <TradeAtAGlance leagueId={leagueId} tradeStatus={trade.status!} teams={trade.teams!} picks={trade.picks!} players={trade.players!}
                          playerMap={playerMap} teamMap={teamMap} pickMap={undefined} />
                </StyledTableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>}
    {allHistoryLoaded ? <p>Beginning of trade history</p> : <Button onClick={() => loadHistory(historicTrades.length)}>Load {historicTrades.length ? 'More' : ''} Trade History</Button>}
  </Box>
}

export default TradesOverview;
