import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import {Box, Typography, Select, MenuItem} from '@mui/material';
import { LeagueSeason } from '../../../sdk/model';
import { leagueSeasonApi } from '../../../adapters/APIExporter';
import AllSeasonsReport from './AllSeasonsReport';
import SeasonReport from './SeasonReport';

function LeagueHistory({league}) {
  const [searchParams] = useSearchParams();
  const defaultSeasonYear = searchParams.get("seasonYear");
  const [loadedSeasons, setLoadedSeasons] = React.useState(false);
  const [completedSeasons, setCompletedSeasons] = React.useState<LeagueSeason[]>([]);
  const [viewingSeason, setViewingSeason] = React.useState(defaultSeasonYear ? defaultSeasonYear : 'ALL');
  
  React.useEffect(() => {
    leagueSeasonApi.getAllCompletedLeagueSeasons(league.id).then(response => {
      setCompletedSeasons(response.data);
      setLoadedSeasons(true);
    });
  }, []);
  if (!loadedSeasons) {
    return <p>Loading...</p>;
  }
  
  function getSeasonReport() {
    const leagueSeason : LeagueSeason = completedSeasons.find(season => season.seasonYear === Number(viewingSeason))!;
    return <SeasonReport league={league} leagueSeason={leagueSeason} teamSeasons={leagueSeason.teams!} />
  }
  
  function getBody() {
    if (completedSeasons.length === 0) {
      return <Typography variant="body1">No historical data found for this league</Typography>
    }
    return <Box>
      <Select name="seasonSelect" label="View Season" labelId="seasonSelectLabel" value={viewingSeason}
          onChange={event => setViewingSeason(event.target.value)}>
        <MenuItem value='ALL'>All Seasons</MenuItem>
        {completedSeasons.map(season => <MenuItem value={season.seasonYear}>{season.seasonYear}</MenuItem>)}
      </Select>
      {viewingSeason === 'ALL' ? 
        <AllSeasonsReport league={league} completedSeasons={completedSeasons} showSeason={setViewingSeason} /> : 
        getSeasonReport()
      }
      
    </Box>
    
  }
  return <Box>
    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
      League History
    </Typography>
    {getBody()}
  </Box>
}

export default LeagueHistory;
