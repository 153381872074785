import * as React from 'react'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';

function LeagueEventDatePicker({eventDateTime, handleEventChange, editMode, label}) {
  function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
  }
  
  const today = new Date();
  const earliestEvent = today;
  const earliestEventTime = eventDateTime && sameDay(new Date(eventDateTime), new Date(earliestEvent)) ? earliestEvent : null;
  return (<LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateTimePicker
        label={label}
        renderInput={(params) => <TextField {...params} />}
        minutesStep={15}
        minDate={earliestEvent}
        minTime={earliestEventTime}
        value={eventDateTime}
        disabled={!editMode}
        onChange={date => handleEventChange({ target: { value: date, name: 'eventDateTime' } })}
    />
  </LocalizationProvider>);
}

export default LeagueEventDatePicker;
