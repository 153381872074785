import { GameScoreBreakdown, NflPlayerGameStatistics, Passing, PassingScoringSettings, Player, PlayerGameScore, Receiving, ReceivingScoringSettings, Rushing, RushingScoringSettings, ScoringSettings } from "../../sdk/model";


const ALL_STRING : string = "ALL";

export function getPlayerScoreFromStats(player: Player ,scoringSettings: ScoringSettings, playerStats: NflPlayerGameStatistics) : PlayerGameScore {
  let playerScore: PlayerGameScore = {};
  const stats = playerStats.statistics;
  const key : string = (player.position ?? "ALL").toString().toUpperCase();
  playerScore.playerId = player.id;
  
  if (!stats) {
    return playerScore;
  }

  let passing: number = 0;
  if (stats.passing) {
    passing = getPassingScore(getSettingsWithKey(scoringSettings.passingScoringSettings, key), stats.passing ,playerScore);
  }

  let rushing: number = 0;
  if (stats.rushing) {
    rushing = getRushingScore(getSettingsWithKey(scoringSettings.rushingScoringSettings, key), stats.rushing, playerScore);
  }

  let receiving: number = 0;
  if (stats.receiving) {
    receiving = getReceivingScore(getSettingsWithKey(scoringSettings.receivingScoringSettings, key), stats.receiving, playerScore);
  }
  playerScore.totalScore = round(passing + rushing + receiving);
  return playerScore;
 

}

function getPassingScore(scoringSettings: PassingScoringSettings, passingStatistics: Passing, playerScore: PlayerGameScore) : number {
  let score = 0;

  if (!passingStatistics) {
    return score;
  }

  let yards : number = (passingStatistics?.yards ?? 0) / (scoringSettings?.yardsPerPoint ?? 1) * (scoringSettings?.pointsPer ?? 0);
  populateScoreBreakdown(playerScore, "Passing Yards", passingStatistics?.yards, scoringSettings?.yardsPerPoint, yards);

  let bonusYards : number = 0;
  if(scoringSettings?.bonusYards && scoringSettings?.bonusYardsPoints
      && passingStatistics?.yards && passingStatistics?.yards > scoringSettings?.bonusYards){
        bonusYards = scoringSettings?.bonusYardsPoints;
        populateScoreBreakdown(playerScore, "Passing Bonus", undefined, scoringSettings.bonusYards, bonusYards);
  }

  const tds: number = (passingStatistics?.touchdowns ?? 0) * (scoringSettings?.tds ?? 0);
  populateScoreBreakdown(playerScore, "Passing TDs", passingStatistics?.touchdowns, scoringSettings.tds, tds);

  const conversions: number = (passingStatistics?.conversions ?? 0) * (scoringSettings?.twoPointConversions ?? 0);
  populateScoreBreakdown(playerScore, "Passing Conversions", passingStatistics?.conversions, scoringSettings.twoPointConversions, conversions);

  const ints : number = (passingStatistics?.interceptions ?? 0) * (scoringSettings?.interceptions ?? 0);
  populateScoreBreakdown(playerScore, "Interceptions", passingStatistics?.interceptions, scoringSettings.interceptions, ints);

  const sacks : number = (passingStatistics?.sacks ?? 0) * (scoringSettings?.sacked ?? 0);
  populateScoreBreakdown(playerScore, "Sacks", passingStatistics?.sacks, scoringSettings.sacked, sacks);

  const firstDowns : number = (passingStatistics?.firstDowns ?? 0) * (scoringSettings?.firstDowns ?? 0);
  populateScoreBreakdown(playerScore, "Passing First Downs", passingStatistics?.firstDowns, scoringSettings.firstDowns, firstDowns);

  score += yards + bonusYards + tds + conversions + ints + sacks + firstDowns;
  return score;
}

function getRushingScore(scoringSettings: RushingScoringSettings, rushingStatistics: Rushing, playerScore: PlayerGameScore) {
  let score = 0;

  if (!rushingStatistics) {
    return score;
  }

  let yards : number = (rushingStatistics?.yards ?? 0) / (scoringSettings?.yardsPerPoint ?? 1) * (scoringSettings?.pointsPer ?? 0);
  populateScoreBreakdown(playerScore, "Rushing Yards", rushingStatistics?.yards, scoringSettings?.yardsPerPoint, yards);

  let bonusYards : number = 0;
  if(scoringSettings?.bonusYards && scoringSettings?.bonusYardsPoints
      && rushingStatistics?.yards && rushingStatistics?.yards > scoringSettings?.bonusYards){
        bonusYards = scoringSettings?.bonusYardsPoints;
        populateScoreBreakdown(playerScore, "Rushing Bonus", undefined, scoringSettings.bonusYards, bonusYards);
  }

  const tds: number = (rushingStatistics?.touchdowns ?? 0) * (scoringSettings?.tds ?? 0);
  populateScoreBreakdown(playerScore, "Rushing TDs", rushingStatistics?.touchdowns, scoringSettings.tds, tds);

  const conversions: number = (rushingStatistics?.conversions ?? 0) * (scoringSettings?.twoPointConversions ?? 0);
  populateScoreBreakdown(playerScore, "Rushing Conversions", rushingStatistics?.conversions, scoringSettings.twoPointConversions, conversions);

  const firstDowns : number = (rushingStatistics?.firstDowns ?? 0) * (scoringSettings?.firstDowns ?? 0);  
  populateScoreBreakdown(playerScore, "Rushing First Downs", rushingStatistics?.firstDowns, scoringSettings.firstDowns, firstDowns);

  score += yards + bonusYards + tds + conversions + firstDowns;

  return score;
}

function getReceivingScore(scoringSettings: ReceivingScoringSettings, rushingStatistics: Receiving, playerScore: PlayerGameScore) {
  let score = 0;

  if (!rushingStatistics) {
    return score;
  }

  let yards : number = (rushingStatistics?.yards ?? 0) / (scoringSettings?.yardsPerPoint ?? 1) * (scoringSettings?.pointsPer ?? 0);
  populateScoreBreakdown(playerScore, "Receiving Yards", rushingStatistics?.yards, scoringSettings?.yardsPerPoint, yards);

  let bonusYards : number = 0;
  if(scoringSettings?.bonusYards && scoringSettings?.bonusYardsPoints
      && rushingStatistics?.yards && rushingStatistics?.yards > scoringSettings?.bonusYards){
        bonusYards = scoringSettings?.bonusYardsPoints;
        populateScoreBreakdown(playerScore, "Receiving Bonus", undefined, scoringSettings.bonusYards, bonusYards);
  }

  const tds: number = (rushingStatistics?.touchdowns ?? 0) * (scoringSettings?.tds ?? 0);
  populateScoreBreakdown(playerScore, "Receiving TDs", rushingStatistics?.touchdowns, scoringSettings.tds, tds);

  const receptions: number = (rushingStatistics?.receptions ?? 0) * (scoringSettings?.receptions ?? 0);
  populateScoreBreakdown(playerScore, "Receptions", rushingStatistics?.receptions, scoringSettings.receptions, receptions);

  const conversions: number = (rushingStatistics?.conversions ?? 0) * (scoringSettings?.twoPointConversions ?? 0);
  populateScoreBreakdown(playerScore, "Receiving Conversions", rushingStatistics?.conversions, scoringSettings.twoPointConversions, conversions);

  const firstDowns : number = (rushingStatistics?.firstDowns ?? 0) * (scoringSettings?.firstDowns ?? 0);  
  populateScoreBreakdown(playerScore, "Receiving First Downs", rushingStatistics?.firstDowns, scoringSettings.firstDowns, firstDowns);

  score += yards + bonusYards + tds + receptions + conversions + firstDowns;

  return score;


}

function populateScoreBreakdown(playerScore: PlayerGameScore, name: string, stat: number | undefined, pointsPer: number | undefined, pointsTotal: number) {
  if(!playerScore.scoreBreakdown){
    playerScore.scoreBreakdown = [];
  }
  if(!stat){
    return;
  }
  let scoreBreakdown: GameScoreBreakdown = {}
  scoreBreakdown.statType = name;
  scoreBreakdown.stat = stat;
  scoreBreakdown.pointsPer = pointsPer;
  scoreBreakdown.points = round(pointsTotal);
  playerScore.scoreBreakdown.push(scoreBreakdown);
}

// Utility Method

function getSettingsWithKey(scoringSettings: { [key: string]: any; }, key: string) {
  if(!scoringSettings) {
    console.log("No scoring settings found");
    return {};
  }
  if (scoringSettings[key]) {
    return scoringSettings[key];
  }
  return scoringSettings[ALL_STRING];
}

function round(value: number): number {
  return parseFloat(value.toFixed(2));

}


/**
 * Shouldn't be exported into production
 */
export const exportedForTesting = {
  getPassingScore
}
