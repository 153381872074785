import * as React from 'react'
import { useParams } from 'react-router-dom';
import { League } from '../../../sdk/model'
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { useAppSelector } from '../../../app/hooks';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import AllContractsView from './AllContractsView';
import TeamContractReport from './TeamContractReport';

function ContractsView() {
  const {leagueId} = useParams();

  // Redux store variables
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [view, setView] = React.useState<string>('allContracts');
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: true, loadWaivedContracts: true,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  if (!isLoadComplete) {
    return <Typography color="white">Loading contract data..</Typography>
  }
  return <Box>
    <Tabs value={view} onChange={(event, value) => {
      setView(value)
      }} aria-label="Contracts Tabs">
      <Tab label="All Contracts" value="allContracts" />
      <Tab label="Team Contract Report" value="report" />
    </Tabs>
    {view === 'allContracts' && <AllContractsView league={league!} playerDataLoad={playerDataLoad} />}
    {view === 'report' && <TeamContractReport league={league!} />}
  </Box>
}

export default ContractsView;