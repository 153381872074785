import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { League } from '../../../sdk/model';
import Grid  from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { createTheme, Input, ThemeProvider, Card, CardActionArea, CardHeader, CardContent } from '@mui/material';

const LeagueQuickView = ({userId, league, callback}) => {
  const leagueCardTheme = createTheme({
    palette: {
	  background: {
		paper: '#1565c0'
	  }
    },
  });

  const navigate = useNavigate();

  const className = 'league-quick-view';
  return (
    <ThemeProvider theme={leagueCardTheme}>
      <Grid container justifyContent="center" className={className} 
        onClick={() => {
          navigate("/league/" + league.id)
          if (callback) {
            callback();
          }
        }}>
          <Grid item className="league-name">
            <Typography variant='h5'>{league.leagueName} </Typography> 
          </Grid>
          <Grid item className="league-name-commish" >
            <Typography variant="caption">{isCommish(league, userId) ? '(C)' : ''}</Typography> 
          </Grid>
          <Grid justifyContent="center" container xs={12}>
            <Typography className="league-name-user"
                  variant="body2" gutterBottom>
                {getTeamName(league, userId)}
              </Typography>
          </Grid>
      </Grid>
	</ThemeProvider>
  );
}

function isCommish(league, user): boolean {
  var isCommish = league.commissioners.some(leagueUser => leagueUser.id == user);
  return isCommish;
}

function getTeamName(league, user): string {
  var team = league.teams.find(team => team.owners.find(owner => (owner.id == user)));
  if(team == undefined) {
    return "No Team"
  }
  return team.name;
}

export default LeagueQuickView  