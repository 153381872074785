import * as React from 'react';
import Button from '@mui/material/Button';
import ContractSummary from './ContractSummary';
import { auctionApi } from '../../../../adapters/APIExporter';
import { AuctionBid } from '../../../../sdk/model';
import "../style.css";

interface auctionBidButtonProps {
  years: number,
  totalAmount: number,
  currentSeason: number | undefined,
  bidDisabled: boolean,
  auctionId: string,
  teamId: string | undefined
}

function AuctionBidButton(props: auctionBidButtonProps) {
  const [addedButtonClass, setAddedButtonClass] = React.useState<string | undefined>(undefined)
  
  function submitBid() {
    const bid: AuctionBid = {
      teamId: props.teamId,
      years: props.years,
      totalAmount: props.totalAmount
    }
    auctionApi.auctionBid(props.auctionId, bid)
      .then(respoonse => {
        setAddedButtonClass("good_bid")
      })
      .catch(error => {
        setAddedButtonClass("bad_bid")
      }).finally(() => {
        setTimeout(function() { setAddedButtonClass(undefined); }, 3000);
      });
  }
  
  return <Button className={addedButtonClass} color="primary" variant="contained" disabled={props.bidDisabled} onClick={submitBid} sx={{width: 200}}>
    <ContractSummary years={props.years} totalAmount={props.totalAmount} owningTeamName={undefined} currentSeason={props.currentSeason} />
  </Button>
}

export default AuctionBidButton;
