import * as React from 'react';
import { Team } from '../../../../sdk/model';
import { Paper, Typography, Button } from '@mui/material';
import { MINIMUM_ONE_YEAR_SALARY } from '../../../util/ContractUtils';
import TeamAuctionInfo from './TeamAuctionInfo';

interface teamModalProps {
  teams: Array<Team>,
  turnIndex: number,
  salaryCap: number | undefined,
  teamRemainingRosterSpotsMap: Map<string | undefined, number>,
  teamMaxBidMap: Map<string | undefined, number>,
  remainingContractLengthsForTeams: Map<string | undefined, object>
}

function TeamOrderModal(props: teamModalProps) {
  const [expandAll, setExpandAll] = React.useState(false);
  const salaryCap = props.salaryCap ? props.salaryCap : 0;
  return <Paper>
  <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
    Teams:
  </Typography>
  <Button onClick={() => setExpandAll(!expandAll)}>{expandAll ? "Collapse" : "Expand"} All</Button>
  {props.teams.map((team, index) => {
    const remainingRosterSpots = props.teamRemainingRosterSpotsMap.get(team.id);
    const maxBid = props.teamMaxBidMap.get(team.id)
    const currentSalary = team.currentSeasonSalary ? team.currentSeasonSalary - remainingRosterSpots * MINIMUM_ONE_YEAR_SALARY : 0;
    const contractLengths = props.remainingContractLengthsForTeams[team.id].lengthNumbers;
    return <TeamAuctionInfo team={team} remainingRosterSpots={remainingRosterSpots} maxBid={maxBid} currentSalary={currentSalary} contractLengths={contractLengths} salaryCap={salaryCap} myTurn={index === props.turnIndex} expanded={expandAll} />
  })}
</Paper>
}

export default TeamOrderModal;