import * as React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { League } from '../../../sdk/model'
import { toSalary, getSalaryForSeason } from '../../util/SalaryDisplay';
import MaterialReactTable from 'material-react-table';
import TeamDisplay from '../team/TeamDisplay'

interface teamContractReportProps {
  league : League
}

const FUTURE_SEASONS_TO_DISPLAY = 4;

function TeamContractReport(props: teamContractReportProps) {
  const user = useAppSelector((state) => state.user);
  
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Team Name',
        size: 75,
        Cell: ({ renderedCellValue, row }) => {
          const myTeam = row.original.owners!.some(owner => owner.id === user.id);
          return <TeamDisplay leagueId={props.league.id!} team={row.original} emphasized={myTeam} />
        }
      },
      {
        accessorKey: 'currentSeasonSalary',
        id: 'currentSeasonSalary',
        header: props.league.currentSeasonYear + " Salary",
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue }) => {
          return toSalary(renderedCellValue);
        }
      },
      {
        accessorKey: 'capSpace',
        header: props.league.currentSeasonYear + " Cap Space",
        minSize: 15,
        size: 15,
        Cell: ({ renderedCellValue }) => {
          return toSalary(renderedCellValue);
        }
      },
      {
        id: 'futureSalaries',
        header: 'Future Season Salaries',
        columns: [...Array(FUTURE_SEASONS_TO_DISPLAY).keys()].map(index => {
          const seasonYear = props.league.currentSeasonYear! + index + 1;
          return {
            accessorKey: 'salariesBySeason.' + seasonYear,
            header: seasonYear,
            minSize: 15,
            size: 15,
            Cell: ({ renderedCellValue }) => {
              return toSalary(renderedCellValue);
            }
          }
        })
      }
    ],
    [],
  );
  const data = props.league.teams.map(team => {
    const capSpace = props.league.currentSeason!.nflSeason!.salaryCap! - team.currentSeasonSalary!;
    const salariesBySeason = [...Array(FUTURE_SEASONS_TO_DISPLAY).keys()].reduce((builder, index) => {
      const newValue = {...builder};
      const seasonYear = props.league.currentSeasonYear! + index + 1;
      const salaryForSeason = getSalaryForSeason(team.paymentsBySeason![seasonYear]);
      newValue[seasonYear.toString()] = salaryForSeason;
      return newValue;
    }, {});
    return {...team, capSpace, salariesBySeason}
  })
  return <MaterialReactTable enableMultiRowSelection={false}
    getRowId={(row) => row.id} columns={columns} 
    data={data} enablePagination={false}
    initialState={{
      sorting: [
        {
          id: "currentSeasonSalary",
          desc: true,
        },
      ],
      
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: {
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        }
      },
    })}

  />
}

export default TeamContractReport;