import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { WaiverPickup, Team } from '../../../sdk/model';
import {Button} from '@mui/material';

interface waiverPickupDisplayProps {
  waiverPickup: WaiverPickup,
  teamMap: Map<string | undefined, Team>
}

function WaiverPickupDisplay(props: waiverPickupDisplayProps) {
  const navigate = useNavigate();
  
  const team = props.teamMap.get(props.waiverPickup.teamId)!;
  return <p>Claimed off waivers by {team.name} on <Button onClick={() => navigate('/league/' + props.waiverPickup.leagueId + '/playerAcquisitionEvent/' + props.waiverPickup.leagueEventId)}>{new Date(props.waiverPickup.updatedTimeStamp).toLocaleDateString()}</Button></p>
}

export default WaiverPickupDisplay;