import * as React from 'react';
import { League } from '../../../../sdk/model';
import { styled } from '@mui/material/styles';
import { Box, Dialog, DialogTitle, IconButton, DialogContent, Typography, 
    DialogActions, InputLabel, Select, MenuItem, Button } from '@mui/material';
import Close from "@mui/icons-material/Close";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { contractApi } from '../../../../adapters/APIExporter';

interface manageRostersProps {
  league: League
}

function ManageRosters(props: manageRostersProps) {
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [seasonYear, setSeasonYear] = React.useState<number>(props.league.currentSeasonYear!);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<Array<string>>([]);
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  function uploadContracts() {
    setErrors([]);
    setSuccess(false);
    contractApi.importContracts(props.league.id, seasonYear, file).then(response => {
      setFile(undefined);
      setSuccess(true);
    }).catch(error => {
      setErrors([error.response.data.message]);
    }).finally(() => {
      setUploading(false);
    })
  }
  
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  
  return <Box>
    <Dialog open={uploading} maxWidth="sm" fullWidth>
      <DialogTitle>Import Contracts?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setUploading(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to replace all contracts in your league with the contracts in this CSV?  This action is irreversable!</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setUploading(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={uploadContracts}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Button
        className='capsized-button'
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}>
      Upload Contract CSV
      <VisuallyHiddenInput type="file" onChange={event => setFile(event.target.files![0])} />
    </Button>
    <br/>
    {file !== undefined && file.name}
    <br/>
    <InputLabel id="seasonYearLabel">Contract Terms Apply to:</InputLabel>
    <Select name="seasonYear" label="Contract Terms Effective" labelId="seasonYearLabel" value={seasonYear}
        onChange={event => setSeasonYear(event.target.value!)}>
      <MenuItem value={props.league.currentSeasonYear! - 1}>{props.league.currentSeasonYear! - 1}</MenuItem>
      <MenuItem value={props.league.currentSeasonYear!}>{props.league.currentSeasonYear!}</MenuItem>
    </Select>
    <br/>
    <Button className='capsized-button' disabled={!file} onClick={() => setUploading(true)}>Import Contracts into League</Button>
    {success && <p className='success'>Import successful!</p>}
    {errorInfo}
  </Box>
}

export default ManageRosters;
