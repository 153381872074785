import * as React from 'react'
import { League, Player, TagType } from '../../../sdk/model';
import {Dialog, DialogTitle, Box, IconButton, DialogContent, Typography, DialogActions, Button} from '@mui/material';
import Close from "@mui/icons-material/Close";
import { contractApi } from '../../../adapters/APIExporter';
import { useUpdateLeagueData } from '../../../app/dataLoaderHooks';

interface removeTagButtonProps {
  league: League,
  player: Player,
  tagType: TagType
  callback: Function
}

function RemoveTagButton(props: removeTagButtonProps) {
  const [confirming, setConfirming] = React.useState(false);
  function finalizeTag() {
    contractApi.finalizePlayerContractTag(props.league.id, props.player.id, props.tagType).then(result => {
      props.callback('success', result.data);
    }).catch(error => {
      props.callback('error', error.response.data.message);
    })
  }
  return <React.Fragment>
    <Dialog open={confirming} maxWidth="lg" fullWidth>
      <DialogTitle>Franchise Tag Player?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setConfirming(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to finalize the Franchise Tag on {props.player.abbr_name}?  
            The tag will automatically become final 72 hours prior to your league's Rookie Draft,
            but tagging early will allow you to trade the player immediately.  This action is irreversable
            and will immediately drop all un-tagged expired contracts to the Free Agent pool.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setConfirming(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={finalizeTag}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Button color="primary" variant="contained" onClick={() => setConfirming(true)}>Finalize Franchise Tag</Button>
  </React.Fragment>
}

export default RemoveTagButton;
