import { League, Team } from "../../../sdk/model";

// Team View Information

export const findDefaultTeam = function(teams: Array<Team>, userId: string) {
  const defaultTeam = teams.find(team => team?.owners?.some(owner => owner.id === userId));
  if (defaultTeam) {
    return defaultTeam.id;
  } else {
    console.log("No default team found for user: ", userId);
    return undefined;
  }
}


export const getClockText = function(hours, minutes, seconds) {
  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
    if (minutes > 0) {
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
    }
    return `${seconds}`;
}
