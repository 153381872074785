import * as React from 'react'
import { League, NflTeam, Player, Contract, LeaguePhase } from '../../../sdk/model'
import MaterialReactTable from 'material-react-table';
import { getColorByPosition } from '../../util/ColorByPosition';
import { toSalary } from '../../util/SalaryDisplay';
import PlayerInfoCard from '../../cards/playerCard';
import { dataloadToPlayerDataFlat } from '../../util/PlayerDataUtil';
import { Typography, Box, Modal } from '@mui/material';

interface allContractsViewProps {
  league : League,
  playerDataLoad : NflTeam[]
}

interface ContractWithInfo {
  contract : Contract,
  player : Player,
  fantasyTeam : string,
  seasonsByYear : object
}

const SEASONS_TO_DISPLAY = 5;

function AllContractsView(props : allContractsViewProps) {
  const [data, setData] = React.useState<Player[]>([]);
  const [viewingPlayer, setViewingPlayer] = React.useState<Player | undefined>(undefined);
  
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'player.first_name',
        header: 'First Name',
        size: 75,
      },
      {
        accessorKey: 'player.last_name',
        header: 'Last Name',
        size: 75,
      },
      {
        accessorKey: 'player.position',
        id: 'position',
        header: 'Position',
        minSize: 15,
        size: 15,
      },
      {
        accessorKey: 'player.nfl_team.alias',
        header: 'Team',
        minSize: 15,
        size: 15,
      },
      { 
        accessorKey: 'fantasyTeam',
        header: 'Fantasy Team',
        minSize: 15,
        size: 15
      },
      {
        id: 'salaries',
        header: 'Salaries',
        columns: [...Array(SEASONS_TO_DISPLAY).keys()].map(index => {
          const seasonYear = props.league.currentSeasonYear! + index - (props.league.phase === LeaguePhase.Offseason ? 1 : 0);
          return {
            accessorKey: 'seasonsByYear.' + seasonYear + '.annual_salary',
            header: seasonYear,
            minSize: 15,
            size: 15,
            Cell: ({ renderedCellValue, row }) => {
              return renderedCellValue ? toSalary(renderedCellValue) : '-';
            }
          }
        })
      },
      {
        accessorKey: 'finalSeasonYear',
        header: 'Final Contract Year',
        minSize: 15,
        size: 15
      },
      { 
        accessorKey: 'contract.status',
        header: 'Status',
        minSize: 15,
        size: 15
      }
    ],
    [],
  );
  
  React.useEffect(() => {
    setData(dataloadToPlayerDataFlat(props.playerDataLoad));
  }, [props.playerDataLoad]);

  
  const playerMap = new Map(data.map((player) => [player.id, player]));
  const playerIdToTeamNameMap = new Map(props.league.teams.flatMap(team =>  {
    const playerRoster = team.playerRoster ? team.playerRoster : [];
    return Object.values(playerRoster).flat().filter(player => player).map(player => [player.playerId, team.name]);
  }));
  const allContracts : ContractWithInfo[] = props.league.teams.flatMap(team => {
    const playerRoster = team.playerRoster ? team.playerRoster : [];
    return Object.values(playerRoster).flat().filter(player => player).map(player => player.contract!);
  }).concat(props.league.waivedContracts!).filter(contract => contract && contract.seasons).map(contract => {
    const seasonsByYear = [...Array(SEASONS_TO_DISPLAY).keys()].reduce((builder, index) => {
      const newValue = {...builder};
      const seasonYear = props.league.currentSeasonYear! + index - (props.league.phase === LeaguePhase.Offseason ? 1 : 0);
      const season = contract.seasons?.find(season => seasonYear === season.season_year);
      newValue[seasonYear.toString()] = season || {annual_salary : 0};
      return newValue;
    }, {});
    const finalSeasonYear = Math.max(...contract.seasons!.map(season => season.season_year!));
    return {
      contract,
      player: playerMap.get(contract.playerId)!,
      fantasyTeam: playerIdToTeamNameMap.get(contract.playerId)!,
      seasonsByYear,
      finalSeasonYear
    }
  });
  return <Box>
    <Modal open={viewingPlayer !== undefined} onClose={() => setViewingPlayer(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <PlayerInfoCard player={viewingPlayer!} leagueId={props.league.id}/>
    </Modal>
    <MaterialReactTable enableMultiRowSelection={false}
      getRowId={(row) => row.id} columns={columns} 
      data={allContracts}
      initialState={{
        sorting: [
          {
            id: "seasonsByYear." + props.league.currentSeasonYear!.toString() + ".annual_salary",
            desc: true,
          },
        ],
        
      }}
      
      //clicking anywhere on the row will select it
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => setViewingPlayer(playerMap.get(row.original.player.id)),
        sx: getColorByPosition(row.getValue('position'))
      })}

      muiTableBodyCellProps={({ column }) => ({
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            color: 'black',
            fontWeight: parseInt(column.columnDef.header) === props.league.currentSeasonYear ? 'bold' : 'normal'
          },
      })}
      muiTableHeadCellProps={({ column }) => ({
        sx: {
          "& .Mui-TableHeadCell-Content-Labels": {
            padding: "0px",
          },
          "& .MuiBox-root": {
            padding: "0px",
          },
          fontWeight: parseInt(column.columnDef.header) === props.league.currentSeasonYear ? 1000 : 700
        },
      })}

      />
    </Box>
}

export default AllContractsView;
