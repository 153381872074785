import * as React from 'react';
import { League } from '../../../../sdk/model';
import { Box, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { leagueApi } from '../../../../adapters/APIExporter'
import DivisionContainer from './DivisionContainer';

interface divisionSettingsProps {
  league: League
}

function DivisionSettings(props: divisionSettingsProps) {
  const [divisions, setDivisions] = React.useState([...props.league.divisions!]);
  const [editMode, setEditMode] = React.useState(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const numberOfDivisions = divisions.length;
  const teamMap = new Map(props.league.teams!.map(team => [team.id, team]));
  
  function changeNumberOfDivisions(event) {
    let newDivisions = divisions.map(division => {
      return {...division, teams: [...division.teams!]}
    });
    const newNumber = event.target.value;
    if (newNumber > numberOfDivisions) {
      [...Array(newNumber - numberOfDivisions).keys()]
        .map(index => index + numberOfDivisions + 1)
        .forEach(divisionNumber => newDivisions.push({
          name: 'Division ' + divisionNumber,
          teams: []
        }));
      if (numberOfDivisions === 0) {
        const allTeams = props.league.teams.map(team => team.id);
        const teamsPerDivision = allTeams.length / newNumber;
        [...Array(newNumber).keys()].forEach(divisionIndex => {
          [...Array(teamsPerDivision).keys()].forEach(() => {
            newDivisions[divisionIndex].teams!.push(allTeams.shift()!);
          });
        });
      }
    } else if (newNumber < numberOfDivisions) {
      if (newNumber === 0) {
        newDivisions = [];
      } else {
        let moveIndex = 0;
        [...Array(numberOfDivisions - newNumber).keys()]
          .map(index => index + newNumber)
          .forEach(removingIndex => {
            while(newDivisions[removingIndex].teams!.length > 0) {
               newDivisions[moveIndex].teams!.push(newDivisions[removingIndex].teams!.shift()!);
               moveIndex = moveIndex === newNumber - 1 ? 0 : moveIndex + 1;
            }
          });
        newDivisions.splice(newNumber);
      }
    }
    setDivisions(newDivisions);
  }
  
  function cancelEdit() {
    setDivisions([...props.league.divisions!]);
    setEditMode(false);
  }
  
  function saveDivisionSettings() {
    const newErrors: string[] = [];
    if (!divisions.every(division => division.name!.length >= 1 && division.name!.length <= 25)) {
      newErrors.push('Every division name must be between 1 and 25 characters long');
    }
    if ((new Set(divisions.map(division => division.name))).size !== numberOfDivisions) {
      newErrors.push("Each division name must be unique within the league");
    }
    const allTeams = props.league.teams.map(team => team.id);
    const teamsPerDivision = allTeams.length / divisions.length;
    if (!divisions.every(division => division.teams!.length === teamsPerDivision)) {
      newErrors.push('Every division must contain the same number of teams');
    }
    setErrors(newErrors);
    if (newErrors.length === 0) {
      const newLeague = {...props.league, divisions: divisions};
      leagueApi.updateLeague(newLeague.id, newLeague).then(() => setEditMode(false));
    }
  }
  
  function updateDivision(newDivision, divisionIndex) {
    const newDivisions = [...divisions];
    newDivisions[divisionIndex] = newDivision;
    setDivisions(newDivisions);
  }
  
  function moveDivision(movingTeamId, fromDivisionIndex, toDivisionIndex) {
    const newDivisions = divisions.map(division => {
      return {...division, teams: [...division.teams!]}
    });
    newDivisions[fromDivisionIndex].teams = divisions[fromDivisionIndex].teams!.filter(teamId => teamId !== movingTeamId);
    newDivisions[toDivisionIndex].teams!.push(movingTeamId);
    setDivisions(newDivisions);
  }
  
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
  function randomizeDivisions() {
    const newDivisions = divisions.map(division => {return {...division, teams: []}});
    const allTeams = props.league.teams.map(team => team.id);
    shuffle(allTeams);
    const teamsPerDivision = allTeams.length / numberOfDivisions;
    [...Array(numberOfDivisions).keys()].forEach(divisionIndex => {
      [...Array(teamsPerDivision).keys()].forEach(() => {
        newDivisions[divisionIndex].teams!.push(allTeams.shift()!);
      });
    });
    setDivisions(newDivisions);
  }
  
  const numberOfTeams = props.league.teams.length;
  const numberOfDivisionsOptions = [0].concat([...Array(numberOfTeams - 2).keys()].map(index => index + 2).filter(i => numberOfTeams % i === 0));
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  return <Box>
    <InputLabel id="numberOfDivisionsLabel">Number of Divisions</InputLabel>
    <Select name="numberOfDivisions" label="Number of Divisions" labelId="numberOfDivisionsLabel" value={numberOfDivisions}
        onChange={changeNumberOfDivisions} disabled={!editMode}>
      {Object.values(numberOfDivisionsOptions).map(option => 
        <MenuItem key={option} value={option}>{option}</MenuItem>
      )}
    </Select>
    <br />
    {divisions.map((division, divisionIndex) => 
      <DivisionContainer key={divisionIndex} division={division} divisionIndex={divisionIndex} 
          editMode={editMode} updateDivision={updateDivision} moveDivision={moveDivision} teamMap={teamMap} />
    )}
    <br />
    {errorInfo}
    {editMode ? <Box>
        <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
        <Button className='capsized-button' onClick={randomizeDivisions}>Randomize Divisions</Button>
        <Button className='capsized-button' onClick={saveDivisionSettings}>Save</Button>
      </Box> :
      <Button className='capsized-button' onClick={() => setEditMode(true)}>Edit Division Settings</Button>
    }
    
  </Box>
}

export default DivisionSettings;