import * as React from 'react'
import { useParams } from 'react-router-dom';
import { Team, Player, TradeProposalPlayer, PlayerPositionEnum, ContractStatus } from '../../../sdk/model';
import MaterialReactTable from 'material-react-table';
import TradeOptionsMenu from '../trade/TradeOptionsMenu';
import { getFinalSeasonOfContract, toSalary } from '../../util/SalaryDisplay';
import ContractDetails from '../contract/ContractDetails';

const positionOrder: Array<PlayerPositionEnum> = [PlayerPositionEnum.Qb,
                                                  PlayerPositionEnum.Rb,
                                                  PlayerPositionEnum.Wr,
                                                  PlayerPositionEnum.Te
                                                  ];

interface rosterContractDisplayProps {
  team: Team,
  myTeam: boolean | undefined,
  currentSeason: number,
  otherTeamIds: Array<string | undefined>,
  teamMap: Map<string | undefined, Team | undefined> | undefined,
  proposalPlayerMap: Map<string | undefined, TradeProposalPlayer | undefined> | undefined,
  playerMap: Map<string | undefined, Player | undefined>,
  handleTradeProposalChange: Function | undefined
}

function RosterContractDisplay(props: rosterContractDisplayProps) {
  const {leagueId} = useParams();
  const [otherTeamIds, setOtherTeamIds] = React.useState(props.otherTeamIds);
  React.useEffect(() => {
    if (props.otherTeamIds !== otherTeamIds) {
      setOtherTeamIds(props.otherTeamIds);
    }
  }, [props.otherTeamIds]);
  if (!props?.team.playerRoster) {
    return <p>Loading...</p>
  }
  const columns = [
      {
        accessorKey: 'position',
        header: 'Position',
        size: 5,
        sortingFn: (rowA, rowB) => {
          return positionOrder.indexOf(rowA.getValue('position')) - positionOrder.indexOf(rowB.getValue('position'));
          }
      },
      {
        accessorKey: 'name',
        header: 'Player Name',
        size: 50,
      },
      {
        accessorKey: 'salary',
        header: 'Current Year Salary',
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '-'
        )
      },
      {
        accessorKey: 'finalYear',
        header: 'Final Year of Contract',
        size: 50,
      }
    ]
    
  if (props.proposalPlayerMap) {
    columns.push({
        accessorKey: 'player',
        header: 'Action',
        size: 50,
        enableSorting: false,
        Cell : ({ cell }) => {
          const player = cell.getValue();
          const proposalPlayer = props.proposalPlayerMap!.get(player.id);
          return <TradeOptionsMenu handleTradeProposalChange={(playerId: string, fromTeamId: string, action: string) => props.handleTradeProposalChange(playerId, fromTeamId, action, 'player')} asset={player} teamId={props.team.id!} myTeam={props.myTeam} droppable={true} otherTeamIds={otherTeamIds} teamMap={props.teamMap} value={proposalPlayer ? (proposalPlayer.action === 'DROP' ? 'drop' : proposalPlayer.toTeamId) : 'default'} disabled={cell.row.original.contract.status === ContractStatus.Expired} />
        }
      })
  }
  
  const data = Object.values(props?.team.playerRoster!).flatMap(rosterPlayers => rosterPlayers.filter(player => player).map(rosterPlayer => {
    const player: Player = props.playerMap.get(rosterPlayer.playerId)!;
    return {
      position: player.position,
      name: player.abbr_name,
      salary: rosterPlayer.contract?.seasons!.find(season => season.season_year === props.currentSeason)?.annual_salary || 0,
      finalYear: getFinalSeasonOfContract(rosterPlayer),
      player: player,
      contract: rosterPlayer.contract
    }
  }));
  
  return <MaterialReactTable columns={columns} data={data} enableFilters={false} enableColumnActions={false} enablePagination={false} initialState={{
        density: 'compact',
        sorting: [
          {
            id: 'position',
            desc: false
          },
          {
            id: 'salary',
            desc: true
          }
        ]
      }} renderDetailPanel={({row}) =><ContractDetails leagueId={leagueId!} player={row.original.player} contract={row.original.contract!} />} />;
}

export default RosterContractDisplay;