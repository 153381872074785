import { createSlice } from '@reduxjs/toolkit'
import { League } from '../sdk/model'

const initialState : League[] = []

const leagueDataLoadSlice = createSlice({
  name: 'leagueDataLoad',
  initialState,
  reducers: {
    setLeagueDataLoad (state, action) {
      console.log("Action Type: " + action.type)
      console.log(action)
      return state = action.payload
      // return state = {...state, ...action.payload}
    }
  }
})

export const { setLeagueDataLoad } = leagueDataLoadSlice.actions
export default leagueDataLoadSlice.reducer