/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The type of tag placed on a player contract
 * @export
 * @enum {string}
 */

export const TagType = {
    Franchise: 'FRANCHISE',
    RookieOption: 'ROOKIE_OPTION'
} as const;

export type TagType = typeof TagType[keyof typeof TagType];



