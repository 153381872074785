import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League, LeaguePhase } from '../../../sdk/model';
import {Box, Typography, Select, MenuItem} from '@mui/material';
import AllTeamsSchedule from './AllTeamsSchedule';
import TeamSchedule from '../team/TeamSchedule';
import TeamDisplay from '../team/TeamDisplay';
import PlayoffBracket from './PlayoffBracket';

const PHASES_FOR_PLAYOFF_VIEW = new Set([
  LeaguePhase.Playoffs,
  LeaguePhase.SeasonComplete
]);

function LeagueSchedule() {
  const {leagueId} = useParams();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("teamId")
  
  const [viewingTeam, setViewingTeam] = React.useState(teamId ? teamId : "all");
  const [scheduleType, setScheduleType] = React.useState("all");
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadScheduleData: true,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  React.useEffect(() => {
    if (PHASES_FOR_PLAYOFF_VIEW.has(league?.phase)) {
      setScheduleType('playoffs');
    }
  }, [league?.phase]);
  
  if (!isLoadComplete || !league!.currentSeason || !league!.currentSeason.schedule) {
    return <p>Loading...</p>
  }
  const teamMap = new Map(league!.teams.map(team => [team.id, team]));
  const gamesByWeek = league!.currentSeason!.schedule!.reduce((partialResults, game) => {
    partialResults[game.weekNumber!] = partialResults[game.weekNumber!] || [];
    partialResults[game.weekNumber!].push(game);
    return partialResults;
  }, {});
  function displayTeam(teamId) {
    const team = teamMap.get(teamId)!;
    return <TeamDisplay leagueId={leagueId!} team={team} emphasized={team && team.owners?.some(owner => owner.id === user.id)} />
  }
  return <Box>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      League Schedule
    </Typography>
    <Select name="viewingTeam" label="Viewing" value={viewingTeam} onChange={(event) => setViewingTeam(event.target.value)}>
      <MenuItem value='all'>All Teams</MenuItem>
      {league?.teams.map(team => <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>)}
    </Select>
    {viewingTeam === 'all' ? 
      <Box>
        <Select name="scheduleType" label="Schedule Type" value={scheduleType} onChange={(event) => setScheduleType(event.target.value)}>
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='playoffs'>Playoffs</MenuItem>
        </Select>
        {scheduleType === 'all' ?
          <AllTeamsSchedule leagueId={leagueId!} gamesByWeek={gamesByWeek} displayTeam={displayTeam} teams={league?.teams!} editMode={false} editSchedule={null} /> :
          <PlayoffBracket leagueId={leagueId!} gamesByWeek={gamesByWeek} displayTeam={displayTeam} teams={league?.teams!} finalWeekOfRegularSeason={league?.currentSeason.finalWeekOfRegularSeason!} playoffRoundConfig={league?.currentSeason.playoffRoundConfig!} />
        }
      </Box> : 
      <TeamSchedule leagueId={leagueId!} gamesByWeek={gamesByWeek} displayTeam={displayTeam} teamId={viewingTeam}/>}
  </Box>
}

export default LeagueSchedule;
