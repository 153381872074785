import React from "react";
import { Team, User } from "../../sdk/model";
import { Avatar } from "@mui/material";

export function UserAvatar(user: User, children: React.ReactNode) {
  return <Avatar alt={user?.username} src={user?.picture} >{children}</Avatar>
}


export function TeamAvatar(team: Team | undefined) {
  console.log("Team: ", team);
  return <Avatar alt={team?.name} src={team?.picture}/>
}