import * as React from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData, useLoadTradeData, useAddUpdateTrade } from '../../../app/dataLoaderHooks';
import {Box, Select, MenuItem, Grid, Typography, Button} from '@mui/material';
import { League, Team, TeamPlayerInfo, DraftPick, TradeProposal, TradeProposalTeam, TradeProposalPlayer, TradeProposalPick } from '../../../sdk/model';
import TradeTeamViewer from './TradeTeamViewer';
import TradeDetails from './TradeDetails';
import { findDefaultTeam } from '../draft/service';
import { tradeApi } from '../../../adapters/APIExporter';
import { AxiosPromise } from 'axios';

function TradeBuilder() {
  const navigate = useNavigate();
  const {leagueId, tradeId} = useParams();
  const [searchParams] = useSearchParams();
  const otherTeamId = searchParams.get("teamId")
  const selectedPlayerId = searchParams.get("playerId")
  const [tradeTeamIds, setTradeTeamIds] = React.useState(
    otherTeamId ? [otherTeamId] : [""]);
  const [allInvolvedTeamIds, setAllInvolvedTeamIds] = React.useState([]);
  
  // Redux store variables
  const user = useAppSelector((state) => state.user);
  const playerDataLoad = useAppSelector((state) => state.playerDataLoad );

  const [tradeLoaded, setTradeLoaded] = React.useState(tradeId ? false : true)
  const [errors, setErrors] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [picks, setPicks] = React.useState([]);
  const [counterId, setCounterId] = React.useState(null);
  const [selectedPlayerSetup, setSelectedPlayerSetup] = React.useState(false);
  
  const [isTradeLoadComplete, setTradeLoadComplete] = React.useState(tradeId ? false : true);
  const [isException, setIsException] = React.useState(false);
  const [updatedTrade, setUpdatedTrade] = React.useState(undefined);
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
    
  let playerMap = new Map(playerDataLoad.flatMap(team => team.players).map((player) => [player.id, player]));
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: true, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: allInvolvedTeamIds,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});

  const teamMap : Map<string | undefined, Team> = new Map(league?.teams?.map((team) => [team.id, team]));
  const myTeam = league?.teams ? teamMap.get(findDefaultTeam(league?.teams, user?.id)) : null;
  
  const trades : Array<TradeProposal> = useLoadTradeData({teamId: myTeam?.id!, setIsException: setIsException, isLoadComplete: isTradeLoadComplete, setLoadComplete: setTradeLoadComplete});
   
  React.useEffect(() => {
    if (tradeId && isTradeLoadComplete) {
      const thisTrade = trades.find(teamTrade => teamTrade.id === tradeId);
      if (thisTrade) {
        setTradeTeamIds(thisTrade.teams.map(team => team.teamId).filter(teamId => teamId !== myTeam.id))
        setPlayers(thisTrade.players);
        setPicks(thisTrade.picks);
        if (thisTrade.status === 'PENDING') {
          setCounterId(tradeId);
        }
      } else {
        errors.push('Trade not found!');
      }
      setTradeLoaded(true);
    }
  }, [isTradeLoadComplete]);
  
  React.useEffect(() => {
    if (myTeam) {
      setAllInvolvedTeamIds([myTeam?.id].concat(tradeTeamIds));
    }
  }, [myTeam, tradeTeamIds]);
  
  React.useEffect(() => {
    if (isLoadComplete && selectedPlayerId && !selectedPlayerSetup) {
      setPlayers([{
        playerId: selectedPlayerId,
        fromTeamId: otherTeamId,
        action: 'TRADE',
        toTeamId: myTeam?.id
      }]);
      setSelectedPlayerSetup(true);
    }
  }, [isLoadComplete]);
  
  useAddUpdateTrade(myTeam?.id!, updatedTrade?.id, updatedTrade);
  
  if (updatedTrade) {
    navigate("/league/" + league?.id + "/trade/" + updatedTrade.id);
    setUpdatedTrade(undefined);
  }

  // Wait for our league data to load
  if (!tradeLoaded || !myTeam || !myTeam.rosterLoaded) {
    return <p>Loading...</p>
  }
  
  let errorInfo = Object.entries(errors).map(([key, value]) => {
    if (value) {
      return (<p className='error' key={'error_' + key}>{value}</p>);
    }
    return (<p className='error' key={'error_' + key}></p>);
  });
  
  const teamPlayerMap : Map<string | undefined, TeamPlayerInfo | undefined> = new Map(league?.teams?.filter(team => team.playerRoster).flatMap(team => 
    Object.values(team.playerRoster).flatMap(playerList => 
      playerList.filter(player => player).map(player => [player.playerId, player])
    )
  ));
  
  const pickMap : Map<string | undefined, DraftPick | undefined> = new Map(league?.teams?.filter(team => team.draftPicks).flatMap(team => 
    team?.draftPicks?.map(pick => [pick.id, pick])
  ));
  
  const proposalPlayerMap: Map<string | undefined, TradeProposalPlayer | undefined> = new Map(players.map(player => [player.playerId, player]));
  const proposalPickMap: Map<string | undefined, TradeProposalPick | undefined> = new Map(picks.map(pick => [pick.draftPickId, pick]));
  
  const allInvolvedTeams = allInvolvedTeamIds.map(teamId => teamMap.get(teamId)).filter(team => !team || team.rosterLoaded);
  
  function setTradeTeamId(tradeTeamId: string, tradeTeamIndex: number) {
    const oldTeamId = tradeTeamIds[tradeTeamIndex];
    const newTradeTeamIds = [...tradeTeamIds];
    newTradeTeamIds[tradeTeamIndex] = tradeTeamId;
    setTradeTeamIds(newTradeTeamIds);
    const newPlayers = players.filter(player => player.toTeamId !== oldTeamId && player.fromTeamId !== oldTeamId);
    setPlayers(newPlayers);
    const newPicks = picks.filter(pick => pick.toTeamId !== oldTeamId && pick.fromTeamId !== oldTeamId);
    setPicks(newPicks);
  }
  
  function handleTradeProposalChange(assetId: string, fromTeamId: string, action: string, assetType: string) {
    if (assetType === "player") {
      handleAssetChange(players, assetId, "playerId", action, fromTeamId, setPlayers);
    } else if (assetType === "pick") {
      handleAssetChange(picks, assetId, "draftPickId", action, fromTeamId, setPicks);
    }
  }
  
  function handleAssetChange(assetList, assetId, idFieldName, action, fromTeamId, assetListSetter) {
    const newAssetList = [...assetList];
    const assetIndex = newAssetList.map(asset => asset[idFieldName]).indexOf(assetId);
    if (action === 'default') {
      if (~assetIndex) {
        newAssetList.splice(assetIndex, 1);
      }
    } else {
      let actionName: string;
      let toTeamId : string | null;
      if (action === 'drop') {
        actionName = 'DROP';
        toTeamId = null;
      } else {
        actionName = 'TRADE';
        toTeamId = action;
      }
      let asset;
      if (~assetIndex) {
        asset = newAssetList[assetIndex];
      } else {
        asset = {
          fromTeamId: fromTeamId
        };
        asset[idFieldName] = assetId;
        newAssetList.push(asset);
      }
      asset.action = actionName;
      asset.toTeamId = toTeamId;
    }
    assetListSetter(newAssetList);
  }
  
  function clearDraft() {
    setTradeTeamIds([""]);
    setPlayers([]);
    setPicks([]);
  }
  
  function addTeam() {
    const newTradeTeamIds = [...tradeTeamIds];
    newTradeTeamIds.push("");
    setTradeTeamIds(newTradeTeamIds);
  }
  
  function saveDraft() {
    const newErrors : Array<string> = [];
    const otherTeamIds: Array<string> = tradeTeamIds.filter(tradeTeamId => tradeTeamId && players.concat(picks).some(asset => asset.fromTeamId === tradeTeamId || asset.toTeamId === tradeTeamId))
    if (otherTeamIds.length === 0) {
      newErrors.push("There must be at least two teams involved in a trade");
    }
    setErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }
    const teams : Array<TradeProposalTeam> = [{
      teamId: myTeam?.id!,
      status: 'PROPOSED'
    }].concat(otherTeamIds.map(tradeTeamId => {
      return {
        teamId: tradeTeamId!,
        status: 'PENDING'
      }
    }));
    const tradeProposal : TradeProposal = {
      teams: teams,
      players: players,
      picks: picks,
      status: 'DRAFT'
    }
    createOrUpdateAndNavigate(tradeId ? 
        (counterId ? 
          tradeApi.counterTradeProposal(tradeProposal, myTeam?.id, counterId) :
          tradeApi.updateTradeProposal(tradeProposal, tradeId)
        ) :  
        tradeApi.createTradeProposal(tradeProposal, myTeam?.id));
  }
  
  function createOrUpdateAndNavigate(promise: AxiosPromise<string>) {
    promise.then((response) => {
      setUpdatedTrade(response.data);
    }).catch((error) => {
      let newErrors = [error.response.data ? error.response.data.message : "Request failed.  Please try again."];
      setErrors(newErrors);
    });
  }
  
  const maxRosterSize = Object.values(league?.rosterSettings!).reduce((partialSum, numberOfSpots) => partialSum + numberOfSpots, 0);
  
  return <Box>
    <Button color="primary" variant="contained" onClick={clearDraft}>Clear</Button>
    <Grid container>
      <Grid item xs={6}> 
        <br/><br/>
        <TradeTeamViewer team={myTeam} currentSeason={league?.currentSeason?.seasonYear!}
            playerMap={playerMap} teamMap={teamMap} userId={user?.id} myTeam={true} 
            otherTeamIds={tradeTeamIds.filter(tradeTeamId => tradeTeamId)} handleTradeProposalChange={handleTradeProposalChange}
            proposalPlayerMap={proposalPlayerMap} proposalPickMap={proposalPickMap} />
      </Grid>
      {tradeTeamIds.map((tradeTeamId, tradeTeamIndex) => {
        const tradeTeam = teamMap.get(tradeTeamId);
        const otherTeamIds = [myTeam.id].concat(tradeTeamIds).filter(otherTeamId => otherTeamId !== tradeTeamId);
        return <Grid key={tradeTeamIndex} item xs={6}> 
          <Select label="Team" labelId="viewingTeamLabel" value={tradeTeamId} displayEmpty onChange={(event) => setTradeTeamId(event.target.value, tradeTeamIndex)}
            sx={{ flexGrow: 1 }} disabled={counterId ? true : false}>
            <MenuItem value="">-- Select a Team --</MenuItem>
            {Object.entries(league?.teams!).filter(([teamIndex, team]) => team.id !== myTeam.id && (team.id === tradeTeamId || !tradeTeamIds.includes(team.id))).map(([teamIndex, team]) => 
              <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
            )}
          </Select>
          {tradeTeamId !== "" && tradeTeam.rosterLoaded && <TradeTeamViewer team={tradeTeam} currentSeason={league?.currentSeason?.seasonYear!}
              playerMap={playerMap} teamMap={teamMap} userId={user?.id} myTeam={false} otherTeamIds={otherTeamIds} 
              handleTradeProposalChange={handleTradeProposalChange} proposalPlayerMap={proposalPlayerMap} proposalPickMap={proposalPickMap} />}
        </Grid>
      })}
      <Grid item xs={6}>
        <Button color="primary" variant="contained" onClick={addTeam}>Add Team</Button>
      </Grid>
    </Grid>
    <Typography variant="h5" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
      Trade Overview and Impact
    </Typography>
    <TradeDetails leagueId={leagueId!} teams={allInvolvedTeams} currentSeason={league?.currentSeasonYear} players={players} picks={picks} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={pickMap} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
    <br />
    {errorInfo}
    <Button color="primary" variant="contained" onClick={saveDraft}>Save and Review</Button>
  </Box>
}

export default React.memo(TradeBuilder);
