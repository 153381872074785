import * as React from 'react';
import { styled } from '@mui/material/styles';
import { PublicUserInfo, League } from '../../sdk/model';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import Close from "@mui/icons-material/Close";
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { userApi, leagueApi } from '../../adapters/APIExporter';
import LeagueQuickView from '../body/league/LeagueQuickView';
import DiamondIcon from '@mui/icons-material/Diamond';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface userInfoCardProps {
  userId: string,
  callback: Function
}

export default function UserInfoCard(props: userInfoCardProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [user, setUser] = React.useState<PublicUserInfo | undefined>(undefined);
  const [leagues, setLeagues] = React.useState<League[]>([]);
  const [viewingImage, setViewingImage] = React.useState(false);
  
  React.useEffect(() => {
    userApi.getUser(props.userId).then(response => setUser(response.data));
  }, []);

  const handleExpandClick = () => {
    if (leagues.length) {
      setExpanded(!expanded);
    } else {
      leagueApi.getLeagues(true, props.userId).then((resp) => {
        setLeagues(resp.data);
        setExpanded(!expanded);
      });
    }
  };
  
  if (!user) {
    return <p>Loading user info...</p>
  }
  const subtitle = <div><b>Joined:</b> {new Date(user.createdTimestamp!).getFullYear()}</div>

  return (
    <Card style={{maxHeight: 800, overflow: 'auto'}}>
      <Dialog open={viewingImage} maxWidth='md' fullWidth>
        <DialogContent>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={() => setViewingImage(false)}>
              <Close />
            </IconButton>
          </Box>
          <Box component="img" src={user.picture} sx={{width: '100%', height:'100%'}} />
        </DialogContent>
      </Dialog>
      <CardHeader
        avatar={<IconButton sx={{ p: 0 }} disabled={!user.picture} onClick={() => setViewingImage(true)}>
          <Avatar sx={{ bgcolor: red[500] }} aria-label="test"
            src={user.picture!}>
          </Avatar>
        </IconButton>}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={user.username}
        subheader={subtitle}
      />
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h5">Leagues:</Typography>
          {leagues.map((league, index) => <LeagueQuickView key={index} userId={user?.id} league={league} callback={props.callback} />)}
          <Typography variant="h5">Accomplishments:</Typography>
          <Box sx={{border: 1}}>
            <p>
              Total League Championships: {user.leagueChampionshipTeamSeasons?.length}
            </p>
            {user.leagueChampionshipTeamSeasons?.map(teamSeason => <p>
              <DiamondIcon />{teamSeason.leagueName} - {teamSeason.teamName}, {teamSeason.seasonYear}
            </p>)}
          </Box>
          <Box sx={{border: 1}}>
            <p>
              Total Division Championships: {user.divisionChampionshipTeamSeasons?.length}
            </p>
            {user.divisionChampionshipTeamSeasons?.map(teamSeason => <p>
              <MilitaryTechIcon />{teamSeason.leagueName} - {teamSeason.teamName}, {teamSeason.seasonYear}
            </p>)}
          </Box>
          <Box sx={{border: 1}}>
            <p>
              Total Playoff Appearances: {user.playoffAppearanceTeamSeasons?.length}
            </p>
            {user.playoffAppearanceTeamSeasons?.map(teamSeason => <p>
              <ThumbUpAltIcon />{teamSeason.leagueName} - {teamSeason.teamName}, {teamSeason.seasonYear}
            </p>)}
          </Box>
          <p>Has played in {user.allLeaguesPlayedIn?.length} league{user.allLeaguesPlayedIn?.length === 1 ? '' : 's'} and completed {user.allTeamSeasonsPlayed?.length} team season{user.allTeamSeasonsPlayed?.length === 1 ? '' : 's'}</p>
        </CardContent>
      </Collapse>
    </Card>
  );
}