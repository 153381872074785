import * as React from 'react'
import { League, Player, Team, Contract, TagType, TradeProposalPlayer, TradeProposalPlayerActionEnum } from '../../../sdk/model';
import { useAppSelector } from '../../../app/hooks';
import { useLoadLeagueData, useUpdateLeagueData } from '../../../app/dataLoaderHooks';
import {Dialog, DialogTitle, Box, IconButton, DialogContent, Typography, DialogActions, Button} from '@mui/material';
import Close from "@mui/icons-material/Close";
import { toSalary } from '../../util/SalaryDisplay';
import TradeDetails from '../trade/TradeDetails';
import { contractApi } from '../../../adapters/APIExporter';

interface franchiseTagButtonProps {
  leagueId: string,
  player: Player,
  contract: Contract
  currentSeasonYear: number,
  team: Team,
  callback: Function
}

function FranchiseTagButton(props: franchiseTagButtonProps) {
  const [confirming, setConfirming] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [mutableLeague, setMutableLeague] = React.useState<League | undefined>(undefined);
  
  useUpdateLeagueData(mutableLeague);
  if (mutableLeague) {
    setMutableLeague(undefined);
    props.callback('success', mutableLeague);
  }
  
  const user = useAppSelector((state) => state.user);
  const league : League|undefined = useLoadLeagueData({leagueId: props.leagueId, userId: user.id, loadBasicDraftData: false,
                                                            loadFullDraftData: false, loadContractData: true,
                                                            loadBasicAuctionData: false, loadFullAuctionData: false,
                                                            loadRosterData: false, loadWaivedContracts: false,
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  const playerMap = new Map([[props.player.id, props.player]]);
  const teamMap = new Map([[props.team.id, props.team]]);
  const teamPlayerMap = new Map(Object.values(props.team.playerRoster!).flatMap(playerList => 
    playerList.filter(player => player).map(player => [player.playerId, player])
  ));
  const minimumTagValue = league!.currentSeason!.minimumTagValuesAtPosition![props.player!.position!].franchiseTag!;
  const previousContractValue = props.contract.seasons!
      .filter(season => season?.season_year === props.currentSeasonYear - 1)[0]?.annual_salary!;
  const increasedContractValue = previousContractValue * 1.2;
  const franchiseCost = Math.max(minimumTagValue, increasedContractValue);
  const weeklyPayments = franchiseCost / league?.currentSeason?.finalWeek!;
  const maxRosterSize = Object.values(league?.rosterSettings!).reduce((partialSum, numberOfSpots) => partialSum + numberOfSpots, 0);
  teamPlayerMap.set(props.player.id, {
    contract: {
      seasons: [{
        season_year: league!.currentSeasonYear,
        annual_salary: franchiseCost,
        weeks: [...Array(league?.currentSeason?.finalWeek).keys()].map(weekIndex => weekIndex + 1).map(weekNumber => {
          return {
            fullSalary: weeklyPayments,
            guaranteedSalary: weeklyPayments,
            payments: [{
              teamId: props.team.id,
              weekNumber: weekNumber,
              price: weeklyPayments,
              completed: false
            }]
          }
        })
      }]
    }
  });
  const players : TradeProposalPlayer[] = [{
    playerId: props.player.id,
    toTeamId: props.team.id,
    action: TradeProposalPlayerActionEnum.Trade
  }]
  
  function tagPlayer() {
    contractApi.markPlayerForContractTag(props.leagueId, props.player.id, TagType.Franchise).then(result => {
      const newLeague = {...league, teams : [...league!.teams]};
      const teamIndex = newLeague.teams.findIndex(team => team.id === result.data.id);
      newLeague.teams[teamIndex] = result.data;
      setMutableLeague(newLeague);
    }).catch(error => {
      props.callback('error', error.response.data.message);
    }).finally(() => {
      setConfirming(false);
    });
  }
  
  return <React.Fragment>
    <Dialog open={confirming} maxWidth="lg" fullWidth>
      <DialogTitle>Franchise Tag Player?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setConfirming(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Are you sure you want to designate {props.player.abbr_name} to receive a Franchise Tag?  
            This will sign him to a one-year, fully-guaranteed deal in {props.currentSeasonYear} for {toSalary(franchiseCost)}.  
            The signing of this deal will not be finalized until the league's tag deadline unless you choose to manually finalize the tag early.
            This will have the following impact on your team's salary cap:
          <TradeDetails leagueId={props.leagueId} teams={[props.team]} currentSeason={props.currentSeasonYear} players={players} picks={[]} playerMap={playerMap} teamMap={teamMap} teamPlayerMap={teamPlayerMap} pickMap={new Map()} salaryCap={league!.currentSeason!.nflSeason!.salaryCap!} maxRosterSize={maxRosterSize} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => setConfirming(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={tagPlayer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Button color="primary" variant="contained" onClick={() => setConfirming(true)}>Franchise Tag Player</Button>
  </React.Fragment>
}

export default FranchiseTagButton;
