import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Label, Logout } from '@mui/icons-material';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../../app/hooks'
import { logIn, logOut } from '../../../../adapters/ServiceAPI';
import { User } from '../../../../sdk/model';
import { setUser } from '../../../../slices/user';
import { useNavigate } from 'react-router-dom';


const menuOptions = ['Profile', 'Account', 'Dashboard', 'Logout'];
function ProfileButton() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  
  // console.log("USER: " + user.email);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleUserMenuClick = (setting: String) => {
    console.log(setting);

    switch(setting) {
      case 'Profile':
        navigate('/profile');
        break;
      case 'Account':
        console.log('Account TBD');
        console.log(user);
        break;
      case 'Dashboard':
        console.log('Dashboard TBD');
        break;
      case 'Logout':
        logOut(() => {
        	window.location.assign('/');
        })
        break;
    }

    handleCloseUserMenu()

  };

  const handleLoginButtonPressed = () => {
    console.log('Login TBD');
    //State can get stuck in a weird state where you are technically logged in but the user is null
    //This is a hack to fix that logs you out if you are not redirected, we should probably figure out a better fix at some point
    logIn((resp) => logOut())
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // If the user is null then we want to show the login button
  // TBD: Pick an image for the login button
  if (user === null || Object.keys(user).length === 0) {
    return (
      <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={handleLoginButtonPressed}>
        Login
      </Button>
    );
  }

  return (
      <Box sx={{ flexGrow: 0 }}> 
        {(user.username ? user.username : "") + " "}
        <Tooltip title="Profile Settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={user.username} src={user.picture} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        > 
          {menuOptions.map((setting) => (
            <MenuItem key={setting} onClick={(event => handleUserMenuClick(setting))}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
  );
}
export default ProfileButton;