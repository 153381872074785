import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, Typography, Grid, Button, IconButton, Avatar, Modal} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { LeagueSeason, League, TeamSeason, User } from '../../../sdk/model';
import UserInfoCard from '../../cards/userCard'

interface allSeasonsReportProps {
  league: League,
  completedSeasons: LeagueSeason[],
  showSeason: Function
}

function AllSeasonsReport(props: allSeasonsReportProps) {
  const navigate = useNavigate();
  const [viewingUser, setViewingUser] = React.useState<User | undefined>(undefined);
  const recordColumns = React.useMemo(
    () => [
      {
        accessorKey: 'team.name',
        header: 'Team Name',
        size: 25,
        Cell: ({ renderedCellValue, row }) => <Button onClick={() => {
          showTeam(row.original.team.id);
          }}>{renderedCellValue}</Button>
      },
      {
        accessorKey: 'wins', //access nested data with dot notation
        header: 'Wins',
        size: 15,
      },
      {
        accessorKey: 'losses',
        header: 'Losses',
        size: 15,
      },
      {
        accessorKey: 'ties', //normal accessorKey
        header: 'Ties',
        size: 15,
      },
      {
        accessorKey: 'winPct',
        id: 'winPct',
        header: 'Win %',
        size: 15,
        Cell: ({ renderedCellValue, row }) => [-1, 99].includes(renderedCellValue) ? '-' : ((renderedCellValue * 100).toFixed(1)) + "%"
      },
    ], []
  );
  function showTeam(teamId : string) {
    navigate(`/league/${props.league.id}/team/${teamId}`)
  }
  function getCountByTeamDisplay(propertyName) {
    const countMap = props.league.teams
      .reduce((accumulator, team) => {
        const championships = team[propertyName].length;
        if (accumulator[championships]) {
          accumulator[championships].push(team);
        } else {
          accumulator[championships] = [team];
        }
        return accumulator;
      }, {});
    var ranking = 1;
    return Object.keys(countMap).sort().reverse().map(championships => {
      const elem = countMap[championships].map(team => <Grid key={team.id} container spacing={2} justifyContent="center">
        <Grid item textAlign="right" xs={5}>
          <Box sx={{marginTop: '5px'}}>{ranking}.</Box>
        </Grid>
        <Grid item textAlign="left" xs={7}>
          <Button onClick={() => showTeam(team.id)}>{team.name}</Button> - {championships}
        </Grid>
      </Grid>)
      ranking += countMap[championships].length;
      return elem;
    });
  }
  
  const allTeamSeasons : TeamSeason[] = props.completedSeasons.flatMap(season => season.teams!).concat(props.league.teams.map(team => team.currentSeason!));
  function getTeamRecordList(winsAttribute, lossesAttribute, tiesAttribute, reversed) {
    const teamRecords = allTeamSeasons.reduce((accumulator, season) => {
      if (!accumulator[season.teamId!]) {
        const thisTeam = props.league.teams.find(team => season.teamId === team.id)
        accumulator[season.teamId!] = {team: thisTeam, wins: 0, losses: 0, ties: 0};
      }
      const teamRecord = accumulator[season.teamId!];
      teamRecord.wins += season[winsAttribute];
      teamRecord.losses += season[lossesAttribute];
      if (tiesAttribute) {
        teamRecord.ties += season[tiesAttribute];
      }
      return accumulator;
    }, {});
    const teamRecordsList = Object.values(teamRecords);
    teamRecordsList.forEach(teamRecord => {
      const totalGames = teamRecord.wins + teamRecord.losses + teamRecord.ties;
      teamRecord.winPct = totalGames === 0 ? (reversed ? 99 : -1) : (teamRecord.wins + teamRecord.ties / 2) / totalGames;
    });
    return teamRecordsList;
  }
  const teamRecordList = getTeamRecordList("wins", "losses", "ties", false);
  const teamPlayoffRecordList = getTeamRecordList("playoffWins", "playoffLosses", undefined, false);
  const loserBracketRecordList = getTeamRecordList("loserBracketWins", "loserBracketLosses", undefined, true);
  const teamPostseasonRecordList = getTeamRecordList("postseasonWins", "postseasonLosses", undefined, false);
  return <Box>
    <Modal open={!!viewingUser} onClose={() => setViewingUser(undefined)} 
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        sx={{margin: '5%'}}>
      <UserInfoCard userId={viewingUser?.id!} callback={() => setViewingUser(undefined)}/>
    </Modal>
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            League Champions:
          </Typography>
          {props.completedSeasons.map(season => {
            const championshipTeams = season.teams?.filter(team => team.overallRank === 1)!;
            return <Grid key={season.seasonYear} container spacing={2} justifyContent="center">
              <Grid item textAlign="right" xs={5}>
                <Box><Button onClick={() => props.showSeason(season.seasonYear!)}>{season.seasonYear}</Button>:</Box>
              </Grid>
              <Grid item textAlign="left" xs={7}>
                {championshipTeams.map(team => <Box key={team.id}>
                  <Button onClick={() => showTeam(team.teamId!)}>{team.teamName}</Button> - 
                  {team.owners?.map((owner, ownerIndex) => 
                    <Button key={ownerIndex} onClick={() => setViewingUser(owner)}>
                      <IconButton sx={{ p: 0 }}>
                        <Avatar alt={owner?.username} src={owner?.picture} />
                      </IconButton>
                      {' ' + owner.username}
                      <br/>
                    </Button>
                  )}
                </Box>)}
              </Grid>
            </Grid>
          })}
        </Box>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Championships by Team:
          </Typography>
          {getCountByTeamDisplay('leagueChampionshipSeasons')}
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Division Championships by Team:
          </Typography>
          {getCountByTeamDisplay('divisionChampionshipSeasons')}
        </Box>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Playoff Appearances by Team:
          </Typography>
          {getCountByTeamDisplay('playoffAppearanceSeasons')}
        </Box>
      </Grid>
    </Grid>
    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
      League Records
    </Typography>
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Regular Season Records
          </Typography>
          <MaterialReactTable enableMultiRowSelection={false}
              getRowId={(row) => row.id} columns={recordColumns} 
              data={teamRecordList} enablePagination={false}
              initialState={{
                sorting: [
                  {
                    id: 'winPct',
                    desc: true,
                  },
                ],
                
              }}/>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Playoff Records
          </Typography>
          <MaterialReactTable enableMultiRowSelection={false}
              getRowId={(row) => row.id} columns={recordColumns} 
              data={teamPlayoffRecordList} enablePagination={false} 
              initialState={{
                sorting: [
                  {
                    id: 'winPct',
                    desc: true,
                  },
                ],
                
              }}/>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Loser Bracket Records
          </Typography>
          <MaterialReactTable enableMultiRowSelection={false}
              getRowId={(row) => row.id} columns={recordColumns} 
              data={loserBracketRecordList} enablePagination={false} 
              initialState={{
                sorting: [
                  {
                    id: 'winPct',
                    desc: false,
                  },
                ],
                
              }}/>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ border: 1, width: 600 }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Postseason Records
          </Typography>
          <MaterialReactTable enableMultiRowSelection={false}
              getRowId={(row) => row.id} columns={recordColumns} 
              data={teamPostseasonRecordList} enablePagination={false} 
              initialState={{
                sorting: [
                  {
                    id: 'winPct',
                    desc: true,
                  },
                ],
                
              }}/>
        </Box>
      </Grid>
    </Grid>
  </Box>
}

export default AllSeasonsReport;
