/* tslint:disable */
/* eslint-disable */
/**
 * fantasy-dynasty-app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * LeagueEventQueueApi - axios parameter creator
 * @export
 */
export const LeagueEventQueueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} leagueEventId 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerQueue: async (leagueEventId: string, teamId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('getPlayerQueue', 'leagueEventId', leagueEventId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getPlayerQueue', 'teamId', teamId)
            const localVarPath = `/league_event_queue/{leagueEventId}/{teamId}`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {string} teamId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPlayerQueue: async (leagueEventId: string, teamId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leagueEventId' is not null or undefined
            assertParamExists('setPlayerQueue', 'leagueEventId', leagueEventId)
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('setPlayerQueue', 'teamId', teamId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('setPlayerQueue', 'requestBody', requestBody)
            const localVarPath = `/league_event_queue/{leagueEventId}/{teamId}`
                .replace(`{${"leagueEventId"}}`, encodeURIComponent(String(leagueEventId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueEventQueueApi - functional programming interface
 * @export
 */
export const LeagueEventQueueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueEventQueueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} leagueEventId 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerQueue(leagueEventId: string, teamId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerQueue(leagueEventId, teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {string} teamId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPlayerQueue(leagueEventId: string, teamId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPlayerQueue(leagueEventId, teamId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeagueEventQueueApi - factory interface
 * @export
 */
export const LeagueEventQueueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueEventQueueApiFp(configuration)
    return {
        /**
         * 
         * @param {string} leagueEventId 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerQueue(leagueEventId: string, teamId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPlayerQueue(leagueEventId, teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leagueEventId 
         * @param {string} teamId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPlayerQueue(leagueEventId: string, teamId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.setPlayerQueue(leagueEventId, teamId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueEventQueueApi - object-oriented interface
 * @export
 * @class LeagueEventQueueApi
 * @extends {BaseAPI}
 */
export class LeagueEventQueueApi extends BaseAPI {
    /**
     * 
     * @param {string} leagueEventId 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventQueueApi
     */
    public getPlayerQueue(leagueEventId: string, teamId: string, options?: AxiosRequestConfig) {
        return LeagueEventQueueApiFp(this.configuration).getPlayerQueue(leagueEventId, teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leagueEventId 
     * @param {string} teamId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueEventQueueApi
     */
    public setPlayerQueue(leagueEventId: string, teamId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return LeagueEventQueueApiFp(this.configuration).setPlayerQueue(leagueEventId, teamId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
