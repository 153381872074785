import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks'
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import { League } from '../../../sdk/model';
import { Box, Dialog, DialogContent, Typography, IconButton } from '@mui/material';
import Close from "@mui/icons-material/Close";
import Avatar from '@mui/material/Avatar';

function LeagueName() {
  const {leagueId} = useParams();
  
  const user = useAppSelector((state) => state.user);

  const [viewingImage, setViewingImage] = React.useState(false);
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);

  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: false,
                                                              loadFullDraftData: false, loadContractData: false,
                                                              loadBasicAuctionData: false, loadFullAuctionData: false,
                                                              loadRosterData: false, 
                                                              isException: isException, setIsException: setIsException,
                                                              isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});
  
  return <Box>
    <Dialog open={viewingImage} maxWidth='md' fullWidth>
      <DialogContent>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setViewingImage(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box component="img" src={league?.picture} sx={{width: '100%', height:'100%'}} />
      </DialogContent>
    </Dialog>
    <Typography variant="h4" component="div" color="text.secondary">
      <IconButton sx={{ p: 0 }} disabled={!league?.picture} onClick={() => setViewingImage(true)}>
        <Avatar alt={league?.leagueName} src={league?.picture}>{!league?.picture && league?.leagueName?.substring(0,1)}</Avatar>
      </IconButton>
      {league?.leagueName}
    </Typography>
    
  </Box>
}

export default LeagueName;
