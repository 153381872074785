import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { League } from '../../../sdk/model'
import { useLoadLeagueData } from '../../../app/dataLoaderHooks';
import Box from '@mui/material/Box';
import { Select, Dialog, DialogContent, MenuItem, Button, Typography, Tabs, Tab, IconButton, Avatar } from '@mui/material';
import Close from "@mui/icons-material/Close";
import TeamRoster from './TeamRosterCustom';
import TeamAssets from './TeamAssets';
import TeamHistory from './TeamHistory';
import { useAppSelector } from '../../../app/hooks';
import { toSalary } from '../../util/SalaryDisplay';
import { usePlayerDataLoadFlat } from '../../util/PlayerDataUtil';

function TeamView() {
  const {leagueId, teamId} = useParams();
  
  const navigate = useNavigate();

  // Redux store variables
  const user = useAppSelector((state) => state.user);
  const playerMap = usePlayerDataLoadFlat();

  console.log("Player Map: ", playerMap);
  
  const [isLoadComplete, setLoadComplete] = React.useState(false);
  const [isException, setIsException] = React.useState(false);
  const [view, setView] = React.useState("lineup");
  const [viewingImage, setViewingImage] = React.useState(false);
  
  const league : League|undefined = useLoadLeagueData({leagueId: leagueId, userId: user.id, loadBasicDraftData: true,
                                                            loadFullDraftData: false, loadContractData: false,
                                                            loadBasicAuctionData: true, loadFullAuctionData: false,
                                                            loadRosterData: false, loadRosterFor: [teamId],
                                                            isException: isException, setIsException: setIsException,
                                                            isLoadComplete: isLoadComplete, setLoadComplete: setLoadComplete});

  if (!isLoadComplete || !teamId) {
    return <Typography color="white">Loading roster data..</Typography>
  }

  const thisTeam = league?.teams.find(team => team.id === teamId)!;
  const teamMap = new Map(league?.teams.map(team => [team.id, team]));
  
  const myTeam = thisTeam?.owners?.some(owner => owner.id === user.id)!;

  return (
    <Box>
      <Dialog open={viewingImage} maxWidth='md' fullWidth>
        <DialogContent>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={() => setViewingImage(false)}>
              <Close />
            </IconButton>
          </Box>
          <Box component="img" src={thisTeam.picture} sx={{width: '100%', height:'100%'}} />
        </DialogContent>
      </Dialog>
      <Select name="viewingTeam" label="Team" labelId="viewingTeamLabel" value={teamId} onChange={(event) => navigate('/league/' + league?.id + '/team/' + event.target.value)}
        sx={{ flexGrow: 1 }}>
        {Object.entries(league?.teams!).map(([teamIndex, team]) => 
          <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
        )}
      </Select>
      <IconButton sx={{ p: 0 }} disabled={!thisTeam.picture} onClick={() => setViewingImage(true)}>
        <Avatar alt={thisTeam.name} src={thisTeam.picture}>{!thisTeam.picture && thisTeam.name?.substring(0,1)}</Avatar>
      </IconButton>
      {myTeam || <Button color="primary" variant="contained" onClick={() => navigate('/league/' + league?.id + '/trade?teamId=' + teamId)}>Propose Trade</Button>}
      <Typography>Salary Cap: {toSalary(league?.currentSeason?.nflSeason?.salaryCap)}</Typography>
      <Typography>Current Season Committed Salary: {toSalary(thisTeam.currentSeasonSalary)}</Typography>
      <Tabs value={view} onChange={(event, value) => {
        setView(value)
        }} aria-label="Roster Tabs">
        <Tab label="Lineup" value="lineup" />
        <Tab label="Contracts/Assets" value="assets" />
        <Tab label="Team Info/History" value="history" />
      </Tabs>
      {view === 'lineup' && <TeamRoster leagueId={leagueId} teamId={thisTeam?.id} team={thisTeam} currentSeasonYear={league?.currentSeasonYear!}
        playerMap={playerMap} rosterSettings={league?.rosterSettings} myTeam={myTeam} />}
      {view === 'assets' && <TeamAssets league={league} team={thisTeam} currentSeason={league?.currentSeasonYear!} playerMap={playerMap} myTeam={myTeam} teamMap={teamMap} />}
      {view === 'history' && <TeamHistory team={thisTeam} leagueId={league?.id!} />}
    </Box>
  )
}

export default TeamView;
